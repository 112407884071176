// TODO replace with Foundation 6.4 range input after the Foundation upgrade

$include-html-range-slider-classes: $include-html-classes !default;

$range-slider-margin: 20px 0 !default;
$range-slider-track-height: 6px !default;
$range-slider-track-full-color: $body-font-color !default;
$range-slider-track-included-color: get-color(primary) !default;
$range-slider-thumb-diameter: 30px !default;
$range-slider-thumb-diameter-medium: $range-slider-thumb-diameter !default;
$range-slider-thumb-diameter-large: $range-slider-thumb-diameter-medium !default;
$range-slider-thumb-color: get-color(primary) !default;
$range-slider-output-color: get-border-value($input-border, 'color') !default;
$range-slider-output-font-family: null !default;
$range-slider-output-font-weight: null !default;
$range-slider-output-font-size: null !default;
$range-slider-output-border: $input-border !default;
$range-slider-output-border-radius: 4px !default;
$range-slider-output-background: null !default;
$range-slider-output-letter-spacing: null !default;
$range-slider-output-height: 2.5em !default;
$range-slider-output-height-medium: $range-slider-output-height !default;
$range-slider-output-height-large: $range-slider-output-height-medium !default;
$range-slider-output-padding: 0 ($range-slider-output-height - 1) / 2 !default;
$range-slider-output-line-height: $range-slider-output-height !default;
$range-slider-output-bottom-spacing: 1em !default;
$range-slider-output-bottom-spacing-medium: $range-slider-output-bottom-spacing !default;
$range-slider-output-bottom-spacing-large: $range-slider-output-bottom-spacing-medium !default;
$range-slider-output-zindex: null !default;
$range-slider-output-width: null !default;
$range-slider-thumb-margin-top: ($range-slider-thumb-diameter / -2 + $range-slider-track-height / 2) !default; // used for centering verically
$range-slider-thumb-margin-top-medium: ($range-slider-thumb-diameter-medium / -2 + $range-slider-track-height / 2) !default; // used for centering verically
$range-slider-thumb-margin-top-large: ($range-slider-thumb-diameter-large / -2 + $range-slider-track-height / 2) !default; // used for centering verically
$range-slider-padding-top: $range-slider-output-height + $range-slider-output-bottom-spacing !default;
$range-slider-left: null !default;
$price-single-button-background: null !default;
$price-single-button-font-size: null !default;
$price-single-button-line-height: null !default;
$price-single-button-letter-spacing: null !default;
$price-single-button-padding: null !default;
$price-single-button-width: null !default;
$price-single-button-disabled-background-color: null !default;
$price-single-button-disabled-cursor: null !default;
$price-single-button-disabled-pointer-events: null !default;
$sub-menu-actions-padding-top: null !default;
$filter-menu__sub-menu-options-padding-bottom: null !default;
$sub-menu-actions-padding-bottom: null !default;
$price-single-button-margin-bottom: null !default;
$input-range-focus-outline: 1px dotted $black !default;
$range-input-webkit-slider-thumb-outline-color: -webkit-focus-ring-color !default;
$range-input-webkit-slider-thumb-outline-style: auto !default;
$range-slider-text-input-background: null !default;
$range-slider-text-input-border: $input-border !default;
$range-slider-text-input-border-radius: 0 !default;
$range-slider-text-input-color: get-border-value($input-border, 'color') !default;
$range-slider-text-input-font-family: null !default;
$range-slider-text-input-font-weight: null !default;
$range-slider-text-input-font-size: null !default;
$range-slider-text-input-height: rem-calc(40) !default;
$range-slider-text-input-letter-spacing: null !default;
$range-slider-text-input-line-height: $range-slider-text-input-height !default;
$range-slider-text-input-width: null !default;
$range-slider-text-input-zindex: null !default;
$range-slider-text-input-padding: 0 !default;
$range-slider-output-top: 0 !default;

@if $include-html-range-slider-classes != false {
	.range-slider {
		@include clearfix();
		left: $range-slider-left;
		margin: $range-slider-margin;
		padding-top: $range-slider-padding-top;
		position: relative;
		text-align: center;

		// Small up
		@include breakpoint(medium) {
			padding-top: $range-slider-output-height-medium + $range-slider-output-bottom-spacing-medium;
		}

		// Medium up
		@include breakpoint(large) {
			padding-top: $range-slider-output-height-large + $range-slider-output-bottom-spacing-large;
		}

		.track {
			bottom: $range-slider-thumb-diameter / 2;
			height: $range-slider-track-height;
			left: 0;
			margin-bottom: $range-slider-track-height / -2;
			position: absolute;
			width: 0;
			z-index: 50;

			// Small up
			@include breakpoint(medium) {
				bottom: $range-slider-thumb-diameter-medium / 2;
			}

			// Medium up
			@include breakpoint(large) {
				bottom: $range-slider-thumb-diameter-large / 2;
			}

			&--full {
				background: $range-slider-track-full-color;
				width: 100%;
			}

			&--included {
				background: $range-slider-track-included-color;
				border-radius: $range-slider-track-height / 2;
			}
		}

		.slider-thumb {
			background: $range-slider-thumb-color;
			border-radius: 50%;
			cursor: pointer;
			display: none;
			height: $range-slider-thumb-diameter;
			left: 0;
			position: absolute;
			width: $range-slider-thumb-diameter;
			z-index: 101;

			// IE10+ hack
			// sass-lint:disable-block no-vendor-prefixes
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				display: block;
			}

			// Small up
			@include breakpoint(medium) {
				height: $range-slider-thumb-diameter-medium;
				width: $range-slider-thumb-diameter-medium;
			}

			// Medium up
			@include breakpoint(large) {
				height: $range-slider-thumb-diameter-large;
				width: $range-slider-thumb-diameter-large;
			}
		}

		[type=range] {
			appearance: none;
			background: none;
			height: $range-slider-thumb-diameter;
			margin: 0;
			outline: none;
			padding: 0;
			pointer-events: none;
			position: relative;
			width: 100%;
			z-index: 75;

			// Small up
			@include breakpoint(medium) {
				height: $range-slider-thumb-diameter-medium;
			}

			// Medium up
			@include breakpoint(large) {
				height: $range-slider-thumb-diameter-large;
			}

			&:focus {
				outline: none;

				&::-webkit-slider-thumb { // sass-lint:disable-line no-vendor-prefixes
					outline-color: $range-input-webkit-slider-thumb-outline-color; // sass-lint:disable-line no-vendor-prefixes
					outline-style: $range-input-webkit-slider-thumb-outline-style;
				}

				&::-moz-range-thumb { // sass-lint:disable-line no-vendor-prefixes
					outline: $input-range-focus-outline;
				}

				&::-ms-thumb { // sass-lint:disable-line no-vendor-prefixes
					outline: $input-range-focus-outline;
				}
			}

			&::-moz-focus-outer { // sass-lint:disable-line no-vendor-prefixes
				border: 0;
			}

			&:first-of-type {
				float: left;
				margin-bottom: -1 * $range-slider-thumb-diameter;

				// Small up
				@include breakpoint(medium) {
					margin-bottom: -1 * $range-slider-thumb-diameter-medium;
				}

				// Medium up
				@include breakpoint(large) {
					margin-bottom: -1 * $range-slider-thumb-diameter-large;
				}
			}

			&:last-of-type {
				float: right;
				margin-bottom: 0;
			}

			// Note that while we're repeating code here,
			// that's necessary as you can't comma-separate these type of selectors.
			// Browsers will drop the entire selector if it doesn't understand a part of it.
			// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

			//////////////////////////////////////
			// Track styling
			//////////////////////////////////////
			&::-webkit-slider-runnable-track { // sass-lint:disable-line no-vendor-prefixes
				background: none;
				border: 0;
				height: $range-slider-track-height;
				z-index: -1;
			}

			&::-ms-fill-lower { // sass-lint:disable-line no-vendor-prefixes
				background: none;
				border: 0;
			}

			&::-ms-fill-upper { // sass-lint:disable-line no-vendor-prefixes
				background: none;
				border: 0;
			}

			&::-ms-track { // sass-lint:disable-line no-vendor-prefixes
				background: transparent;
				border: 0;
				border-color: transparent;
				color: transparent;
				height: $range-slider-track-height;
				z-index: -1;
			}

			&:focus::-ms-fill-lower { // sass-lint:disable-line no-vendor-prefixes
				background: none;
				border: 0;
			}

			&:focus::-ms-fill-upper { // sass-lint:disable-line no-vendor-prefixes
				background: none;
				border: 0;
			}

			&::-moz-range-track { // sass-lint:disable-line no-vendor-prefixes
				appearance: none;
				background: none;
				border: 0;
				height: $range-slider-track-height;
				z-index: -1;
			}

			//////////////////////////////////////
			// Slider thumb styling
			//////////////////////////////////////
			&::-webkit-slider-thumb { // sass-lint:disable-line no-vendor-prefixes
				appearance: none;
				background: $range-slider-thumb-color;
				border: 0;
				border-radius: 50%;
				cursor: pointer;
				height: $range-slider-thumb-diameter;
				margin-top: $range-slider-thumb-margin-top;
				pointer-events: all;
				position: relative;
				width: $range-slider-thumb-diameter;
				z-index: 100;

				// Small up
				@include breakpoint(medium) {
					height: $range-slider-thumb-diameter-medium;
					margin-top: $range-slider-thumb-margin-top-medium;
					width: $range-slider-thumb-diameter-medium;
				}

				// Medium up
				@include breakpoint(large) {
					height: $range-slider-thumb-diameter-large;
					margin-top: $range-slider-thumb-margin-top-large;
					width: $range-slider-thumb-diameter-large;
				}
			}

			&::-ms-thumb { // sass-lint:disable-line no-vendor-prefixes
				background: $range-slider-thumb-color;
				border: 0;
				border-radius: 50%;
				cursor: pointer;
				height: $range-slider-thumb-diameter;
				margin-top: 0;
				pointer-events: all;
				position: relative;
				width: $range-slider-thumb-diameter;
				z-index: 100;

				// Small up
				@include breakpoint(medium) {
					height: $range-slider-thumb-diameter-medium;
					width: $range-slider-thumb-diameter-medium;
				}

				// Medium up
				@include breakpoint(large) {
					height: $range-slider-thumb-diameter-large;
					width: $range-slider-thumb-diameter-large;
				}
			}

			&::-moz-range-thumb { // sass-lint:disable-line no-vendor-prefixes
				appearance: none;
				background: $range-slider-thumb-color;
				border: 0;
				border-radius: 50%;
				cursor: pointer;
				height: $range-slider-thumb-diameter;
				margin-top: $range-slider-thumb-margin-top;
				pointer-events: all;
				position: relative;
				width: $range-slider-thumb-diameter;
				z-index: 100;

				// Small up
				@include breakpoint(medium) {
					height: $range-slider-thumb-diameter-medium;
					margin-top: $range-slider-thumb-margin-top-medium;
					width: $range-slider-thumb-diameter-medium;
				}

				// Medium up
				@include breakpoint(large) {
					height: $range-slider-thumb-diameter-large;
					margin-top: $range-slider-thumb-margin-top-large;
					width: $range-slider-thumb-diameter-large;
				}
			}
		}

		.output,
		output, 
		.fixed-price-range {
			background: $range-slider-output-background;
			border: $range-slider-output-border;
			border-radius: $range-slider-output-border-radius;
			color: $range-slider-output-color;
			display: inline-block;
			font-family: $range-slider-output-font-family;
			font-size: $range-slider-output-font-size;
			font-weight: $range-slider-output-font-weight;
			height: $range-slider-output-height;
			left: 50%;
			letter-spacing: $range-slider-output-letter-spacing;
			line-height: $range-slider-output-line-height;
			padding: $range-slider-output-padding;
			position: absolute;
			text-align: center;
			top: $range-slider-output-top;
			transform: translate(-50%, 0);
			width: $range-slider-output-width;
			z-index: $range-slider-output-zindex;
		}

		.range-text-input {
			background: $range-slider-text-input-background;
			border: $range-slider-text-input-border;
			border-radius: $range-slider-text-input-border-radius;
			color: $range-slider-text-input-color;
			display: inline-flex;
			align-items: baseline;
			font-family: $range-slider-text-input-font-family;
			font-size: $range-slider-text-input-font-size;
			font-weight: $range-slider-text-input-font-weight;
			height: $range-slider-text-input-height;
			letter-spacing: $range-slider-text-input-letter-spacing;
			line-height: $range-slider-text-input-line-height;
			padding: $range-slider-text-input-padding;
			position: absolute;
			text-align: left;
			top: 0;
			width: $range-slider-text-input-width;
			z-index: $range-slider-text-input-zindex;
			box-sizing: content-box;
			cursor: text;

			&.text-range-1 {
				left: 0;
			}
			&.text-range-2 {
				right: 0;
			}
			.text {
				padding: 0;
				margin: 0;
				color: $range-slider-text-input-color;
				font-family: $range-slider-text-input-font-family;
				font-size: $range-slider-text-input-font-size;
				font-weight: $range-slider-text-input-font-weight;
				line-height: $range-slider-text-input-line-height;
				height: $range-slider-text-input-height;
				flex: 1 1 auto;

				&,
				&:focus,
				&:hover,
				&:focus-within {
					border: 0;
					outline: 0;
				}
			}
		}

		div.fixed-price-range {
			left: rem-calc(146);
			position: absolute;
			width: 100%;
			display: flex;
			text-transform: uppercase;
			font-family: $range-slider-output-font-family;
			
			@include breakpoint(small) {
				padding-top: rem-calc(10);
			}

			span {
				padding-right: rem-calc(5);
				white-space: nowrap;
			}
		}
	}

	.white-background-enabled {
		.range-slider {
			output {
				background-color: $white;
			}
		}
	}

	.range-slider.editable-inputs {
		padding-top: $range-slider-padding-top;

		@include breakpoint(medium) {
			padding-top: $range-slider-padding-top;
		}

		@include breakpoint(large) {
			padding-top: $range-slider-padding-top;
		}
	}

	.range-slider.fixed-label,
	.range-slider.editable-inputs {
		.output,
		output {
			display: none;
		}
	}
	.sub-menu-actions {
		padding-top: $sub-menu-actions-padding-top;
		padding-bottom: $sub-menu-actions-padding-bottom;
		.price-single-button {
			background: $price-single-button-background;
			font-size: $price-single-button-font-size;
			margin-bottom: $price-single-button-margin-bottom;
			line-height: $price-single-button-line-height;
			letter-spacing: $price-single-button-letter-spacing;
			padding: $price-single-button-padding;
			width: $price-single-button-width;
			&.disabled {
				background-color: $price-single-button-disabled-background-color;
				cursor: $price-single-button-disabled-cursor;
				pointer-events: $price-single-button-disabled-pointer-events;
			}
		}
	}
}
