//
// Secondary Navigation
// used as left sidebar on article pages
//

@mixin napa-secondary-nav {
    .secondarynavigation {
        h3 {
            font-size: em-calc(24);
            font-weight: normal;
            margin-top: 1em;
        }

        &.layout-vertical {
            ul {
                list-style: none;
                margin-left: 0;

                li {
                    font-size: em-calc(14);
                    margin-top: 1em;
                    padding-left: em-calc(16, 14);

                    &.has-children {
                        padding-left: 0;

                        & > a,
                        & > span {
                            color: get-color(burnt-orange);

                            &:before {
                                @include icomoonFont($icon-arrow-right-indicator);
                                display: inline-block;
                                width: em-calc(16, 14);
                            }
                        }


                        ul {
                            display: none;
                        }

                        &.active {

                            & > a:before,
                            & > span:before {
                                @include icomoonFont($icon-arrow-down-indicator);
                            }

                            ul {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.layout-horizontal {
            margin-bottom: 30px;
            text-align: center;

            .inline-list {
                margin-bottom: 0;

                li {
                    float: none;
                    display: inline-block;

                    a,
                    span {
                        display: inline;
                    }

                    &.active {
                        a,
                        span {
                            color: get-color(burnt-orange);
                        }
                    }

                    &.has-children {
                        > ul {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
