// ********************************
// *
// * Image with Hotspots Component
// *
// * Handles styles for Image With Hotspots and
// * Carousel Slide - Image With Hotspots components
// *
// ********************************

$image-with-hotspots-marker-color: null !default; // Setting the color prevents inline colors from working
$image-with-hotspots-marker-size: 38px !default; // Total outer size
$image-with-hotspots-marker-border-width: 2px !default;
$image-with-hotspots-marker-border-gap: 3px !default;



// Style the SVG circles that are placed as visual markers
@mixin image-with-hotspots-marker($color: $image-with-hotspots-marker-color) {
	// Animation sequence used to fade out and back in
	@keyframes pulse-opacity {
		0%,
		100% {
			opacity: 1;
		}

		50% {
			opacity: 0.5;
		}
	}

	// Calculate radius for middle of stroke on outer circle
	$outer-radius: ($image-with-hotspots-marker-size/2) - ($image-with-hotspots-marker-border-width/2);
	// Calculate radius of inner circle
	$inner-radius: $outer-radius - $image-with-hotspots-marker-border-gap - ($image-with-hotspots-marker-border-width/2);

	// Primary circle
	&-inner {
		fill: $color;
		r: $inner-radius;
	}

	// Offset border
	&-outer {
		fill: none;
		r: $outer-radius;
		stroke: $color;
		stroke-width: $image-with-hotspots-marker-border-width;
	}

	&:hover {
		.marker-inner,
		.marker-outer {
			animation: pulse-opacity 1.25s infinite;
		}

		.marker-outer {
			animation-delay: 0.25s;
		}
	}
}

// Style the hotspot markers
@mixin cms-image-with-hotspots {
	.image-with-hotspot  {
		margin: 0;

		.marker {
			@include image-with-hotspots-marker;
		}
	}
}
