@import 'catalog/search-results-tabs';

@mixin napa-search-results-tab {
  font-size: 16px;
  margin-bottom: 20px;
  margin-right: 0;
  margin-top: 0;
  text-transform: none;
  width: 50%;

  &:last-child {
    margin-left: 0;
  }

  @include breakpoint(medium) {
    padding-top: 12px;
  }
}

@if $include-html-search-results-tabs-classes != false {
  .search-result-tab {
    @include napa-search-results-tab;
  }
}
