// ********************************
// *
// * Image (or video) with Overlay Component
// *
// * Handles styles for Image With Overlay,
// * Carousel Slide - Image With Overlay,
// * Video With Overlay, and
// * Carousel Slide - Video With Overlay
// *
// ********************************

$inner-positions: ('top' 'bottom' 'middle') !default;

@mixin cms-image-with-overlay() {
	.carouselslideimagewithoverlay,
	.carouselslidevideowithoverlay,
	.image_with_overlay2,
	.video_with_overlay {

		.overlay-content {
			.columns {
				padding: rem-calc(map-get($grid-column-gutter, 'small')/2);
			}
		}

		// Responsive vertical alignment styles
		.media .overlay .wrapper .inner {
			@include -zf-each-breakpoint {
				@each $position in $inner-positions {
					&.#{$-zf-size}-inner-#{$position} {
						vertical-align: #{$position};
					}
				}
			}
		}

		//Horizontal positioning styles
		@include breakpoint(medium) {
			& [class*="medium-offset-"]:not(.medium-offset-0) {
				&.medium-uncentered {
					float: right !important;
				}
			}
		}

		@include breakpoint(large) {
			& [class*="large-offset-"]:not(.large-offset-0) {
				&.large-uncentered {
					float: right !important;
				}
			}

			.inner .columns.large-centered {
				@include grid-column-f4($center: true, $collapse: null, $float: false);
			}
		}
	}
}
