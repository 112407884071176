// *******************************************************************
// * Unbranded layout object for facilitating images with overlay text
// *
// * Brand-specific and component-specific styling, hover states, etc should be handled elsewhere
// *
// * Usage:
//
//	<a href="http://example.com">
//		<div class="media">
//			<--- picturefill image inserts here-->
//			<div class="overlay">
//				<div class="wrapper">
//					<div class="inner">
//						centered overlay content goes here
//					</div>
//				</div>
//			</div>
//		</div>
//	</a>
// *
// * Theoretically, this can all be replaced with flexbox
// *
// *******************************************************************

$media-block-video-position: null !default;


// Mixin so that this can easily be reused and extended elsewhere
@mixin media() {
	position: relative;
	overflow: hidden;

	a {
		outline-offset: -1px; // offset focus outline because overflow hidden masks it
	}

	picture img {
		width: 100%;
	}

	.overlay {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		pointer-events: none;

		a {
		    pointer-events: auto;
		}

		form.signup {
			pointer-events: auto;
			padding: 0;
			background: none;

			.form-error {
				background: white;
				padding: 8px;
				margin-top: 0;
				text-align: center;
			}
		}

		.wrapper {
			display: table;
			height: 100%;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;

			.inner {
				display: table-cell;
				text-align: center;
				vertical-align: middle;
			}

		}
	}
}

// Media Blocks
@mixin cms-media-block {
	.media {
		@include media;

		&.video {
			position: $media-block-video-position;
		}
	}
}
