// ********************************
// *
// * Quickshop Image
// *
// * Image with a quickshop link that falls back
// * to a pdp link when in mobile
// *
// ********************************

@mixin cms-quickshop-image() {
	.quickshop-image {
		position: relative;

		.quickshop-link {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
}
