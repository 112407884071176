//We use this mixin to style the ratings stars. They are provided via icon fonts
// product-ratings mixin variables
$product_ratings_color: get-color(primary) !default; // The color of active ratings stars
$product_ratings_off_color: get-color(secondary) !default; // The color of the off state of ratings stars
$product_ratings_size: 1.2em !default; // The size of ratings stars
$product_ratings_size_medium: null !default;
$product_ratings_spacing: -6px !default; // Fine-tune the gap between stars
$product_ratings_spacing_margin_medium: null !default;
$product_ratings_line-height: null !default;


$include-html-ratings-classes: $include-html-classes !default;

@mixin product-ratings() {
	color: $product_ratings_off_color; // Disabled Stars
	cursor: pointer;
	font-size: $product_ratings_size;
	line-height: $product_ratings_line-height;
		@include breakpoint(medium) {
			font-size: $product_ratings_size_medium;
		}

	span {
		display: inline-block;
		margin: 0 $product_ratings_spacing / 2;
		position: relative;
		@include breakpoint(medium) {
			margin: $product_ratings_spacing_margin_medium;
		}
	}

	.active {
		color: $product_ratings_color; // Enabled Stars
	}

	.partial::after {
	    @include icomoonFont($icon-star);
		color: $product_ratings_color;
	    left: 0;
	    top: 0;
	    overflow: hidden;
	    position: absolute;
	    width: 50%;
	}
}

@mixin PowerReviewsPartialStarRatings {
	$increment: 10;

	@for $i from 0 through 9 {
		$value: $increment * $i;

		.partial-#{$value} {
			&::after {
				width: #{"#{$value}%"};
			}
		}
	}
}

@if($include-html-ratings-classes) {
	.rating {
		@include product-ratings;
		@include PowerReviewsPartialStarRatings;
	}
}
