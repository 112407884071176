//
// Email Signup form
//

@import 'cms/components/email-signup';

@mixin napa-email-signup-button() {
  font-size: $email_signup_form_label_font_size;
  text-transform: lowercase;
}

@mixin napa-email-signup-text-input() {
  border-bottom: 1px solid $medium-gray;
}

@mixin napa-email-signup() {

  section.emailsignup {

    button {
      @include napa-email-signup-button;
    }

    input {
      @include napa-email-signup-text-input;
    }
  }
}
