@import 'global/panels';

.topnav-minicart-panel {
  right: 0;
  top: 44px;
  width: 100%;

  &::before {
    display: none;
  }

  @include breakpoint(large) {
    background-color: $white;
    width: 329px;
  }

  .notification {
    background-color: $white;
    border-left: $topMiniCartCartBorder;
    border-right: $topMiniCartCartBorder;
    margin: 0 auto;
    padding: 15px;
    text-align: center;
    text-transform: none;
    width: 100%;

    &-text {
      background-color: get-color(white-smoke);
      padding: 15px;
    }

    &::before {
      background-color: get-color(burnt-orange);
      content: '';
      height: 2px;
      position: absolute;
      right: 68px;
      top: -3px;
      width: 25px;

      @include breakpoint(medium) {
        right: 50px;
      }
    }

    h2 {
      font-size: em-calc(16px);

      .icon-shopcart {
        display: none;
      }
    }

    .icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .button {
      text-transform: lowercase;
      width: 60%;
    }
  }

  .cart-list {
    font-size: rem-calc(14px);

    .cart-item-col {
      .item-price {
        color: $black;
      }

      .item-name {
        padding-right: $column-gutter;
      }

      &.product-image {
        padding-right: $column-gutter/2;
      }
    }

    .cart-item-links {
      padding-top: 0;
      position: absolute;
      right: 0;
      top: 2px;
      width: 24px;
    }

    .content-head {
      text-align: left;
    }

    .page-title {
      font-size: em-calc(14px);
    }

    .section-title {
      float: right;
      font-size: em-calc(14px);
    }

    .cart-actions-item {
      margin-bottom: 0;
    }
  }
}

