// ================
//  Upcoming Events
// ================

@mixin napa-event-listing {
  .eventslisting {

    //Event listing
    .cd-event-item-wrap {

      &:first-child {
        border: 0;
      }

      .cd-event-item-content {
        @include clearfix;
        padding: 40px 11px;
      }

      .event-image {
        display: none;

        @include breakpoint(medium) {
          @include grid-column($columns: 3);
          min-height: 260px;
          padding: 0;
        }
      }

      .store-short-description {
        @include grid-column-f4($columns: 12, $offset: 0);
        padding: 0 40px;

        @include breakpoint(medium) {
          @include grid-column-f4($columns: 5, $offset: 0.5);
          padding: 0;
        }

        .mobile-dates {
          display: inline;
          font-size: em-calc(18);
          line-height: em-calc(33, 18);

          @include breakpoint(medium) {
            display: none;
          }
        }

        .full-dates {
          display: none;

          @include breakpoint(medium) {
            display: inline;
          }
        }

        .dates {
          font-size: em-calc(18);
          line-height: em-calc(39, 18);
          text-transform: uppercase;

          sup {
            position: relative;
            top: -2px;

            @include breakpoint(medium) {
              top: -3px;
            }
          }

          .full-dates {
            font-size: em-calc(18);
          }
        }

        p {
          display: none;

          @include breakpoint(medium) {
            display: block;
            font-size: em-calc(13);
            line-height: em-calc(24, 13);
            margin-bottom: 20px;
          }
        }

        .details-button {
          display: none;

          @include breakpoint(medium) {
            display: block;
          }
        }
      }

      .event-location-time {
        @include grid-column-f4($columns: 12, $offset: 0);
        padding: 0 40px;

        .social-media {
          display: none;
        }

        @include breakpoint(medium) {
          @include grid-column-f4($columns: 2.5, $offset: 1);
          padding: 0;

          .social-media {
            display: block;
          }
        }

        .location-time {
          font-size: em-calc(22);
          line-height: em-calc(37, 22);

          @include breakpoint(medium) {
            font-size: em-calc(13);
            line-height: em-calc(24, 13);
          }
        }

        a {
          font-size: em-calc(22);
          line-height: em-calc(37, 22);

          @include breakpoint(medium) {
            font-size: em-calc(13);
            line-height: em-calc(24, 13);
          }

          &.show-on-map-link {
            &::after {
              @include icomoonFont($icon-location);
            }

            padding-left: 15px;

            &:hover {
              color: $black;
            }
          }
        }

        .details-button {
          display: inline-block;
          font-size: em-calc(14, 13);
          margin-top: 20px;
          width: 100%;

          @include breakpoint(medium) {
            display: none;
          }
        }
      }
    }

    .call-out-wrap {
      background: $black;
      border: 0;
      overflow: hidden;
      padding: 0;
      position: relative;

      @include breakpoint(medium) {
        padding: initial;
      }

      .background {
        height: 342px;
        opacity: 0.35;
        position: absolute;
        top: 0;
        width: 100%;

        img {
          left: 50%;
          margin-left: -635px;
          max-width: 1270px;
          position: absolute;
          width: 1270px;
        }
      }

      .heading {
        color: $white;
      }

      .store-short-description {
        h3 {
          color: $white;
        }

        p {
          color: $light-gray;
        }
      }

      .location-time {
        color: $light-gray;
      }
    }

    .heading {
      font-size: em-calc(36);
      line-height: em-calc(37, 36);
      margin-bottom: 0;

      @include breakpoint(medium) {
        font-size: em-calc(30);
        line-height: em-calc(34, 30);
        text-transform: uppercase;
      }
    }
  }

  #cd-event-list-wrap {
    padding: 0 0 80px;
    position: relative;
  }
}
