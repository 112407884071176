//
// Content Detail Teaser Styles
//

@mixin napa-content-detail-teaser {
  .contentdetailteaser {
    margin: em-calc(16 0);

    h3,
    h4 {
      margin-bottom: em-calc(8);
    }

    .event {
      margin-bottom: em-calc(10);

      .event-date {
        font-style: italic;
        text-transform: uppercase;
      }
    }

    .button {
      margin-top: em-calc(20);
    }

    @include breakpoint(large) {
      margin: 0;
    }
  }
}
