// ********************************
// *
// * Image Hover Captions
// *
// * This allows for images that have captions on hover as provided via the Image component.
// * Almost everything using CSS for responsive design behaviors. A tiny bit of javascript
// * in cms/hovercaptions is used to enhance a couple of spots where CSS is insufficient.
// *
// *
// * The Image Component has Three states:
// *		1. Image by itself - this SCSS file doesn't get applied
// *		2. Image w/ caption - Caption is displayed on mouseover, or via tap on touch devices
// *		3. Image w/ caption, and w/ link - Caption is displayed on mouseover. Tapping on touch
// *			devices triggers the link instead of display the caption.
// *
// *
// * ---- This depends on global/_media.scss ----
// *
// ********************************

$hover_caption_transition_duration: 0.25s !default;
$hover_caption_overlay_color: get-color(primary) !default;
$hover_caption_overlay_font_color: $white !default;
$hover_caption_overlay_font_family: $header-font-family !default;
$hover_caption_overlay_font_size: $global-font-size !default;
$hover_caption_overlay_font_weight: null !default;
$hover_caption_overlay_text_transform: none !default;

@mixin display-caption(
  $hide-close-button: true
) {
  .overlay {
    opacity: 1;	// display quote

    // Hide the close X for users that can hover
    // and are hovering
    @if $hide-close-button {
      .inner::after {
        opacity: 0; // Hide the close X
      }
    }
  }

  &::after {
    opacity: 0; // hide plus icon
  }
}


// We use this mixin to create image hover captions.
@mixin cms-hover-caption {
  // Hover Caption style applied to .media elements
  .media.hover-caption {
    // Caption
    .overlay {
      opacity: 0;
      transition: opacity $hover_caption_transition_duration;

      .inner {
        background: transparentize($hover_caption_overlay_color, 0.1);
        color: $hover_caption_overlay_font_color;
        font-family: $hover_caption_overlay_font_family;
        font-size: $global-font-size;
        font-weight: $hover_caption_overlay_font_weight;
        line-height: 1.5em;
        text-transform: $hover_caption_overlay_text_transform;

        // Close icon
        &::after {
          @include icomoonFont($icon-close);
          position: absolute;
          right: em-calc(10, 14);
          top: em-calc(10, 14);
        }
      }
    }

    // plus icon to indicate there's something to hover over
    &::after {
      @include icomoonFont($icon-plus);
      background: $hover_caption_overlay_color;
      bottom: 0;
      color: $hover_caption_overlay_font_color;
      height: 3em;
      line-height: 3em;
      opacity: 1;
      position: absolute;
      right: 0;
      text-align: center;
      transition: opacity $hover_caption_transition_duration;
      width: 3em;
    }

    // Enables the active class that is toggled when clicking the
    // The image with hover caption. This allows the caption to be
    // shown to user that cannot hover
    html:not(.user-can-hover) &.active {
      @include display-caption($hide-close-button: false);
    }

    // Enable hovering for users that can hover.
    // Some mobile devices will trigger the hover
    // effect on clicking. We want to prevent that
    // So we can just toggle the class above
    .user-can-hover &:hover {
      @include display-caption;
    }

    // Support focusing as best we can.
    // This is to allow keyboard users to trigger the
    // caption when focusing on the anchor link
    &:focus,
    &:focus-within {
      @include display-caption;
    }
  }
}
