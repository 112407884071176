// ==================================
// Overlay background
// ==================================
// This component adds support for non-foundation 4 based overlay backgrounds.
// It provides the same functionality and is another step towards the migration to
// foundation 6.
//
//  TODO:
//  REMOVE THIS FILE WHEN TRANSITION TO
//  CMS TOP NAV & FOUNDATION 6 IS COMPLETE
//

@import 'global/z-indexes';


// We use these to control the style of the overlay overlay.
$overlay_transition_duration: 0.25s !default;

@mixin overlay-bg {
	@include reveal-overlay();
    @include z-index(reveal);
    display: none;
    opacity: 0;
    visibility: hidden;

    &.active {
        animation: $overlay_transition_duration fadeInFromNone;
        display: block;
        opacity: 1;
        visibility: visible;
    }
}

@mixin fadeInFromNone-animation {
    @keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }

        1% {
            display: block;
            opacity: 0;
        }

        100% {
            display: block;
            opacity: 1;
        }
    }
}

@mixin overlay {
    @include fadeInFromNone-animation;

    .overlay-bg {
        @include overlay-bg;
    }
}
