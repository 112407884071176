// ***
// * Related Content Component
// ***

@mixin napa-related-content {
    section.relatedcontent {
        @include component-headline();

        .garnish-full-width {
            &.top-pad-default {
                padding-top: 0;
            }

            &.bottom-pad-default {
                padding-bottom: 0;
            }
        }

        h3 {
            font-size: em-calc(36);
            margin-bottom: 40px;

            @include breakpoint(large) {
                font-size: em-calc(14);
                margin: 0 0 20px;
            }
        }

        h4.accent {
            font-size: em-calc(14);
        }
    }
}
