@function encode-color($string) {
  @if type-of($string) == 'color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }
  $string: '%23' + $string;
  @return $string;
}

@mixin setStyle($map) {
  @each $key, $value in $map {
    #{$key}: #{$value};
  }
}

@mixin setFontStyle($map) {
  @if(type-of($map) == 'map') {
    @each $key, $value in $map {
      @if($key == 'font') {
        @include setStyle($value);
      } @else {
        @include breakpoint($key) {
          @include setStyle($value);
        }
      }
    }
  }
}

@mixin large-title($font-size) {
  color: $header-color;
  font-size: $font-size;
  font-weight: bold;
}

@mixin button-background-color($color) {
  background-color: $color;

  &:hover {
    background-color: $button-hover;
  }
}


@mixin set-button-secondary {
  $btnMediumPadding: 15px 20px !default;
  $btnMediumFontSize: 1em !default;
  font-size: $btnMediumFontSize;
  padding: convertPxToVw(20px, $vwunit-base) convertPxToVw(22.5px, $vwunit-base);

  @include breakpoint(medium) {
    font-size: convertPxToVw($btnMediumFontSize, ($vwunit-base / 0.75));
    padding: 11.5px convertPxToVw(23, $vwunit-base);
  }

  @include breakpoint(large) {
    font-size: $btnMediumFontSize;
    padding: $btnMediumPadding;
  }
}

@mixin rotated-text($num-letters: 100, $angle-span: 180deg, $angle-offset: 0deg) {
  $angle-per-char: 4;
  @for $i from 1 through $num-letters {
    &.char#{$i} {
    transform: (rotate($angle-offset + $angle-per-char * $i));
    }
  }
}


//This mixin is for generating cross browser border circles
@mixin border-circle ($radius) {
  border-radius: $radius;
  width: $radius;
  height: $radius;
}

@mixin placeholder($color) {
    &::placeholder {
        color: $color;
    }

    // Firefox 19+
    // applies a default style of opacity: 0.54 to placeholder text.
    // See bug 556145.  https://bugzilla.mozilla.org/show_bug.cgi?id=556145
    // Most other major browsers don't currently share this particular
    // default style for their placeholder text pseudo-elements or pseudo-classes.
    //
  // sass-lint:disable-block no-vendor-prefixes
    &::-moz-placeholder {
        opacity: 1;
    }
}

// This mixin provides the base styles for usage of the Icomoon font.
// These styles have been copied from Icomoon generator and should not be modified.
// Intended to be used by the icomoonFont mixin and the %icon extended
@mixin icomoonBase {
  font-family: 'icomoon' !important; // sass-lint:disable-line no-important // Prevent issues with browser extensions that change fonts
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  // Better Font Rendering
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes // Apple-specific font smoothing
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes // Firefox on OSX-specific font smoothing
}

// This mixin provides the base icon font styling since @extend .icon won't work in Media Queries
// Use as follows:
// @media (....) {
//  .element {
//    &:before {
//    Icon character you wish to use.
//      content: $icon-search;
//
//    Icon character is optional if you set it above using content:'...'
//      @include icomoonFont($icon-search);
//
//    false can be added as 2nd option to prevent base icon styles from being included to prevent redundancy
//      This should be used only the content value needs an override
//      @include icomoonFont($icon-search, false);
//
//    Other properties such as font-size can be added for further adjustments
//      font-size: 16px;
//    }
//  }
// }
@mixin icomoonFont($icon: null, $includeBase: true) {
  @if ($icon) {
    content: $icon;
  }
  @if ($includeBase) {
    @include icomoonBase();
  }
}

// Icomoon no longer provides this extender in its generated packages.
// This will provide support for usage of '@extend %icon'
%icon {
  @include icomoonBase();
}

//Transform Scale Mixin
@mixin transform-scale($size, $origin: 0 0) {
    transform: scale($size);
    transform-origin: $origin;
}
//end Transform Scale Mixin

//Foundation 6.4.0 Mixins Shim used in _switch.scss component
@mixin disable-mouse-outline {
  [data-whatinput='mouse'] & {
    outline: 0;
  }
}

//We use this on dl dt lists for native bullet-list
@mixin bullet-list() {
    display: list-item;
    list-style-type: disc;
}

// To account for a ghost space after a display:list-item that's noticable is Safari
// We are faking the bullet from a bullet list by adding a ::before with a content of
// a bullet and offsetting to appear as a bulleted list item and display:inline-block.
@mixin dl-bullet-list() {
  dl {
    @include grid-column-f4($columns: 12, $float: none, $collapse: true);
    padding-left: $column-gutter*0.65;
  }

  dt {
    display: inline-block;
    margin: 0 0 0 $column-gutter * -1; // adds a place for the bullet
    white-space: nowrap;
    float: left;

    &::before {
      content: '\2022'; // Bullet
      display: inline-block;
      text-align: center;
      width: $column-gutter;
    }

    &::after {
      content: ':';
      display: inline-block;
      text-align: center;
      width: 0.5em;
    }
  }

  dd {
    display: block;
    font-size: inherit;
    letter-spacing: inherit;
    line-height: 1;
    margin-bottom: 10px;
  }
}

//
// Vertical Center Align
// @parameter position - CSS position, defaults to relative
// @parameter offset - Further offset the element using top margin, defaults to 0
// @parameter centering - {center|top|bottom} Adjust what reference point on the child element is aligned to center, defaults to center
//
// Example align the center of an element with the center of its container:
//    @include vertical-align();
//
// Example align the bottom of an element with the center of its container:
//    @include vertical-align($centering: 'bottom');
//
@mixin vertical-align($position: relative, $offset: 0, $centering: 'center') {
  $alignments: (
    top: 0%,
    center: -50%,
    bottom: -100%
  );

  margin-top: $offset;
  position: $position;
  top: 50%;
  transform: translateY(map-get($alignments, $centering));
}

//
// Removes the styles set in vertical-align
// @parameter position - CSS posoition, defaults to relative
//
@mixin vertical-align-reset($position: relative) {
  margin-top: auto;
  position: relative;
  top: auto;
  transform: initial;
}

//
// Generates vendor-specific styles for Apple Pay
// https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons
//
@mixin _applepay_button_defaults {
  @include supports(applepay) {
  // sass-lint:disable-block no-vendor-prefixes // These only work on Safari
        .apple-pay-button {
            appearance: -apple-pay-button;
            display: inline-block;
        }

        .apple-pay-button-black {
            -apple-pay-button-style: black;
        }

        .apple-pay-button-white {
            -apple-pay-button-style: white;
        }

        .apple-pay-button-white-with-line {
            -apple-pay-button-style: white-outline;
        }
    }
}

// @supports is like a media query and gets popped out in ways that can
// cause conflicts. This provides a mixin that can be used like the breakpoint() mixin
@mixin supports($condition) {
  @if $condition == applepay {
    @supports (appearance: -apple-pay-button) {
      @content;
    }
  }
}


//
// Hides the native scrollbars on Chrome, Safari and Opera
// https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
//
@mixin hide-safari-scrollbars {
  // sass-lint:disable-block no-vendor-prefixes
  &::-webkit-scrollbar {
    background: transparent;
    display: none;
    height: 0;
  }
}

// Hides scrollbar in all browsers
@mixin hide-scrollbars {
  // IE and Edge
  -ms-overflow-style: none; // sass-lint:disable-line no-vendor-prefixes
  scrollbar-width: none;  // Firefox

  @include hide-safari-scrollbars; // Chrome, Safari and Opera 
}

@mixin toggle-scrollbar($show-scrollbar) {
    &::-webkit-scrollbar {
      background: transparent;
      display: none;
      height: 0;

      @if ($show-scrollbar) {
        background: inherit;
        display: block;
        height: auto;
      }
    }
}

@mixin custom-scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%), $is-rounded: false) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $foreground-color;
    @if ($is-rounded) {
      border-radius: $size / 2;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: $background-color;
  }
  //Firefox
  & {
    scrollbar-width: thin;
    scrollbar-color: $foreground-color $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

//
// Adds an underline to any element using :after
//
@mixin thick-underline-border($borderWeight, $borderBottomPosition, $borderColor, $borderTransitionDuration, $borderPosition: relative) {
  &:after {
    background: $borderColor;
    bottom: $borderBottomPosition;
    content: '';
    display: block;
    height: $borderWeight;
    position: $borderPosition;
    transition: background $borderTransitionDuration;
    width: 100%;
  }
}

//
// Set an image as background, centered, with cover behavior and no repetition
// @parameter $background-image - image path or url
// @parameter $size - as background size, defaults to auto
//
@mixin background-image-cover($background-image) {
  background-image: $background-image;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

//
// Foundation 6 way of getting the input height
//
@function get-form-input-height() {
  $height: ($input-font-size * unitless-calc($input-line-height)) + (get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) - rem-calc(1);
  @return $height;
}

@mixin flex-vertical-align-center {
  align-items: center;
  display: flex;
}


@mixin target-ie {
  // sass-lint:disable-block no-vendor-prefixes
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

//This disables the up and down arrows in input[type=number] box
@mixin disable-input-box-number-spinners {
  input[type="number"] {
    -moz-appearance: textfield; // sass-lint:disable-block no-vendor-prefixes
  }

  input[type="number"]::-webkit-inner-spin-button, // sass-lint:disable-block no-vendor-prefixes
  input[type="number"]::-webkit-outer-spin-button { // sass-lint:disable-block no-vendor-prefixes
    -webkit-appearance: none;
    margin: 0;
  }
}

// This mixin can be used to override the default visibility classes to 
// change the opacity instead of the display type. 
@mixin visibility-classes-opacity(
  $display: inline-block,
  $opacity-hidden:	0,
  $opacity-visible:	1
) {
  display: $display !important; // override default display
  opacity: $opacity-visible;

  // hide opacity on small screens
  &.hide,
  &.hide-for-small-only,
  &.show-for-medium,
  &.show-for-medium-only,
  &.show-for-large,
  &.show-for-large-only {
    opacity: $opacity-hidden;
  }

  @include breakpoint(medium) {
    // hide opacity on medium screens
    &.hide-for-medium,
    &.hide-for-medium-only,
    &.show-for-small-only {
      opacity: $opacity-hidden;
    }

    // show opacity on medium screens
    &.hide-for-small-only,
    &.show-for-medium,
    &.show-for-medium-only {
      opacity: $opacity-visible;
    }
  }

  @include breakpoint(large) {
    // hide opacity on large screens
    &.hide-for-large,
    &.hide-for-large-only,
    &.show-for-medium-only {
      opacity: $opacity-hidden;
    }

    // show opacity on large screens
    &.show-for-large,
    &.show-for-large-only {
      opacity: $opacity-visible;
    }
  }
}
