// ----------------------------------------------------
// VFDP Social Media Icons and Buttons
//
// Use .social-icon.<network> on the clickable element
// to generate a button with a background and hover styles.
//
// Use .icon-<network> to generate the icon by iself
// (see icon fonts)
//
// <li>
//  <span class="social-icon {network}"
//    aria-label="Share on {Network}">
//    <span class="icon-{network}"></span>
//  </span>
// </li>
//
// ----------------------------------------------------

// Default social button background color
// @type Color
$social-button-bg: get-color(primary) !default;

// Default social button hover background color
// Set to force all social buttons to have the same hover color
// @type Color
$social-button-bg__hover: null !default;

// Default social button border
// @type Border
$social-button-border: null !default;

// Default social button icon color
// @type Color
$social-button-color: $black !default;

// Default social button hover icon color
// @type Color
$social-button-color__hover: $white !default;

// Default social button hover transition length
// @type Number
$social-button__duration: $hoverDuration !default;

// Default shape for social icon.
// @type String
$social-button-shape: square !default;

// Default size for a social button
// @type Number
$social-button-size: rem-calc(35px) !default;

// Default size for the icon within the button
// @type Number
$social-button-icon-size: rem-calc(16px) !default;

// Additional social networks to generate
// Primary list comes from vfdp-ui-framework $social-media-palette
// @type Map
$social-networks-supplimentary: (
	mail: get-color(primary),
	print: get-color(primary)
);


//
// Adds the hover style for a social button
// @param {Color} $background__hover - Background hover color for button
// @param {Color} $color__hover - Color of icon when hovered
// @param {Number} $duration - Length of transition
@mixin social-button-hover(
	$background__hover: $social-button-bg__hover,
	$color__hover: $social-button-color__hover,
	$duration: $social-button__duration
) {
	transition: background $hoverDuration, color $hoverDuration;

	// Support multiple states for accessibility
	&:active,
	&:focus,
	&:hover {
		// Set global styles for all networks first
		background: $background__hover; // When not null, all networks will share a common hover background
		color: $color__hover;
	}
}

//
// Changes the background of a social network icon
// to the branded network color on hover
// @param {String} $network - Name of the network
// @param {Color} $background - Override background color instead of network value
// @param {Color} $color - Icon color
@mixin social-button-hover-network(
	$network,
	$background: null,
	$color: null
) {
	// Use the override background provided, otherwise get from the Social palette
	$bg: if($background, $background, get-social-media-color($network));

	// Supports both:
	// 1. <span class="social-icon {network}"><span class="icon-{network}"></span></span>
	// 2. <span class="social-icon icon-{network}"></span>
	// TODO: Remove #2 after refactoring CMS and PDP social sharing buttons
	&.#{$network},
	&.icon-#{$network} {
		&:active,
		&:focus,
		&:hover {
			background: $bg;
			color: $color;
		}
	}
}

//
// Styles the core of a button suitable for social sharing links or
// social profile links.
// @param {Color} $background - Background color for button
// @param {Color} $color - Color of icon
// @param {Number} $icon-size - Text size of the icon
// @param {String} $shape - Button shape
// @param {Number} $size - Height and Width of button
@mixin social-button-base(
	$background: $social-button-bg,
	$border: $social-button-border,
	$color: $social-button-color,
	$icon-size: $social-button-icon-size,
	$shape: $social-button-shape,
	$size: $social-button-size
) {
	background: $background;
	border: $border;
	color: $color;
	cursor: pointer; // Support more than a,button
	display: inline-block;
	font-size: $icon-size;
	height: $size;
	line-height: $size;
	text-align: center;
	text-decoration: none;
	width: $size;

	// Set the button to be a circle
	@if $shape == circle {
		@include border-rounded;
	}

	// Set the button to be pill-shaped
	@if $shape == pill {
		@include border-rounded;
		min-width: $size;
		width: auto;
	}

	// Set the button with rounded corners matching globals
	@if $shape == radius {
		@include border-radius;
	}
}


//
// Styles a button suitable for social sharing links or
// social profile links.
// @param {Color} $background - Background color for button
// @param {Color} $background__hover - Background hover color for button
// @param {Color} $color - Color of icon
// @param {Color} $color__hover - Color of icon when hovered
// @param {Number} $duration - Length of transition
// @param {Number} $icon-size - Text size of the icon
// @param {String} $shape - Button shape
// @param {Number} $size - Height and Width of button
@mixin social-button(
	$background: $social-button-bg,
	$background__hover: $social-button-bg__hover,
	$color: $social-button-color,
	$color__hover: $social-button-color__hover,
	$duration: $social-button__duration,
	$icon-size: $social-button-icon-size,
	$shape: $social-button-shape,
	$size: $social-button-size
) {
	$networks: map-merge(
		$social-media-palette,
		$social-networks-supplimentary
	);

	@include social-button-base(
		$background: $background,
		$color: $color,
		$icon-size: $icon-size,
		$shape: $shape,
		$size: $size
	);
	@include social-button-hover(
		$background__hover: $background__hover,
		$color__hover: $color__hover,
		$duration: $duration
	);

	// Create the network-specific styles, but only if the
	// brand hasn't chosen a single color to override
	@if ($background__hover) {
		// Intentionally left blank because Compass doesn't
		// support @if($var != null) {...}
		// TODO: Remove after changing SASS compilers
	} @else {
		@each $net, $net-color in $networks {
			@include social-button-hover-network(
				$network: $net,
				$background: $net-color,
				$color: null
			);
		}
	}
}

//
// Adds styles for a list of social share butons
// @param {Number} $gutter-x - Gap between columns of buttons
// @param {Number} $gutter-y - Gap between rows of buttons
// @param {String} $el - Item selector to target for children
// TODO: VFDP-522 update the defaults to use Foundation 6 values
@mixin share-buttons-list(
	$gutter-x: $column-gutter/2,
	$gutter-y: $column-gutter/2
) {
	// TODO: Probably a good use case for CSS Grid
	@include inline-list;
	margin: 0; // inline-list() sets a negative left margin

	> li {
		@include share-buttons-list-item(
			$gutter-x: $gutter-x,
			$gutter-y: $gutter-y
		);
	}
}

//
// Adds styles for a single item in a social sharing list
// @param {Number} $gutter-x - Gap between columns of buttons
// @param {Number} $gutter-y - Gap between rows of buttons
// @param {String} $el - Item selector to target for children
// TODO: VFDP-522 update the defaults to use Foundation 6 values
@mixin share-buttons-list-item(
	$gutter-x: $column-gutter/2,
	$gutter-y: $column-gutter/2
) {
	margin-#{$global-left}: $gutter-x/2;
	margin-#{$global-right}: $gutter-x/2;
	margin-bottom: $gutter-y;

	&:first-child {
		margin-#{$global-left}: 0;
	}

	&:last-child {
		margin-#{$global-right}: 0;
	}

	// Support text alignment using Foundation helper classes on parent container
	.text-center &,
	.text-left &,
	.text-right & {
		display: inline-block;
		float: none;
	}
}


@mixin vfdp-social-buttons {
	.share-buttons {
		@include share-buttons-list;
	}

	.social-icon {
		@include social-button;

	}
}

$socialMediaModalBackground: $white !default;
$socialMediaModalWidth: 40% !default;
$socialMediaModalCloseButtonColor: transparent !default;
$socialMediaModalShareButtonsDisplay: flex !default;
$socialMediaModalShareButtonsAlignment: center !default;
$socialMediaModalIconsBackgroundColor: transparent !default;
$socialMediaModalIconsBackgroundHoverColor: transparent !default;
$socialMediaModalIconColor: $black !default;
$socialMediaModalIconHoverColor: get-color(primary) !default;
$socialMediaModalIconSize: rem-calc(32) !default;
$socialMediaModalIconShape: circle !default;
$socialMediaModalIconShapeSize: rem-calc(65) !default;
$socialMediaModalIconBorder: 2px solid $black !default;
$socialMediaModalIconSpacing: rem-calc(20) !default;
$socialMediaModalIconHoverBorder: 2px solid get-color(primary) !default;
$socialMediaModalHeadingDisplay: flex !default;
$socialMediaModalHeadingAlignment: center !default;
$socialMediaModalHeadingFont: null !default;
$socialMediaModalHeadingColor: get-color(primary) !default;
$socialMediaModalSubHeadingFontSize: rem-calc(22) !default;
$socialMediaModalSubHeadingKerning: rem-calc(2) !default;
$socialMediaModalLabelFont: null !default;
$socialMediaModalLabelAlignment: center !default;
$socialMediaModalLabelMargin: rem-calc(5) 0 0 0 !default;
$socialMediaModalTitleFontSize: null !default;
$socialMediaModalLabelDisplay: null !default;
$socialMediaModalInputBorder: null !default;
$socialMediaModalContainerPadding: null !default;
$socialMediaModalContainerTitleFontSize: null !default;
$socialMediaModalContainerTitleLineHeight: null !default;
$socialMediaModalContainerTitlePadding: null !default;
$socialMediaModalInputHeight: null !default;
$socialMediaModalInputWidth: null !default;
$socialMediaModalButtonHeight: null !default;
$socialMediaModalButtonWidth: null !default;
$socialMediaModalIconFontSize: null !default;
$socialMediaModalIconHeight: null !default;
$socialMediaModalIconWidth: null !default;
$socialMediaModalButtonFontSize: null !default;
$socialMediaModalButtonLineHeight: null !default;
$socialMediaModalButtonFontWeight: null !default;
$socialMediaModalCloseIconFontSize: null !default;
$socialMediaModalPadding: null !default;
$socialMediaModalIconHoverColor: null !default;
$socialMediaModalTop: null !default;

@mixin social-media-modal {
	.social-media-share-modal-container {
		&.reveal {
			background: $socialMediaModalBackground;
			padding: $socialMediaModalPadding;
			top: $socialMediaModalTop;
			width: $socialMediaModalWidth;

			.social-media-share-modal-label {
				display: $socialMediaModalLabelDisplay;
			}

			.social-icon {
				border: 0;
			}

			.share-buttons .social-icon {
				border: 0;
				font-size: $socialMediaModalIconFontSize;
				height: $socialMediaModalIconHeight;
				width: $socialMediaModalIconWidth;

				&:hover {
					color: $socialMediaModalIconHoverColor;
				}
			}

			.icon-close {
				font-size: $socialMediaModalCloseIconFontSize;
			}
		}

		.close-button {
			background-color: $socialMediaModalCloseButtonColor;
		}

		.social-media-modal-title {
			font-size: $socialMediaModalTitleFontSize;
			font-weight: 700;
			line-height: rem-calc(31);
			padding-bottom: rem-calc(22);
			padding-top: rem-calc(45);
			text-align: center;

		}

		.share-buttons {
			display: $socialMediaModalShareButtonsDisplay;
			justify-content: $socialMediaModalShareButtonsAlignment;
			@include share-buttons-list($socialMediaModalIconSpacing);
		}

		.social-icon {
			border: $socialMediaModalIconBorder;
			@include social-button($background: $socialMediaModalIconsBackgroundColor,
								   $background__hover: $socialMediaModalIconsBackgroundHoverColor,
								   $color: $socialMediaModalIconColor,
								   $color__hover: $socialMediaModalIconHoverColor,
								   $icon-size: $socialMediaModalIconSize,
								   $shape: $socialMediaModalIconShape,
								   $size: $socialMediaModalIconShapeSize);

			&:hover,
			&:active,
			&:focus {
				border: $socialMediaModalIconHoverBorder;
			}
		}

		.social-media-share-modal-heading,
		.social-media-share-modal-subheading {
			display: $socialMediaModalHeadingDisplay;
			font-family: $socialMediaModalHeadingFont;
			justify-content: $socialMediaModalHeadingAlignment;
		}

		.social-media-share-modal-heading {
			color: $socialMediaModalHeadingColor;
		}

		.social-media-share-modal-subheading {
			font-size: $socialMediaModalSubHeadingFontSize;
			letter-spacing: $socialMediaModalSubHeadingKerning;
		}

		.social-media-share-modal-label {
			font-family: $socialMediaModalLabelFont;
			margin: $socialMediaModalLabelMargin;
			text-align: $socialMediaModalLabelAlignment;
		}

		.social-widget-container {

			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding: $socialMediaModalContainerPadding;

			.social-widget-title {
				display: block;
				font-size: $socialMediaModalContainerTitleFontSize;
				line-height: $socialMediaModalContainerTitleLineHeight;
				padding: $socialMediaModalContainerTitlePadding;
				text-align: center;
				width: 100%;
			}

			.social-widget-input {
				border: $socialMediaModalInputBorder;
				flex: 3;
				font-size: $socialMediaModalButtonFontSize;
				height: $socialMediaModalInputHeight;
				width: $socialMediaModalInputWidth;
			}

			.social-widget-copy-button {
				font-size: $socialMediaModalButtonFontSize;
				font-weight: $socialMediaModalButtonFontWeight;
				height: $socialMediaModalButtonHeight;
				line-height: $socialMediaModalButtonLineHeight;
				width: $socialMediaModalButtonWidth;
			}
		}
	}
}
