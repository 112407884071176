$shop-the-look-padding: 0 0 rem-calc(100) 0 !default;
$shop-the-look-eye-icon-color: get-color(primary) !default;
$shop-the-look-eye-icon-size: rem-calc(28) !default;
$shop-the-look-eye-icon-size-large: rem-calc(35) !default;
$shop-the-look-eye-icon-margin: 0 rem-calc(15) 0 0 !default;
$shop-the-look-header-color: get-color(primary) !default;
$shop-the-look-header-margin: 0 auto !default;
$shop-the-look-header-margin-medium: 0 0 0 rem-calc(40) !default;
$shop-thelook-header-h1-margin: null !default;
$shop-the-look-header-font-size: null !default;
$shop-the-look-header-font-size-large: null !default;
$shop-the-look-header-font-kerning: null !default;
$shop-the-look-header-text-align: center !default;
$shop-the-look-header-text-align-medium: left !default;
$shop-the-look-header-text-transform: null !default;
$shop-the-look-subheader-text-transform: null !default;
$shop-the-look-subheader-font-weight: normal !default;
$shop-the-look-subheader-font-size: null !default;
$shop-the-look-font-face: null !default;
$shop-the-look-nav-font-size: rem-calc(14) !default;
$shop-the-look-nav-margin: 0 auto !default;
$shop-the-look-nav-margin-medium: rem-calc(-20) 0 0 0 !default;
$shop-the-look-nav-margin-large: rem-calc(-40) 0 0 0 !default;
$shop-the-look-nav-width: rem-calc(70) !default;
$shop-the-look-nav-width-medium: rem-calc(80) !default;
$shop-the-look-nav-width-large: rem-calc(100) !default;
$shop-the-look-nav-active-color: get-color(primary) !default;
$shop-the-look-nav-hover-color: get-color(secondary) !default;
$shop-the-look-nav-text-align: center !default;
$shop-the-look-nav-text-align-medium: right !default;
$shop-the-look-nav-li-text-transform: none !default;
$shop-the-look-nav-li-margin: 0 rem-calc(15) 0 0 !default;
$shop-the-look-nav-li-margin-large: 0 rem-calc(30) 0 0 !default;
$shop-the-look-nav-li-text-align: null !default;
$shop-the-look-nav-li-text-hover-color: get-color(primary) !default;
$shop-the-look-nav-li-text-active-color: get-color(secondary) !default;
$shop-the-look-nav-li-border-hover: 5px solid $shop-the-look-nav-hover-color !default;
$shop-the-look-nav-li-border-active: 5px solid $shop-the-look-nav-active-color !default;
$shop-the-look-nav-li-active-text-decoration: none !default;
$shop-the-look-nav-li-active-font-weight: normal !default;
$shop-the-look-nav-li-button-text-decoration: none !default;
$shop-the-look-nav-li-button-font-size: null !default;
$shop-the-look-nav-li-button-font-weight: normal !default;
$shop-the-look-nav-li-button-border-radius: 0 !default;
$shop-the-look-nav-li-button-margin: 0 !default;
$shop-the-look-nav-li-button-padding: 1em 0 !default;
$shop-the-look-nav-li-button-border: medium none black !default;
$shop-the-look-nav-li-button-active-color: white !default;
$shop-the-look-nav-li-button-active-background: black !default;
$shop-the-look-nav-li-button-height: null !default;
$shop-the-look-nav-button-row-margin: 0 rem-calc(-5) 0 0 !default;
$shop-the-look-nav-button-column-padding: 0 rem-calc(5) rem-calc(5) 0 !default;
$shop-the-look-body-color: get-color(primary) !default;
$shop-the-look-kit-image-width: 100% !default;
$shop-the-look-kit-image-height: auto !default;
$shop-the-look-kit-image-width-medium: rem-calc(655) !default;
$shop-the-look-kit-image-height-medium: rem-calc(486) !default;
$shop-the-look-kit-image-width-large: rem-calc(1015) !default;
$shop-the-look-kit-image-height-large: rem-calc(486) !default;
$shop-the-look-kit-card-margin: rem-calc(-50) auto !default;
$shop-the-look-kit-card-margin-medium: 0 !default;
$shop-the-look-kit-card-width: 95% !default;
$shop-the-look-kit-card-width-medium: rem-calc(555) !default;
$shop-the-look-kit-card-height: rem-calc(300) !default;
$shop-the-look-kit-card-heading-margin: rem-calc(5) 0 rem-calc(15) 0 !default;
$shop-the-look-kit-card-background-color: $white !default;
$shop-the-look-kit-card-margin-align-right: 0 0 0 rem-calc(-50) !default;
$shop-the-look-kit-card-margin-align-left: 0 rem-calc(50) 0 0 !default;
$shop-the-look-kit-card-header-font-size: rem-calc(18) !default;
$shop-the-look-kit-card-header-font-size-large: rem-calc(22.5) !default;
$shop-the-look-kit-card-paragraph-font-size: rem-calc(15) !default;
$shop-the-look-kit-card-paragraph-font-size-large: rem-calc(18) !default;
$shop-the-look-kit-card-paragraph-line-height: rem-calc(22) !default;
$shop-the-look-kit-card-paragraph-line-height-large: rem-calc(24) !default;
$shop-the-look-kit-card-paragraph-color: get-color(primary) !default;
$shop-the-look-kit-card-paragraph-padding: 0 rem-calc(15) 0 0 !default;
$shop-the-look-kit-card-body-color: $black !default;
$shop-the-look-kit-card-category-color: $shop-the-look-nav-active-color !default;
$shop-the-look-kit-card-category-size: rem-calc(13) !default;
$shop-the-look-kit-card-category-line-height: rem-calc(18) !default;
$shop-the-look-kit-card-category-font-weight: bold !default;
$shop-the-look-kit-card-category-text-transform: null !default;
$shop-the-look-kit-card-header-color: $black !default;
$shop-the-look-kit-card-top: 0 !default;
$shop-the-look-kit-card-top-medium: rem-calc(($shop-the-look-kit-image-height-medium - $shop-the-look-kit-card-height) / 2) !default;
$shop-the-look-kit-card-icon-color: $shop-the-look-eye-icon-color !default;
$shop-the-look-kit-card-icon-margin: 0 rem-calc(-30px) 0 0 !default;
$shop-the-look-kit-card-icon-arrow-size: rem-calc(30);
$shop-the-look-kit-card-padding: 0 rem-calc(50) 0 rem-calc(50) !default;
$shop-the-look-kit-card-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !default;
$shop-the-look-background-color: get-color(primary) !default;
$shop-the-look-kit-margin: 0 0 rem-calc(80) 0 !default;
$shop-the-look-kit-margin-medium: rem-calc(40) rem-calc(40) 0 !default;
$shop-the-look-kit-margin-large: rem-calc(40) rem-calc(40) 0 !default;

$shop-the-look-grid-padding: rem-calc(15) !default;
$shop-the-look-grid-hover-color: rgba(0, 0, 0, 0.41) !default;
$shop-the-look-grid-content-wrapper-color: white !default;
$shop-the-look-grid-mobile-button-border: medium solid black !default;
$shop-the-look-grid-mobile-button-font-size: null !default;
$shop-the-look-grid-mobile-button-font-weight:  null !default;
$shop-the-look-grid-mobile-button-background: null !default;
$shop-the-look-grid-mobile-button-color: null !default;
$shop-the-look-grid-cell-title-font-size: rem-calc(20) !default;
$shop-the-look-grid-cell-height: rem-calc(50) !default;
$shop-the-look-grid-cell-line-height: rem-calc(50) !default;
$shop-the-look-grid-header-margin: 0 auto !default;
$shop-the-look-grid-nav-header-margin: rem-calc(54) auto rem-calc(39) auto !default;
$shop-the-look-grid-nav-header-margin-medium: rem-calc(109) auto rem-calc(24) auto !default;
$shop-the-look-grid-layout-margin-large: 0 rem-calc(125) !default;
$shop-the-look-overlay-button-font-size: null !default;
$shop-the-look-overlay-button-font-weight: null !default;
$shop-the-look-nav-li-button-background: null !default;
$shop-the-look-nav-li-button-hover-background: null !default;
$shop-the-look-nav-li-button-hover-color: null !default;
$shop-the-look-subheader-padding-top: null !default;
$shop-the-look-header-text-transform: null !default;
$shop-the-look-nav-li-active-color: null !default;
$shop-the-look-nav-li-color: null !default;
$shop-the-look-landing-page-background-color: null !default;
$shop-the-look-bundle-grid-width-medium: null !default;
$shop-the-look-button-border-medium:  null !default;
$shop-the-look-button-hover-border: null !default;
$shop-the-look-grid-cell-display: null !default;
$shop-the-look-grid-mobile-button-font-weight: null !default;
$shop-the-look-grid-mobile-button-letter-spacing: null !default;
$shop-the-look-grid-mobile-button-line-height: null !default;
$shop-the-look-grid-mobile-button-text-decoration: null !default;
$shop-the-look-grid-mobile-button-text-display: null !default;
$shop-the-look-header-font-size-medium: null !default;
$shop-the-look-header-height-medium: null !default;
$shop-the-look-header-letter-spacing-medium: null !default;
$shop-the-look-header-letter-spacing: null !default;
$shop-the-look-header-line-height-medium: null !default;
$shop-the-look-header-width-medium: null !default;
$shop-the-look-height-medium: null !default;
$shop-the-look-li-font-weight: null !default;
$shop-the-look-li-letter-spacing: null !default;
$shop-the-look-nav-li-active-font-size: null !default;
$shop-the-look-nav-li-active-height: null !default;
$shop-the-look-nav-li-active-letter-spacing: null !default;
$shop-the-look-nav-li-active-text-align: null !default;
$shop-the-look-nav-li-button-border: null !default;
$shop-the-look-nav-li-button-color:  null !default;
$shop-the-look-nav-li-font-size: null !default;
$shop-the-look-nav-li-height: null !default;
$shop-the-look-nav-ul-display-medium: null !default;
$shop-the-look-subheader-font-size-medium: null !default;
$shop-the-look-subheader-font-weight-medium: null !default;
$shop-the-look-subheader-height-medium: null !default;
$shop-the-look-subheader-height: null !default;
$shop-the-look-subheader-letter-spacing-medium: null !default;
$shop-the-look-subheader-letter-spacing: null !default;
$shop-the-look-subheader-line-height-medium: null !default;
$shop-the-look-subheader-line-height: null !default;
$shop-the-look-subheader-text-align-medium: null !default;
$shop-the-look-subheader-width-medium: null !default;
$shop-the-look-subheader-width: null !default;
$shop-the-look-width-medium: null !default;
$shop-the-look-header-mobile-fixed-position: null !default;
$shop-the-look-header-mobile-fixed-top: null !default;
$shop-the-look-header-mobile-fixed-left: null !default;
$shop-the-look-header-mobile-fixed-width: null !default;
$shop-the-look-header-mobile-fixed-z-index: null !default;
$shop-the-look-header-mobile-fixed-background-color: null !default;
$shop-the-look-heaer-mobile-fixed-border-bottom: null !default;
$shop-the-look-overlay-button-text-decoration: null !default;

@mixin cms-shop-the-look {
  .shop-the-look-landing-page {
    font-family: $shop-the-look-font-face;

    .shop-the-look {
      background-color: $shop-the-look-background-color;

      &:not(.grid-layout) {
        display: flex;
        flex-direction: column;
      }

      &.grid-layout {

        .shopthelook_bundle {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .shopthelook_bundle .columns {
          padding: $shop-the-look-grid-padding;

          @include breakpoint(medium) {
            width: $shop-the-look-bundle-grid-width-medium;
          }
        }

        .content-wrapper {
          background: $shop-the-look-grid-content-wrapper-color;
        }

        .grid-cell {
          position: relative;
          overflow: hidden;

          .grid-cell-title {
            font-size: $shop-the-look-grid-cell-title-font-size;
            height: $shop-the-look-grid-cell-height;
            line-height: $shop-the-look-grid-cell-line-height;
            display: $shop-the-look-grid-cell-display;

          }
        }

        .mobile-shop-button {
          .button {
            background: $shop-the-look-grid-mobile-button-background;
            border: $shop-the-look-grid-mobile-button-border;
            color: $shop-the-look-grid-mobile-button-color;
            display: $shop-the-look-grid-mobile-button-text-display;
            font-size: $shop-the-look-grid-mobile-button-font-size;
            font-weight: $shop-the-look-grid-mobile-button-font-weight;
            letter-spacing: $shop-the-look-grid-mobile-button-letter-spacing;
            line-height: $shop-the-look-grid-mobile-button-line-height;
            text-decoration: $shop-the-look-grid-mobile-button-text-decoration;

          }
        }

        .overlay {
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          pointer-events: auto;
          display: none;

          .wrapper {
            display: none;
            height: 100%;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;

            .inner {
              display: table-cell;
              text-align: center;
              vertical-align: middle;

              .button {
                background: $shop-the-look-nav-li-button-background;
                border: $shop-the-look-nav-li-button-border;
                color: $shop-the-look-nav-li-button-color;
                font-size: $shop-the-look-overlay-button-font-size;
                font-weight: $shop-the-look-overlay-button-font-weight;
                text-decoration: $shop-the-look-overlay-button-text-decoration;

                &:hover {
                  background: $shop-the-look-nav-li-button-hover-background;
                  border: $shop-the-look-button-hover-border;
                  color: $shop-the-look-nav-li-button-hover-color;
                }
              }
            }
          }

          @include breakpoint(medium) {
            &:hover {
              background: $shop-the-look-grid-hover-color;

              .wrapper {
                display: table;
              }
            }
          }
        }

        @include breakpoint(medium) {
          .overlay {
            display: block;
          }
        }
      }

      padding: $shop-the-look-padding;

      &-header {
        letter-spacing: $shop-the-look-header-letter-spacing;
        margin: $shop-the-look-header-margin;
        text-align: $shop-the-look-header-text-align;
        text-transform: $shop-the-look-header-text-transform;

        @include breakpoint(medium) {
          font-size: $shop-the-look-header-font-size-medium;
          height: $shop-the-look-header-height-medium;
          letter-spacing: $shop-the-look-header-letter-spacing-medium;
          line-height: $shop-the-look-header-line-height-medium;
          margin: $shop-the-look-header-margin-medium;
          text-align: $shop-the-look-header-text-align-medium;
          width: $shop-the-look-header-width-medium;
        }

        h1 {
          color: $shop-the-look-header-color;
          font-size: $shop-the-look-header-font-size;
          letter-spacing: $shop-the-look-header-font-kerning;
          text-transform: $shop-the-look-header-text-transform;
          margin: $shop-thelook-header-h1-margin;

          @include breakpoint(large) {
            font-size: $shop-the-look-header-font-size-large;
          }
        }

        .icon-eye {
          color: $shop-the-look-eye-icon-color;
          font-size: $shop-the-look-eye-icon-size;
          margin: $shop-the-look-eye-icon-margin;

          @include breakpoint(large) {
            font-size: $shop-the-look-eye-icon-size-large;
          }
        }
      }

      &-subheader {
        font-size: $shop-the-look-subheader-font-size;
        font-weight: $shop-the-look-subheader-font-weight;
        letter-spacing: $shop-the-look-subheader-letter-spacing;
        line-height: $shop-the-look-subheader-line-height;
        padding-top: $shop-the-look-subheader-padding-top;
        text-transform: $shop-the-look-subheader-text-transform;
        width:  $shop-the-look-subheader-width;

        @include breakpoint(medium) {
          font-size: $shop-the-look-subheader-font-size-medium;
          font-weight: $shop-the-look-subheader-font-weight-medium;
          letter-spacing: $shop-the-look-subheader-letter-spacing-medium;
          line-height: $shop-the-look-subheader-line-height-medium;
          text-align: $shop-the-look-subheader-text-align-medium;
          width: $shop-the-look-subheader-width-medium;
        }
      }

      &-kit {
        float: none !important; // prevent floating when ".right" and ".left" classes are applied
        height: $shop-the-look-kit-image-height;
        margin: $shop-the-look-kit-margin;
        position: relative;

        @include breakpoint(medium) {
          height: $shop-the-look-kit-image-height-medium;
          margin: $shop-the-look-kit-margin-medium;
        }

        @include breakpoint(large) {
          height: $shop-the-look-kit-image-height-large;
          margin: $shop-the-look-kit-margin-large;
        }

        figure {
          height: $shop-the-look-kit-image-height;
          overflow: hidden;
          position: relative;
          width: $shop-the-look-kit-image-width;

          @include breakpoint(medium) {
            height: $shop-the-look-kit-image-height-medium;
            position: absolute;
            width: $shop-the-look-kit-image-width-medium;
          }

          @include breakpoint(large) {
            height: $shop-the-look-kit-image-height-large;
            width: $shop-the-look-kit-image-width-large;
          }
        }

        &.right {
          div {
            right: 0;
          }
        }

        &.left {
          figure {
            right: 0;
          }

          div {
            left: 0;
          }
        }
      }

      &-card {
        background-color: $shop-the-look-kit-card-background-color;
        box-shadow: $shop-the-look-kit-card-shadow;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: $shop-the-look-kit-card-height;
        justify-content: center;
        margin: $shop-the-look-kit-card-margin;
        padding: $shop-the-look-kit-card-padding;
        position: relative;
        top: $shop-the-look-kit-card-top;
        width: $shop-the-look-kit-card-width;

        @include breakpoint(medium) {
          position: absolute;
          margin: $shop-the-look-kit-card-margin-medium;
          top: $shop-the-look-kit-card-top-medium;
          width: $shop-the-look-kit-card-width-medium;
        }

        h1 {
          font-size: $shop-the-look-kit-card-header-font-size;
          margin: $shop-the-look-kit-card-heading-margin;
        }

        p {
          color: $shop-the-look-kit-card-paragraph-color;
          font-size: $shop-the-look-kit-card-paragraph-font-size;
          line-height: $shop-the-look-kit-card-paragraph-line-height;
          padding: $shop-the-look-kit-card-paragraph-padding;
        }

        summary {
          color: $shop-the-look-kit-card-category-color;
          font-size: $shop-the-look-kit-card-category-size;
          font-weight: $shop-the-look-kit-card-category-font-weight;
          line-height: $shop-the-look-kit-card-category-line-height;
          text-transform: $shop-the-look-kit-card-category-text-transform;
        }

        div {
          align-items: center;
          display: flex;
          flex-direction: row;
        }

        .icon-arrow-right-indicator-open {
          color: $shop-the-look-kit-card-icon-color;
          font-size: $shop-the-look-kit-card-icon-arrow-size;
          margin: $shop-the-look-kit-card-icon-margin;
        }
      }
    }

    &.shop-the-look-landing-page-grid {
      .shop-the-look-header {
        margin: $shop-the-look-grid-header-margin;
        text-align: center;
      }

      nav {
        margin: $shop-the-look-grid-nav-header-margin;
        @include breakpoint(medium) {
          margin: $shop-the-look-grid-nav-header-margin-medium;
        }
      }

      @include breakpoint(large) {
        margin: $shop-the-look-grid-layout-margin-large;
      }
    }

    nav {
      color: $shop-the-look-body-color;
      font-size: $shop-the-look-nav-font-size;
      margin: $shop-the-look-nav-margin;
      text-align: $shop-the-look-nav-text-align;

      @include breakpoint(medium) {
        margin: $shop-the-look-nav-margin-medium;
        text-align: $shop-the-look-nav-text-align-medium;
      }

      @include breakpoint(large) {
        margin: $shop-the-look-nav-margin-large;
      }

      ul {
        display: block;
        list-style: none;
        margin: 0;

        @include breakpoint(medium) {
          display: $shop-the-look-nav-ul-display-medium;
        }
      }

      li {
        color: $shop-the-look-nav-li-color;
        cursor: pointer;
        display: inline-block;
        font-size: $shop-the-look-nav-li-font-size;
        font-weight: $shop-the-look-li-font-weight;
        height: $shop-the-look-nav-li-height;
        letter-spacing: $shop-the-look-li-letter-spacing;
        margin: $shop-the-look-nav-li-margin;
        text-align: $shop-the-look-nav-li-text-align;
        text-transform: $shop-the-look-nav-li-text-transform;
        width: $shop-the-look-nav-width;

        @include breakpoint(medium) {
          width: $shop-the-look-nav-width-medium;
        }

        @include breakpoint(large) {
          margin: $shop-the-look-nav-li-margin-large;
          width: $shop-the-look-nav-width-large;
        }


        &:hover {
          border-bottom: $shop-the-look-nav-li-border-hover;
        }

        &.active {
          font-size: $shop-the-look-nav-li-active-font-size;
          font-weight: $shop-the-look-nav-li-active-font-weight;
          height: $shop-the-look-nav-li-active-height;
          letter-spacing: $shop-the-look-nav-li-active-letter-spacing;
          text-align: $shop-the-look-nav-li-active-text-align;

          @include breakpoint(medium) {
            color: $shop-the-look-nav-li-active-color;
            border-bottom: $shop-the-look-nav-li-border-active;
            text-decoration: $shop-the-look-nav-li-active-text-decoration;
          }
        }
      }

      .button {
          font-size: $shop-the-look-nav-li-button-font-size;
          font-weight: $shop-the-look-nav-li-button-font-weight;
          text-decoration: $shop-the-look-nav-li-button-text-decoration;
          border-radius: $shop-the-look-nav-li-button-border-radius;
          border: $shop-the-look-nav-li-button-border;
          padding: $shop-the-look-nav-li-button-padding;
          margin: $shop-the-look-nav-li-button-margin;
          height: $shop-the-look-nav-li-button-height;

        &.active {
          background: $shop-the-look-nav-li-button-active-background;
          color: $shop-the-look-nav-li-button-active-color;
        }
      }

      .button-row {
        margin: $shop-the-look-nav-button-row-margin;
      }

      .button-column {
        padding: $shop-the-look-nav-button-column-padding;
      }

      .fixed-mobile {
        background-color: $shop-the-look-header-mobile-fixed-background-color;
        border-bottom: $shop-the-look-heaer-mobile-fixed-border-bottom;
        left: $shop-the-look-header-mobile-fixed-left;
        position: $shop-the-look-header-mobile-fixed-position;
        top: $shop-the-look-header-mobile-fixed-top;
        width: $shop-the-look-header-mobile-fixed-width;
        z-index: $shop-the-look-header-mobile-fixed-z-index;
      }
    }
  }
}
