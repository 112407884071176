// Standard styling for the <h3> tag on most components
@mixin component-headline() {
  .component-title {
    margin-bottom: em-calc(25);

    .header {
      margin-bottom: 0;
      font-size: em-calc(38);
    }

    .subheader {
      font-size: em-calc(17);
      margin-top: 0;
      color: $black;
    }

    .accent {
      font-size: em-calc(17);
    }
  }
}


// Standard styling for read more button rows on grid layouts
@mixin component-grid() {
  .grid.row,
  .cat-dynamic.row {
    @include breakpoint(large) {
      margin-bottom: 30px;
    }
  }

  .cat-see-more,
  .cta {
    @include breakpoint(medium) {
      // No bottom margin on desktop buttons so it doesn't conflict with component-garnish()
      .button {
        margin-bottom: 0;
      }
    }
  }
}
