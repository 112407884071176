//
// Image Grid Styling
//

@mixin napa-image-grid {
  .imageGrid {
    .group {
      ul {
        list-style: none;
        margin: 15px auto;
      }
    }

    h5 {
      font-size: em-calc(22);
      padding: 14px 0;
    }

    &.layout-small {
      &.row {
        border-bottom: 1px solid $medium-gray;
        border-top: 1px solid $medium-gray;
      }

      h5 {
        text-align: center;
      }
    }

    &.layout-large {
      h5 {
        padding-left: 17px;
      }
    }
  }
}
