//
//  Article Previous & Next Bar Styles
//

$prev_next_border_top: null !default;
$prev_next_border_bottom: null !default;

$prev_next_row_background: null !default;
$prev_next_row_padding: null !default;

$prev_next_link_height_desktop: 50px !default;
$prev_next_link_height_mobile: 100px !default;

$prev_next_arrow_color: null !default;
$prev_next_arrow_color_hover: null !default;
$prev_next_arrow_font: 'icomoon' !default;
$prev_next_arrow_font_size: 1em !default;
$prev_next_arrow_width: 1em !default;

$prev_next_link_previous_arrow_code: $icon-arrow-left-indicator-open !default;
$prev_next_link_previous_mobile_border: null !default; // TNF
$prev_next_link_next_arrow_code: $icon-arrow-right-indicator-open !default;

$prev_next_link_use_arrow_borders: false !default; // Vans
$prev_next_link_previous_arrow_border_padding: null !default; // Vans
$prev_next_link_next_arrow_border_padding: null !default; // Vans
$prev_next_link_arrow_border: null !default; // Vans

$prev_next_link_image_margin_inside: 1em !default;
$prev_next_link_image_margin_outside: 1em !default;

$prev_next_middle_border_left: null !default;
$prev_next_middle_border_right: null !default;
$prev_next_middle_border_top_on_mobile: null !default;

// Prev/Next Bar styles applied to all nav.prevnextbar elements
@mixin cms-prev-next-bar() {

    nav.prevnextbar {

        border-top: $prev_next_border_top;
        border-bottom: $prev_next_border_bottom;

        %prev-next-link-before-after {
            color: $prev_next_arrow_color;
            display: table-cell;
            font: {
                family: $prev_next_arrow_font;
                size: $prev_next_arrow_font_size;
            }
            vertical-align: middle;
            width: $prev_next_arrow_width;

            // VFDP-3867 IE11 - Prev Next arrows hidden
            // https://connect.microsoft.com/IE/feedback/details/796188/pseudo-element-display-table-cell-font-ignored
            // https://philipnewcomer.net/2014/04/target-internet-explorer-10-11-css/
            // sass-lint:disable-block no-vendor-prefixes
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: inline-block;
            }
        }

        .mobile-middle {
            text-align: center;
            display: none !important;
            border-top: $prev_next_middle_border_top_on_mobile;
        }

        .row {
            background: $prev_next_row_background;
            padding: $prev_next_row_padding;

            div:first-child {
                border-right: $prev_next_link_previous_mobile_border;
                @include breakpoint(medium) {
                    border-right: 0;
                }
            }
        }

        a {
            display: table;
            width: 100%;
            line-height: $prev_next_link_height_mobile;
            text-decoration: none;

            @include breakpoint(medium) {
                line-height: $prev_next_link_height_desktop;
            }

            img {
                max-height: $prev_next_link_height_desktop;
            }

            &.previous {
                text-align: left;

                &:before {
                    @extend %prev-next-link-before-after;
                    @include icomoonFont($prev_next_link_previous_arrow_code, false);
                    @if $prev_next_link_use_arrow_borders != false {
                        @include breakpoint(medium) {
                            padding: $prev_next_link_previous_arrow_border_padding;
                            border-right: $prev_next_link_arrow_border;
                        }
                    }
                }

                img {
                    margin-right: $prev_next_link_image_margin_inside;
                    margin-left: $prev_next_link_image_margin_outside;
                }
            }

            &.middle {
                border-left: $prev_next_middle_border_left;
                border-right: $prev_next_middle_border_right;
                text-align: center;
                line-height: $prev_next_link_height_desktop;
                vertical-align: middle;

                &:before,
                &:after {
                    display: table-cell;
                    content: ' ';
                    width: 1px;
                }

                span {
                    line-height: 1em;
                    display: table-cell;
                    height: $prev_next_link_height_desktop;
                    vertical-align: middle;
                    width: 100%;
                }
            }

            &.next {
                text-align: right;

                &:after {
                    @extend %prev-next-link-before-after;
                    @include icomoonFont($prev_next_link_next_arrow_code, false);
                    @if $prev_next_link_use_arrow_borders != false {
                        @include breakpoint(medium) {
                            padding: $prev_next_link_next_arrow_border_padding;
                            border-left: $prev_next_link_arrow_border;
                        }
                    }
                }

                img {
                    margin-left: $prev_next_link_image_margin_inside;
                    margin-right: $prev_next_link_image_margin_outside;
                }
            }

            .pagination-link-wrap {
                line-height: 1em;
                display: table-cell;
                margin: 0;
                padding: 0 10px;
                vertical-align: middle;
            }
        }
    }
}
