@import 'cms/components/single-category-tile';

@mixin napa-single-category-tile {
	.single-category-tile {
		.info {
			line-height: 2;
			margin: {
				top: 15px;
				bottom: 15px;
			}
		}
	}
}
