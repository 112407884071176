// *******************************************************************
// * Unbranded layout object for facilitating video JS players
// *
// * Brand-specific and component-specific styling, hover states, etc should be handled elsewhere
// *
// * Usage:
//
// <div class="videojsplayer youtube ratio-16-9"
//     itemprop="video"
//     itemscope itemtype="http://schema.org/VideoObject">
//     <h4 itemprop="name" class="name">Video Name</h4>
//     <meta itemprop="url" content="https://www.youtube.com/watch?v=XXXXXX}" />
//     <meta itemprop="embedURL" content="https://www.youtube.com/embed/XXXXXX" />
//     <meta itemprop="thumbnailURL" content="http://example.com/example.jpg" />
//	   <meta itemprop="uploadDate" content="2015-12-25" />
// 	<div class="video">
// 		<video
//             id="YYYYYY"
//             class="video-js vjs-default-skin"
//             poster="http://example.com/example.jpg"
//             controls autoplay
//             data-player-type="youtube"
//             ytControls=2
//             data-options='{
//                 "techOrder": ["youtube"],
//                 "sources": [{
//                     "type": "video/youtube",
//                     "src": "https://www.youtube.com/watch?v=XXXXXX"
//                 }]
//             }'
//         >
//      </video>
// 	</div>
//  <span itemprop="description" class="description">${description}</span>
// </div>
// *
// *
// *******************************************************************

// Mixin so that this can easily be reused and extended elsewhere
@mixin cms-video-player() {
    .videojsplayer {
        .video-js {
            height: 100%;
            width: 100%;
        }

        // Hide microformat data
        meta,
        .name,
        .description {
            display: none;
        }

        // Enable full screen mode on devices.
        .video-js > div:first-child {
            height: 100% !important;
        }

        .media {
            position: absolute;
        }

        @include breakpoint(medium) {
            .s7container[mode='fullscreen'],
            .s7videoplayer,
            .s7videoplayer video,
            .video-js .s7container,
            .vjs-fullscreen .s7container,
            [class$="-dimensions"] { // In Edge v16/17, IE11 restrict the video within the page container
                height: 100% !important;
                width: 100% !important;
            }
        }
    }

    .native-video {
        width: 100%;
    }
}
