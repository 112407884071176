//
// Search Results Tabs
// Tabs for the search results content choices
//
// Configurable options for colors, fonts, and borders
//

$include-html-search-results-tabs-classes: $include-html-classes !default;

$searchTabContainerBorder: null !default;
$searchTabContainerAlign: null !default;

$searchTabBackground: null !default;
$searchTabBorderActive: null !default;
$searchTabBorderDefault: null !default;
$searchTabFloat: left !default;
$searchTabFont: null !default;
$searchTabFontColor: null !default;
$searchTabFontSize: null !default;
$searchTabFontSizeMedium: null !default;
$searchTabFontWeight: null !default;
$searchTabHeight: 60px !default;
$searchTabLineHeight: 1.25 !default;
$searchTabLineHeightMedium: 2.2 !default;
$searchTabMargin: 0 0.5% 15px 0 !default;
$searchTabMarginMedium: 10px 0 30px !default;
$searchTabPadding: 16px 25px 10px !default;
$searchTabPaddingMedium: null !default;
$searchTabTextAlign: center !default;
$searchTabTextDecoration: none !default;
$searchTabTextTransform: uppercase !default;
$searchTabWidth: 49% !default;
$searchTabWidthMedium: 50% !default;

@mixin search_results_tab() {
	background: $searchTabBackground;
	border-bottom: $searchTabBorderActive;
	color: $searchTabFontColor;
	display: inline-block;
	float: $searchTabFloat;
	font-family: $searchTabFont;
	font-size: $searchTabFontSize;
	font-weight: $searchTabFontWeight;
	height: $searchTabHeight;
	line-height: $searchTabLineHeight;
	margin: $searchTabMargin;
	padding: $searchTabPadding;
	text-align: $searchTabTextAlign;
	text-decoration: $searchTabTextDecoration;
	text-transform: $searchTabTextTransform;
	width: $searchTabWidth;

	&-container {
		border-bottom: $searchTabContainerBorder;
		text-align: $searchTabContainerAlign;
	}

	&.off {
		border-bottom: $searchTabBorderDefault;
	}

	&:last-child {
		margin-left: 0.5%;
		margin-right: 0;
	}

	.product-list-product-count {
		display: block;
	}

	@include breakpoint(medium) {
		font-size: $searchTabFontSizeMedium;
		line-height: $searchTabLineHeightMedium;
		margin: $searchTabMarginMedium;
		padding: $searchTabPaddingMedium;
		width: $searchTabWidthMedium;

		&:last-child {
			margin-left: 0;
			margin-right: 0;
		}

		.product-list-product-count {
			display: inline;
		}
	}
}

@if $include-html-search-results-tabs-classes != false {
    // Styles applied to all .search-result-tab elements

    .search-result-tab {
        @include search_results_tab;
    }
}
