@import 'recursive-map-merge';

// =================
// Default Variables
// THIS FILE IS IN PROCESS OF BEING DEPRECATED! , DO NOT ADD ANYTHING NEW
// -----------------


$include-html-classes: true !default;

$vwunit-base: 480px; // VW Unit reference point for convertPxToVw()

//Calculate tracking on ems
@function trackingToEm($tracking) {
	@return ($tracking / 1000) * 1em;
}

//$value in px to convert, desired context $viewportWidth in px.
// Note: Safari & iOS Safari (both 6 and 7) does not support viewport units for border widths, column gaps, transform values, box shadows or in calc().
@function convertPxToVw($value, $viewportWidth) {
	@return (strip-unit($value) / strip-unit($viewportWidth)) * 100vw;
}

//////////////////////////////////////////////////////////
//
// --------------------- CAUTION -------------------------
//
// This is a hack to account for the default variables
// not taking into account the base font size for
// each brand when using the deprecated convert-to-em
// function. Please remove this and this file ASAP
//
// Do NOT use this function anywhere else
//////////////////////////////////////////////////////////
@function base16-convert-to-em($size) {
  @return em-calc($size, 16);
}


//
// Button Variables
//

// Colors for buttons
$button-cta: #C9192E;
$button-primary: #C9192E;
$button-hover: #000;

////////////Default Variables and Style////////////

// Mobile Form
$formElementFontSizeMobileNoZoom: 16px !default; // 16px prevents zooming effect on iphones.

// form validation
$errorWidth: 32% !default;

// topbar navigtion.
$topbar-bg-color: #fff !default;
$topbar-bg-shaded: darken($topbar-bg-color, 50%) !default;
$topbar-height: 95px !default;
$tablet-topbar-height: 95px !default;
$mobile-topbar-height: 61px !default;
$mobile-topbar-panel-offset: $mobile-topbar-height !default;

// styles for top-level nav

//this is mobile topbar button width.
$mobile-topbar-button-width: 61px !default;
$mobile-topbar-button-color: #000 !default;
$mobile-topbar-button-hover-color: #000 !default;
$mobile-cart-items-text-position: 7px !default;
$topbar-link-color-hover: #CB1B2C !default;

$topbar-link-bg-hover: transparent !default;
$topbar-menu-icon-color: #333333 !default;
//making foundation dropdown bg to transparant.
$topbar-dropdown-bg: #171717 !default;

$topbar-dropdown-arrow-bg: $topbar-dropdown-bg !default;
$topbar-dropdown-bg-custom: #171717 !default;
$topbar-menu-width: 60px !default;
$topbar-menu-bg-color-hover: #F0F0F0 !default;
$topbar-menu-border: 1px solid #CCCCCC !default;
$topbar-font-color: #333333 !default;
$topbar-font-color-hover: #CB1B2C !default;
$topbar-font-weight: bold !default;

$topbar-upper-bg-color: transparent !default;
$topbar-upper-height: 25px !default;
$topbar-upper-font-size: 11px !default;
$topbar-upper-font-color: #CCCCCC !default;
$topbar-upper-padding: 0px 10px 0px 10px !default;
$topbar-upper-tablet-padding: $topbar-upper-padding !default;
$topbar-upper-mobile-utility-link-size: 16px !default;
$topbar-upper-mobile-link-size: 16px !default;
$topbar-upper-mobile-title-size: 21px !default;

$topbar-lower-bg-color: transparent !default;
$topbar-lower-height: 64px !default;
$topbar-lower-top-position: 31px !default;
$topbar-lower-slash-width: 20px !default;
$topbar-lower-break-point: 1023px !default;
$topbar-lower-search-box-height: 36px !default;
$topbar-lower-search-box-padding: 10px 0 10px 10px !default;
$topbar-lower-search-box-font-size: 13 !default;
$topbar-lower-margin: 0px !default;
$topbar-lower-menu-font-size: 16px !default;
$topbar-border-bottom: 1px dotted #3d3d3d !default;


$topbar-nav-extension-top: $topbar-lower-top-position !default;
$topbar-nav-extension-height: 40px !default;
$topbar-dropdown-arrow-width: 16px !default;
$topbar-dropdown-padding-top: 40px !default;
$topbar-dropdown-padding-bottom: 30px !default;
$topbar-dropdown-padding: $topbar-dropdown-padding-top 0px $topbar-dropdown-padding-bottom 0px !default;
$topbar-dropdown-bg-transparent: transparent !default;
$topbar-dropdown-toggle-width: 20px !default;
$topbar-dropdown-mega-column-width: 140px !default;
$topbar-dropdown-sub-header-font-weight: bold !default;
$topbar-dropdown-font-size: 14px !default;
$topbar-dropdown-font-letter-spacing: 0.07em !default;
$topbar-dropdown-font-color: #85BEE3 !default;
$topbar-dropdown-button-link-color: #fff !default;
$topbar-dropdown-link-height: 22px !default;
$topbar-dropdown-title-text-alignment: left !default;
$topbar-dropdown-title-height: inherit !default;
$topbar-dropdown-title-bkd-color: inherit !default;
$topbar-dropdown-title-letter-spacing: inherit !default;
$topbar-dropdown-parent-link-line-height: ($topbar-height / 2) !default;
$topbar-dropdown-parent-link-bkd-color:  inherit !default;

$topbar-kids-item-width: 120px !default;

$topbar-search-form-width: 178px  !default;
$topbar-search-form-width-popup: 350px !default;
$topbar-search-form-margin: 10px !default;
$topbar-search-button-size: 40px !default;
$topbar-search-container-background: #fff !default;

$topbar-search-container-icon-color: get-border-value($input-border, 'color') !default;
$topbar-search-tablet-top-position: 40px !default;
$topbar-search-tablet-bottom-position: "" !default;

//auto search
$auto-search-suggestion-background: #262626 !default;

//brand colors
$brandColorPalette1: #C9192E !default;
$brandColorPalette2: #332B28 !default;
$brandColorPalette3: #000 !default;
$brandColorPalette7: #CCC !default;


//TODO: These variables need to be cleaned up
$secondaryFont: 'Helvetica Neue', 'Arial Narrow', Arial, sans-serif !default;
$OTWHeaderTitleFontFamily: Arial, sans-serif !default;
$mediumFontFamily: $secondaryFont !default;
$medium-headline: (
  'font': (
    'font-family': $mediumFontFamily,
    'font-size': 1.5em,
    'color': $header-color,
    'letter-spacing': 0.05em,
    'line-height': 24px,
    'text-transform': uppercase
  )
) !default;

$fontError: #ED1B2A !default;
$fontInfo: #dfede5 !default;
$fontGeneric: #2b2b2b !default;
$fontSuccess: #10b782 !default;
$fontWarning: #dfede5 !default;

$fontInvert: #fff !default;
$fontInvertLight: #929292 !default;
$fontGrey: #bebebe !default;
$fontGreyDark: #64686b !default;
$fontGreyLight: #8d9293 !default;
$fontDark: #3d3d3d !default;
$fontLink: #589BC6 !default;
$fontLinkHover: #C9192E !default;


//productLaneSubTitleFontSize
$productLaneFontStyle: (
  'font': (
    'color': #2A2A2A,
    'font-style': italic,
    'font-weight': bold,
    'letter-spacing': trackingToEm(160),
    'font-size': 1em
  ),
  'medium': (
    'font-size': em-calc(15px, 13px)
  )
) !default;

$productLaneViewAllFontStyle: (
  'font': (
    'font-size': 0.75em,
    'letter-spacing': trackingToEm(150)
  )
) !default;

$productInfoPromoEyebrowFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-size': base16-convert-to-em(13px),
    'font-weight': bold,
    'color': $brandColorPalette1,
    'text-transform': uppercase
  )
) !default;

$productInfoPriceFontStyle: (
  'font': (
    'font-size': base16-convert-to-em(13px)
  )
) !default;

$productDescriptionFontStyle: (
  'font': (
    'font-size': base16-convert-to-em(17px)
  )
) !default;

$productDetailsHeaderStyle: (
  'font': (
    'font-size': base16-convert-to-em(20px)
  ),
  'large': (
    'font-size': base16-convert-to-em(28px)
  ),
  'medium': (
    'font-size': 1.5em
  )
) !default;

$breadcrumbFontStyle: (
  'font': (
    'font-size': base16-convert-to-em(21px)
  ),
  'medium': (
    'font-size': base16-convert-to-em(15px)
  )
) !default;

$productInfoColorSwatchFontStyle: (
  'font': (
    'font-weight': bold,
    'font-size': base16-convert-to-em(10px)
  )
) !default;

$subCatNavFontStyle: (
  'font': (
    'font-size': base16-convert-to-em(15px),
    'text-transform': uppercase
  )
) !default;

$facetOptionFontStyle: (
  'font': (
    'color': #2A2A2A
  )
) !default;

$facetTitleFontStyle: (
  'font': (
    'color': #FFF,
    'font-size': base16-convert-to-em(15px),
    'text-transform': uppercase,
    'letter-spacing': trackingToEm(150),
    'text-align': left
  ),
  'medium': (
    'font-size': base16-convert-to-em(13px),
  )
) !default;

$facetClearAllFontStyle: (
  'font': (
    'color': #2A2A2A,
    'text-decoration': underline,
    'letter-spacing': 0.1em
  )
) !default;

$compareBtnFontStyle: (
  'font': (
    'font-family': $header-font-family
  )
) !default;

$compareBtnFontStyle: recursive-map-merge($facetTitleFontStyle, $compareBtnFontStyle);

$sortByLabelFontStyle: (
  'font': (
    'text-decoration': underline,
  ),
  'medium': (
    'font-size': base16-convert-to-em(13px)
  )
) !default;

$sortByLabelFontStyle: recursive-map-merge($compareBtnFontStyle, $sortByLabelFontStyle);

$compareEmptySlotTitleFontStyle: (
  'font': (
    font-family: $header-font-family,
    font-size: base16-convert-to-em(100px),
    color: #e9e7dd
  )
) !default;

$compareProductDetailInfoFontStyle: (
  'font': (
    font-family: $header-font-family,
    font-size: base16-convert-to-em(17px),
    color:  $brandColorPalette3
  )
) !default;

$compareBtnCounterFontStyle: (
  'font': (
    font-family: $header-font-family,
    font-size: base16-convert-to-em(15px)
  )
) !default;

$checkoutStepsMenuFontStyle: (
  'font': (
    'font-family': $secondaryFont,
    'font-weight': normal,
    'text-transform': none,
    'font-size': base16-convert-to-em($topbar-upper-font-size),
    'color': $topbar-upper-font-color,
    'letter-spacing': 0em
  )
) !default;

$checkoutStepsFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-size': 1em,
    'font-weight': bold,
    'text-transform': uppercase,
    'color': #64686b,
    'letter-spacing': 0em
  )
) !default;

$checkoutItemsListEditLink: (
  'font': (
    'font-family': $secondaryFont,
    'font-weight': normal,
    'text-transform': none,
    'font-size': base16-convert-to-em(9),
    'letter-spacing': 0em
  )
) !default;

$checkoutSmArcFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': bold,
    'text-transform': uppercase,
    'font-size': 1em,
    'letter-spacing': 0.1em,
    'color': $brandColorPalette1
  )
) !default;

$infoDetailFontStyle: (
  'font': (
    'font-style': none,
    'font-size': base16-convert-to-em(14),
    'font-weight': normal,
    'color': #64686b,
    'text-transform': uppercase
  )
) !default;

$myaccountVIPRewardTitleFontStyle: (
  'font': (
    'font-size': convertPxToVw(28px, 640px)
  ),
  'medium': (
    'font-size': 1.5em
  )
) !default;
$myAccountVIPRewardsPointsFontStyle: (
  'font': (
    'font-weight': bold,
    'font-size': base16-convert-to-em(17px)
  )
) !default;
$myAccountWishlistProductSectionFontStyle: (
  'font': (
    'font-size': initial,
    'font-weight': initial,
    'line-height': initial
  )
) !default;
$myAccountWishlistProductDetailFontStyle: (
  'font': (
    'font-size': 0.75em
  )
) !default;
$orderReturnExchangeTitleFontStyle: (
  'font': (
    'font-size': 1.5em,
  )
) !default;
$orderIteminfoFontStyle: (
  'font': (
    'font-size': 0.85em,
    'font-weight': normal,
  )
) !default;
$orderItemTableItemTitleFontStyle: (
  'font': (
    'font-size': 0.85em,
  )
) !default;
$orderShippingBillingInfoFontStyle: (
  'font': (
    'font-size': base16-convert-to-em(15px)
  )
) !default;

$emailPrefTitleFontStyle: (
  'font': (
    'font-size': 1.5em
  )
) !default;

$filterGroupFontStyle: (
  'font': (
    'font-family': $header-font-family
  )
) !default;

$filterRefineSearchFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-size': $input-font-size,
    'color': #FFFFFF
  )
) !default;

$searchResultSummaryFontStyle: (
  'font': (
    'font-family': $header-font-family
  )
) !default;

$gridTitleHeroTextFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'color': #fff,
    'text-align': center,
    'text-transform': uppercase
  )
) !default;

$featureHeadingShopFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-size': 0.75em,
    'line-height': base16-convert-to-em(13),
    'color': #FFFFFF,
    'text-transform': uppercase
  )
) !default;

$quickViewUploaderFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-size': 1em,
    'letter-spacing': base16-convert-to-em(4)
  )
) !default;

$espotRecommendTitleFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'color': $header-color,
    'font-size': base16-convert-to-em(26),
    'text-transform': uppercase
  )
) !default;

$espotStoryTellingFontStyle: (
  'font': (
    'font-family': $header-font-family
  )
) !default;

$promoContentFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-style': italic,
    'letter-spacing': 0.08em
  )
) !default;

$categoryContentFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-size': base16-convert-to-em(14)
  )
) !default;

$subCategoryHeaderFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-size': base16-convert-to-em(14),
    'color': #fff,
    'text-transform': uppercase
  )
) !default;

$moreContentFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-size': base16-convert-to-em(14)
  )
) !default;

$navDelimitedFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-size': 1em
  )
) !default;

$globalMenuContentFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'letter-spacing': 0.07em
  )
) !default;

$searchTermBoxFontStyle: (
  'font': (
    'font-family': $header-font-family
  )
) !default;

$catNameFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-size': 1em
  )
) !default;

$miniCartPromoHeaderFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-size': 1.5em,
    'text-transform': uppercase,
    'text-align': center
  )
) !default;

$itemSavedToWishlistFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-size': 1em,
    'color': $fontSuccess,
    'text-transform': uppercase,
    'text-align': center
  ),
  'medium': (
    'font-size': 1.25em
  )
) !default;

$checkoutCustomFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'color': $fontDark,
    'text-transform': uppercase,
    'text-align': center
  )
) !default;

$checkoutUnavailableFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'color': $brandColorPalette1,
    'text-transform': uppercase,
    'text-align': center
  )
) !default;

$promoTextFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'color': $fontSuccess,
    'text-transform': uppercase,
    'text-align': center
  )
) !default;

$espotPromoTextFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'color': $fontDark,
    'text-transform': uppercase,
    'text-align': center
  )
) !default;

$shippingOptionsTitleFontStyle: (
  'font': (
    'text-transform': uppercase
  )
) !default;

$chooseShippingOptionsFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'color': $fontGreyDark,
    'text-transform': uppercase
  )
) !default;

$countdownContainerFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-size': 0.9em,
    'color': $fontError,
    'text-align': center
  )
) !default;

$featureCountdownFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'font-size': 1em,
    'color': $header-color,
    'text-transform': uppercase
  )
) !default;

$showAllFeaturesHopupFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'letter-spacing': 0.05em,
    'text-transform': uppercase,
    'text-align': center
  )
) !default;

$olapicViewerFontStyle: (
  'font': (
    'font-family': $header-font-family
  )
) !default;

$nearStoresHeaderFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'text-align': right,
    'text-transform': uppercase,
    'text-rendering': optimizeLegibility
  )
) !default;

$locationContainerFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700,
    'text-transform': uppercase
  )
) !default;

$checkoutFooterHelpFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-weight': 700
  )
) !default;

//colors

//background and accent colors
$pageBackground: #F1F2ED !default;
$moduleBackground: #FFF !default;
$hairlineRules: #D8D9D5 !default;
$dashedRules: #D8D9D5 !default;
$filterHairlineRules: #A4D0DA !default;
$blueHighlight: #EBEFF1 !default;
$errorHighlight: #EFE5E2 !default;
$successHighlight: #DAECE2 !default;
$genericHighlight: #E7E8E3 !default;
$womensPageBG: #F3EEEE !default;
$productReview: #1B897F !default;
$fontWeightBold: 700 !default;
$fontWeightSemiBold: 600 !default;
$fontWeightMedium: 500 !default;

//text color palette
$darkText: #000 !default;
$defaultText: #888 !default;
$negativeText: #fff !default;
$negativeLightText: #929292 !default;
$errorText: #ED1B2A !default;
$hyperlinkText: #589BC6 !default;

$lightHyperlinkText: #FFC05B !default;

$promoText: #F24609 !default;
$breadcrumbHyperLinkHover: #c9192e;

$fontFilterLinkHover: $brandColorPalette3;
$fontPdpDrawerProHeadline: #F5A019;
$fontPdpDrawerProBtnPrimary: #231f20;
$fontPdpPrice: $brandColorPalette1;
$currentPriceFontColor: $brandColorPalette1;

// Promo Banner
$promoBannerHeight: 40px !default;
$promoBannerLineHeight: $promoBannerHeight !default;
$promoBannerHeightMobile: 40px !default;
$promoBannerLineHeightMobile: $promoBannerHeightMobile !default;
$promoBannerBgColor: #1C1C1C !default;
$promoBannerFontColor: #FFFFFF !default;
$promoBannerBgColorHover: #1C1C1C !default;
$promoBannerFontColorHover: #FFFFFF !default;
$promoBannerControlBgHover: #242424 !default;
$promoBannerControlWidth: 40px !default;


// New Font Varibales Set For PDP Drawer
$primaryFontBold: 'Arial Narrow Bold' !default;
$secondaryFont: $secondaryFont !default;
$smallHeadlineFontSize: 14px !default;
$mediumHeadlineFontSize: 24px !default;
$largeHeadlineFontSize: 32px !default;
$smallParagraphFontSize: 12px !default;
$mediumParagraphFontSize: 24px !default;
$largeParagraphFontSize: 28px !default;
$primaryParagraphFontColor: #51514f !default;
$greenIndexFontColor: #595b45 !default;
$drawerContainerFeatureTitlesFont: (
  'font': (
    'font-size': 13px,
    'color': #51514f,
    'text-transform': uppercase
  )
) !default;

// Account pages utility Nav and legacy nav promobar
$pageUtilNavContent: (
  'font': (
    'font-family': $header-font-family,
    'font-size': base16-convert-to-em(14px),
    'font-weight': 400,
    'text-transform': uppercase
  )
) !default;
$pageUtilNavHeaderContent: (
  'font': (
    'font-weight': 700,
    'font-size': 1em
  )
) !default;

$pageUtilNavHeaderContent: recursive-map-merge($pageUtilNavContent, $pageUtilNavHeaderContent);

//background color palette
$backgroundColor: #f1f2ed !default;
$backgroundColorDark: #1c1c1c !default;
$backgroundColorLight: #fff !default;
$backgroundColorError: #EFE5E2 !default; //light red
$backgroundColorInfo: #dfede5 !default; //light green
$backgroundColorSuccess: #dfede5 !default; //light green
$backgroundColorUtility: #eae9e2 !default; //light gray
$backgroundColorHighlight: #fdffba !default; //light yellow
$backgroundColorGeneric: #e7e8e3 !default;
$backgroundColorTransparency: rgba(0, 0, 0, 0.9) !default;

//spacing
$vertSpaceSmall: 15px !default;
$vertSpace: 30px !default;
$vertSpaceMed: 35px !default;
$vertSpaceLarge: 40px !default;
$vertSpaceJumbo: 80px !default;

//border style
$borderDashedColor: #d3d3d3 !default;
$borderColor: #dcddda !default;
$borderFocusColor: $brandColorPalette1 !default;
$borderDotted: 1px dotted $borderColor !default;
$borderSolid: 1px solid $borderColor !default;
$borderDashed: 1px dashed $borderDashedColor;
$defaultFocusColor: rgb(0, 95, 204);

// Form Entry
$formLabelWidth: 22% !default;
$formEntryTitleFontSize: base16-convert-to-em(22) !default;
$checkboxOptionLabelFontSize: 0.9em !default;

$breadcrumbLastItemFontSize: 22px !default;
$breadcrumbLastFontStyle: (
  'font': (
    'font-size': $breadcrumbLastItemFontSize
  ),
  'medium': (
    'font-size': convertPxToVw($breadcrumbLastItemFontSize, $vwunit-base)
  )
) !default;

//My Account- Address Book Page
$addressEntryFontSize: 0.9em !default;
$addressEntrySubHeaderFontSize: 0.9em !default;
$addressEntryParagraphFontSize: 0.8em !default;

//PDP
$pdpSpacingLarge: 20px !default;
$pdpProductViewControlBottom: 20px !default;

//PDP Product Info
$productTitleH1Style: null !default;
$productTitleH1StyleMedium: $productTitleH1Style !default;
$productPriceFontStyle: (
  'font': (
    'font-size': 31px
  ),
  'medium': (
    'font-size': 24px
  )
) !default;

//PDP select a size error message
$pdpSelectSizeErrorBg: transparent !default;

//ORE Returns landing page
$sectionTitleBorderColor: $brandColorPalette3 !default;

//Tool Tip
$tooltipBackgroundColor: #fff !default;
$tooltipBoxShadowColor: $brandColorPalette7 !default;
$tooltipCommonCloseColor: #589bc6 !default;
$productFinderTooltipBoxShadowColor: $black !default;

// My Account- Return Order History

$returnOrderDarkBorder: 1px dotted #2c2c2c !default;
$returnHistoryFontSize: 16px !default;
$returnHistoryDefaultFontSize: 13px !default;

//Background images
$BGSectionSeperatorA: null !default;
$BGSectionSeperatorB:  $borderDotted !default;
$BGSectionSeperatorC: $borderSolid !default;

//Product Block
$badgeFontColor: $brandColorPalette1 !default;
$productNamePriceDividerFontColor: $negativeLightText !default;

$resultCounterInnerFontSize: 1em !default;

//Shopflow: Search Results
$searchRelatedProductsSectionBorderColor: #000 !default;

$headerHeroDescFontSize: 13px !default;

$quickshopBtnFontWeight: 300 !default;
$quickshopBtnFontSizeSmall: 0.8em !default;
$catologProductNameFontSize: 16px !default;
$heroProductNameFontSize: 16px !default;
$heroBadgeFontSize: 20px !default;

// //Facet Breadcrumbs
$facetCounterFontColor: $darkText !default;
$mobileSearchBtnColor: #000 !default;

//Filter Bar Facet
$facetBoxBorderThickness: 2px !default;
$facetBoxSelectedColor: rgb(231, 43, 30) !default;
$facetBoxSelectedBackroundColor: #FFF !default;
$facetBoxHoverColor: $borderFocusColor !default;


//Product Block Dismensions
$productBlockDesktopHeight: 360px !default;

//Product Block Styling
$productBlockMargin: 10px !default;
$productBlockBorder: 4px solid transparent !default;


//Product Block Info
$productBlockInfoMargin: 12px !default;

//Product Block Price
$productBlockPriceContent: '/' !default;
$priceFontColor: $brandColorPalette1 !default;
$offerPriceColor: $brandColorPalette1 !default;
$originalPriceColor: #B0B1AB !default;

//Product Block Compare
$productDetailsDescSize: 17px !default;


$checkoutTopBarBg: #1C1C1C !default;
$checkoutTopBarHeight: 95px !default;
$checkoutStepsBg: $backgroundColorGeneric !default;
$checkoutStepsBg: $body-background !default;
$checkoutSectionBorder: 1px solid $backgroundColorGeneric !default;
$checkoutSpacingLarge: 40px !default;
$checkoutSpacingMedium: 25px !default;
$checkoutSpacingSmall: 0px !default;
$checkoutSavedAddressTop: 0 !default;

// checkout content section title
$checkoutMultiOptionStatusLabelFontStyle: (
  'font': (
    'font-size': 15px
  )
) !default;
$shopcartDisabledCustomItemMessageFontStyle: (
  'font': (
    'letter-spacing': trackingToEm(140),
    'text-transform': uppercase,
    'color': #939393,
    'font-size': convertPxToVw(24, $vwunit-base)
  ),
  'medium': (
    'font-size': inherit
  )
) !default;
$shippingGiftOptionItemDetailLabelFontStyle: (
  'font': (
    'text-transform': uppercase
  )
) !default;
$shippingGiftOptionItemsControlFontStyle: (
  'font': (
    'font-size': convertPxToVw(28px, $vwunit-base)
  ),
  'medium': (
    'font-size': 15px
  )
) !default;
$LinkbtnFontStyle: (
  'font': (
	'font-size': 0.85em,
	'text-transform': none
  )
) !default;

//Grid and Search Result Counter
$miniCartSubtotalFontStyle: (
  'font': (
    'font-family': $secondaryFont,
    'color': #1c1c1c,
    'font-style': normal,
    'font-size': base16-convert-to-em(14),
    'text-transform': uppercase
  )
) !default;
$miniCartItemTitleFontStyle: (
  'font': (
    'color': $darkText,
    'font-weight': bold,
    'text-transform': uppercase
  )
) !default;
//Quick View
$quickViewBGColor: #F1F1EB !default;
$notificationBGColor: #E2EAED !default;
$notificationFontSize: 13px !default;

//Reveal Modal
$mediumHopupsize: 35% !default;
$largeHopupsize: 75% !default;
$tabletHopupsize: 80% !default;
$mobileHopupsize: 90% !default;
$fullHopupsize: 98% !default;


//Carousel Color Variables
$arrowBtnContainerBGColor: transparent !default;
$arrowBtnContainerHoverBGColor: #262626 !default;
$carouselBtnFontColor: $brandColorPalette3 !default;

//PDP image loader
$ajax-loader: url('../images/common/spinner.gif') !default;

// **
// * Animations
// **
$hoverDuration: 250ms !default; // for hover effects
$openDuration: 1000ms !default; // for opening larger layout elements

// ship to
$shippingLoginToAccountFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'text-align': center,
    'text-transform': uppercase
  )
) !default;
$shippingOptionsFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'text-transform': uppercase,
    'font-weight': 500,
    'letter-spacing': 1px
  )
) !default;

$productBenefitsHeaderFontStyle: (
  'font': (
	'letter-spacing': 0.125em
    )
  ) !default;
$productSpecificationTitleFontStyle: (
  'font': (
    'text-transform': uppercase,
    'font-size': 1em,
    'letter-spacing': 0.125em,
    'font-style': normal
  )
) !default;

//In-Store Availability font styles
$findStoreHeaderFontStyle: (
  'font': (
    'font-size': 1.5em
  )
)!default;

$foundStoreNameFontStyle: (
  'font': (
    'font-size': 1em
  )
)!default;
$foundStoreHeaders: (
  'font': (
    'font-size': em-calc(14px, 16)
  )
)!default;
$foundStoreDetailHeaders: (
  'font': (
    // 'font-family': $header-font-family,
  )
)!default;
$foundStoreStatusFontStyle: (
  'font': (
    'font-size': 1em
  )
)!default;
$additionalStoresButtonFontStyle: (
  'font': (
    'font-size': 1em
  )
)!default;

