//
// Email Signup form
//

$email_signup_form_email_input_text_align_mobile: center !default;
$email_signup_form_email_input_text_align_tablet_up: left !default;
$email_signup_form_background_color: null !default;
$email_signup_form_max_width: null !default;
$email_signup_form_padding: 1em !default;
$email_signup_form_submit_button_background_color: null !default;
$email_signup_form_submit_button_background_color_hover: null !default;
$email_signup_form_submit_button_border: null !default;
$email_signup_form_submit_button_border_hover: null !default;
$email_signup_form_submit_button_font_color: null !default;
$email_signup_form_submit_button_font_color_hover: null !default;
$email_signup_form_submit_button_before_content: null !default;
$email_signup_form_submit_button_before_font_family: null !default;
$email_signup_form_submit_button_after_content: null !default;
$email_signup_form_submit_button_after_font_family: null !default;
$email_signup_form_label_font_family: null !default;
$email_signup_form_label_font_color: null !default;
$email_signup_form_label_font_size: null !default;
$email_signup_form_label_font_weight: null !default;
$email_signup_form_label_text_transform: null !default;
$email_signup_form_label_text_align_mobile: center !default;
$email_signup_form_label_text_align_tablet: right !default;
$email_signup_form_label_text_align_desktop: null !default; // default to null because large will inherit medium
$email_signup_form_label_letter_spacing: null !default;
$email_signup_form_text_input_background_color: null !default;
$email_signup_form_text_input_border: null !default;
$email_signup_form_text_input_font_color: null !default;
$email_signup_form_alert_box_margin: 1.5em auto 0 !default;
$email_signup_form_input_button_gutter_percent: 0 !default;
$email_signup_form_input_button_gutter_width: 0 !default;
$email_signup_form_input_height: null !default;
$email_signup_form_input_border_right: null !default;
$email_signup_form_button_border_left: null !default;

@mixin email-signup-form() {
    padding: $email_signup_form_padding;
    margin: 0 auto;
    max-width: $email_signup_form_max_width;
    background-color: $email_signup_form_background_color;
}

@mixin email-signup-button() {
    margin-bottom: 0;
    background-color: $email_signup_form_submit_button_background_color;
    border: $email_signup_form_submit_button_border;
    color: $email_signup_form_submit_button_font_color;
    height: $email_signup_form_input_height;
    line-height: 1;

    @include breakpoint(medium) { // Tablet and up
        border-left: $email_signup_form_button_border_left;

        &:hover {
            border-left: $email_signup_form_button_border_left;
        }
    }

    &:hover {
        background-color: $email_signup_form_submit_button_background_color_hover;
        color: $email_signup_form_submit_button_font_color_hover;
        border: $email_signup_form_submit_button_border;
    }

    &:before {
        content: $email_signup_form_submit_button_before_content;
        font-family: $email_signup_form_submit_button_before_font_family;
    }

    &:after {
        content: $email_signup_form_submit_button_after_content;
        font-family: $email_signup_form_submit_button_after_font_family;
    }
}

@mixin email-signup-text-input() {
    background-color: $email_signup_form_text_input_background_color;
    border: $email_signup_form_text_input_border;
    color: $email_signup_form_text_input_font_color;

    @include breakpoint(medium) { // Tablet and up
        padding-top: 0;
        padding-bottom: 0;
        height: $email_signup_form_input_height;
        line-height: $email_signup_form_input_height;
        border-right: $email_signup_form_input_border_right;
    }
}

@mixin email-signup-label() {
    font-family: $email_signup_form_label_font_family;
    font-size: $email_signup_form_label_font_size;
    font-weight: $email_signup_form_label_font_weight;
    letter-spacing: $email_signup_form_label_letter_spacing;
    text-transform: $email_signup_form_label_text_transform;
    color: $email_signup_form_label_font_color;
    text-align: $email_signup_form_label_text_align_mobile;
    display: table;
    width: 100%;
    margin-bottom: $form-spacing;

    @include breakpoint(medium) {
        padding: 0;
        margin-bottom: 0;
        min-height: $email_signup_form_input_height;
        text-align: $email_signup_form_label_text_align_tablet;
    }

    @include breakpoint(large) {
        text-align: $email_signup_form_label_text_align_desktop;
    }

    span {
        display: table-cell;
        vertical-align: middle;
    }
}

@mixin email-form-group {
  flex-wrap: wrap;
}

@mixin email-signup-form-error() {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    margin: $form-spacing 0;

    @include breakpoint(medium) {
        margin-bottom: 0;
    }
}

// Email Signup styles applied to all section.emailsignup elements
@mixin cms-email-signup() {

    section.emailsignup {

        %text-alignment {
            text-align: $email_signup_form_email_input_text_align_mobile;
        }

        @include breakpoint(medium) { // Tablet and up
            form,
            input,
            label,
            [data-abide] small.error {
                margin-bottom: 0;
            }

            %text-alignment {
                text-align: $email_signup_form_email_input_text_align_tablet_up;
            }

            %input-gutter {
                // apply gutter spacing if needed
                @if $email_signup_form_input_button_gutter_percent > 0 or $email_signup_form_input_button_gutter_width > 0 {
                    @if $email_signup_form_input_button_gutter_percent > 0 {
                        width: percentage(1 - $email_signup_form_input_button_gutter_percent / 100);
                    }
                    @if $email_signup_form_input_button_gutter_width > 0 {
                        width: calc(100% - #{$email_signup_form_input_button_gutter_width});
                    }
                }
            }
        }

        button {
            @include email-signup-button;
        }

        form {
            @include email-signup-form;
        }

        small {
            @extend %text-alignment;
            @extend %input-gutter !optional;
        }

        label {
            @include email-signup-label;
        }

        input[type="email"] {
            @extend %text-alignment;
            @extend %input-gutter !optional;
            @include email-signup-text-input;
        }

        .callout {
            margin: $email_signup_form_alert_box_margin;
        }

        .form-error {
            @include email-signup-form-error;
        }

        .email-form-group {
        @include email-form-group;
        }
    }
}
