//////
// Garnish for AEM components
//////

$garnish-full-width-top-padding: null !default;
$garnish-full-width-bottom-padding: null !default;
$garnish-full-width-top-margin: null !default;
$garnish-full-width-bottom-margin: null !default;
$garnish-full-width-top-padding-medium-and-up: null !default;
$garnish-full-width-bottom-padding-medium-and-up: null !default;
$garnish-full-width-top-margin-medium-and-up: null !default;
$garnish-full-width-bottom-margin-medium-and-up: null !default;
$garnish-full-width-top-padding-large-and-up: null !default;
$garnish-full-width-bottom-padding-large-and-up: null !default;
$garnish-full-width-top-margin-large-and-up: null !default;
$garnish-full-width-bottom-margin-large-and-up: null !default;


//////
// Component Top/Bottom Padding classes
//
// This should always be applied to .garnish-full-width to ensure that the component
// background color gets extended to fill the padding area
//////
@mixin garnish-paddings($top: $column-gutter, $bottom: $column-gutter) {

	// need to loop through each section of classes separately so they wont overwrite one another
	@for $i from 0 through 4 {
		&.top-pad-#{$i}x0 {
			padding-top: $top * $i;
		}

		&.top-pad-#{$i}x5 {
			padding-top: $top * ($i + 0.5);
		}
	}

	@for $i from 0 through 4 {
		@include breakpoint(medium) {
			&.top-pad-#{$i}x0-med {
				padding-top: $top * $i;
			}

			&.top-pad-#{$i}x5-med {
				padding-top: $top * ($i + 0.5);
			}
		}
	}

	@for $i from 0 through 4 {
		@include breakpoint(large) {
			&.top-pad-#{$i}x0-lg {
				padding-top: $top * $i;
			}

			&.top-pad-#{$i}x5-lg {
				padding-top: $top * ($i + 0.5);
			}
		}
	}

	@for $i from 0 through 4 {
		&.bottom-pad-#{$i}x0 {
			padding-bottom: $bottom * $i;
		}

		&.bottom-pad-#{$i}x5 {
			padding-bottom: $bottom * ($i + 0.5);
		}
	}

	@for $i from 0 through 4 {
		@include breakpoint(medium) {
			&.bottom-pad-#{$i}x0-med {
				padding-bottom: $bottom * $i;
			}

			&.bottom-pad-#{$i}x5-med {
				padding-bottom: $bottom * ($i + 0.5);
			}
		}
	}

	@for $i from 0 through 4 {
		@include breakpoint(large) {
			&.bottom-pad-#{$i}x0-lg {
				padding-bottom: $bottom * $i;
			}

			&.bottom-pad-#{$i}x5-lg {
				padding-bottom: $bottom * ($i + 0.5);
			}
		}
	}
}

//////
// Component Top/Bottom Margin classes
//
// This should always be applied to the component container to ensure that the component
// background color is not extended to fill the margin area
//////
@mixin garnish-margins($top: $column-gutter, $bottom: $column-gutter) {

	// need to loop through each section of classes separately so they wont overwrite one another
	@for $i from 0 through 4 {
		&.top-margin-#{$i}x0 {
			margin-top: $top * $i;
		}

		&.top-margin-#{$i}x5 {
			margin-top: $top * ($i + 0.5);
		}
	}

	@for $i from 0 through 4 {
		@include breakpoint(medium) {
			&.top-margin-#{$i}x0-med {
				margin-top: $top * $i;
			}

			&.top-margin-#{$i}x5-med {
				margin-top: $top * ($i + 0.5);
			}
		}
	}

	@for $i from 0 through 4 {
		@include breakpoint(large) {
			&.top-margin-#{$i}x0-lg {
				margin-top: $top * $i;
			}

			&.top-margin-#{$i}x5-lg {
				margin-top: $top * ($i + 0.5);
			}
		}
	}

	@for $i from 0 through 4 {
		&.bottom-margin-#{$i}x0 {
			margin-bottom: $bottom * $i;
		}

		&.bottom-margin-#{$i}x5 {
			margin-bottom: $bottom * ($i + 0.5);
		}
	}

	@for $i from 0 through 4 {
		@include breakpoint(medium) {
			&.bottom-margin-#{$i}x0-med {
				margin-bottom: $bottom * $i;
			}

			&.bottom-margin-#{$i}x5-med {
				margin-bottom: $bottom * ($i + 0.5);
			}
		}
	}

	@for $i from 0 through 4 {
		@include breakpoint(large) {
			&.bottom-margin-#{$i}x0-lg {
				margin-bottom: $bottom * $i;
			}

			&.bottom-margin-#{$i}x5-lg {
				margin-bottom: $bottom * ($i + 0.5);
			}
		}
	}
}


@mixin garnish-defaults(
		$padding-top,
		$padding-bottom,
		$margin-top,
		$margin-bottom
	) {

	&.top-pad-default {
		// this media query makes this padding top for mobile first/only
		// without this, the padding will overwrite any additional 
		// .top-pad-#{$i}x0 classes appended due to their position in this file
		@media screen and (max-width: 640px) {
			padding-top: nth($padding-top, 1);
		}

		&-med {
			@include breakpoint(medium) {
				padding-top: nth($padding-top, 2);
			}
		}

		&-lg {
			@include breakpoint(large) {
				padding-top: nth($padding-top, 3);
			}
		}
	}

	&.bottom-pad-default {
		// this media query makes this padding top for mobile first/only
		// without this, the padding will overwrite any additional 
		// .top-pad-#{$i}x0 classes appended due to their position in this file
		@media screen and (max-width: 640px) {
			padding-bottom: nth($padding-bottom, 1);
		}

		&-med {
			@include breakpoint(medium) {
				padding-bottom: nth($padding-bottom, 2);
			}
		}

		&-lg {
			@include breakpoint(large) {
				padding-bottom: nth($padding-bottom, 3);
			}
		}
	}

	&.top-margin-default {
		// this media query makes this padding top for mobile first/only
		// without this, the padding will overwrite any additional 
		// .top-pad-#{$i}x0 classes appended due to their position in this file
		@media screen and (max-width: 640px) {
			margin-top: nth($margin-top, 1);
		}

		&-med {
			@include breakpoint(medium) {
				margin-top: nth($margin-top, 2);
			}
		}

		&-lg {
			@include breakpoint(large) {
				margin-top: nth($margin-top, 3);
			}
		}
	}

	&.bottom-margin-default {
		// this media query makes this padding top for mobile first/only
		// without this, the padding will overwrite any additional 
		// .top-pad-#{$i}x0 classes appended due to their position in this file
		@media screen and (max-width: 640px) {
			margin-bottom: nth($margin-bottom, 1);
		}

		&-med {
			@include breakpoint(medium) {
				margin-top: nth($margin-bottom, 2);
			}
		}

		&-lg {
			@include breakpoint(large) {
				margin-top: nth($margin-bottom, 3);
			}
		}
	}
}

@mixin cms-component-garnish() {
	// Garnish Full Width styles applied to all AEM components
	.garnish-wrapper {
		@include clearfix();
	}

	.garnish-full-width {
		@include garnish-paddings();
		@include garnish-margins();
		@include garnish-defaults(
			(
				$garnish-full-width-top-padding,
				$garnish-full-width-bottom-padding,
				$garnish-full-width-top-margin,
			), (
				$garnish-full-width-bottom-margin,
				$garnish-full-width-top-padding-medium-and-up,
				$garnish-full-width-bottom-padding-medium-and-up,
			), (
				$garnish-full-width-top-margin-medium-and-up,
				$garnish-full-width-bottom-margin-medium-and-up,
				$garnish-full-width-top-padding-large-and-up,
			), (
				$garnish-full-width-bottom-padding-large-and-up,
				$garnish-full-width-top-margin-large-and-up,
				$garnish-full-width-bottom-margin-large-and-up
			)
		);
	}
}
