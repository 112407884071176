// ********************************
// *
// * Article Teaser Grid Component
// *
// * Displays CMS articles in a 4-across grid with
// * AJAX loadinging of additional rows when user
// * clicks on the CTA.
// *
// * Replaces The Wall (Small Layout)
// *
// * Requires the brand-specific sass/cms/_mixins.scss
// *
// ********************************

// article filters
$article-teaser-filters-align: null !default;
$article-teaser-filters-border: null !default;
$article-teaser-filters-display: inline !default;
$article-teaser-filters-font-family: null !default;
$article-teaser-filters-font-size: null !default;
$article-teaser-filters-font-weight: null !default;
$article-teaser-filters-item-color: null !default;
$article-teaser-filters-item-color-hover: null !default;
$article-teaser-filters-item-color-active: null !default;
$article-teaser-filters-item-decoration: none !default;
$article-teaser-filters-item-decoration-active: null !default;
$article-teaser-filters-item-padding: 0 1.1em !default;
$article-teaser-filters-margin-bottom: 30px !default;
$article-teaser-filters-margin-top: 30px !default;
$article-teaser-filters-padding-bottom: 30px !default;
$article-teaser-filters-padding-top: 30px !default;
$article-teaser-filters-seperator-color: null !default;
$article-teaser-filters-seperator-content: null !default;
$article-teaser-filters-seperator-enabled: false !default;
$article-teaser-filters-seperator-padding: null !default;
$article-teaser-filters-text-decoration: none !default;
$article-teaser-filters-text-transform: uppercase !default;
$article-teaser-filters-width: 100% !default;

// article filters menu bar option (default display is a paragraph)
$article-teaser-menu-display: flex !default;
$article-teaser-menu-padding: null !default;
$article-teaser-menu-padding-large: null !default;
$article-teaser-filters-menu-margin: 0 !default;
$article-teaser-filters-menu-padding: 0 !default;
$article-teaser-filters-menu-list-margin: rem-calc(6) 0 0 0 !default;
$article-teaser-filters-menu-list-padding: 0 rem-calc(20) 0 0 !default;
$article-teaser-filters-menu-filter-padding: null !default;

// article filters more menu options
$article-teaser-more-dropdown-align: left !default;
$article-teaser-more-dropdown-background: $white !default;
$article-teaser-more-dropdown-border: 1px solid $light-gray !default;
$article-teaser-more-dropdown-left: 0 !default;
$article-teaser-more-dropdown-max-height: 250px !default;
$article-teaser-more-dropdown-padding: rem-calc(10) !default;
$article-teaser-more-dropdown-right-medium: 0 !default;
$article-teaser-more-dropdown-item-padding: rem-calc(5) 0 !default;
$article-teaser-more-dropdown-filter-font-size: null !default;
$article-teaser-more-dropdown-filter-font-weight: null !default;
$article-teaser-more-label-border-color: transparent !default;
$article-teaser-more-label-border-color-active: $article-teaser-filters-item-color-hover !default;
$article-teaser-more-label-border-width: 2px !default;
$article-teaser-more-label-icon-left: 10px !default;
$article-teaser-more-label-icon-top: 2px !default;

// article search bar
$article-teaser-search-padding: rem-calc(0) rem-calc(10) !default;
$article-teaser-search-bar-backgound: $white !default;
$article-teaser-search-bar-backgound-medium: transparent !default;
$article-teaser-search-bar-padding: rem-calc(15) !default;
$article-teaser-search-bar-padding-medium: rem-calc(15) !default;
$article-teaser-search-bar-shadow: 0 2px 3px rgba($black, 0.5) !default;
$article-teaser-search-bar-shadow-medium: none !default;
$article-teaser-search-btn-backgound: null !default;
$article-teaser-search-btn-color: null !default;
$article-teaser-search-btn-padding: rem-calc(8) !default;
$article-teaser-search-btn-padding-medium: 0;
$article-teaser-search-width-medium: 150px !default;

// article sort by
$article-teaser-sort-padding: rem-calc(0) rem-calc(10) !default;
$article-teaser-sort-label-color: null !default;
$article-teaser-sort-label-display: null !default;
$article-teaser-sort-label-font-family: null !default;
$article-teaser-sort-label-font-size: null !default;
$article-teaser-sort-label-font-weight: null !default;

// article grid
@mixin cms-article-teaser-grid() {
	section.article-teaser-grid {
		@include component-headline();
		overflow: hidden;
		padding-bottom: 0;

		h3 {
			margin-top: 0px;
			padding-bottom: 0;
		}

		.articleteaser {
			&-menu-container {
				@include article-teaser-menu-container();
			}

			&-filters {
				@include article-teaser-filters();
			}

			&-search {
				@include article-teaser-search();
			}

			&-sort {
				@include article-teaser-sort();
			}
		}
	}
}

// article filter menu
@mixin article-teaser-menu-container() {
	@include grid-row;
	display: $article-teaser-menu-display;
	flex-wrap: wrap;
	justify-content: flex-end;
	padding: $article-teaser-menu-padding;
	position: relative;

	@include breakpoint(medium) {
		padding: $article-teaser-menu-padding-large;
	}
}

// article search
@mixin article-teaser-search() {
	padding: $article-teaser-search-padding;

	&-bar {
		background: $article-teaser-search-bar-backgound;
		box-shadow: $article-teaser-search-bar-shadow;
		left: 0;
		padding: $article-teaser-search-bar-padding;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: z-index(topnav) - 1;
	}

	.button {
		margin: 0;
		padding: $article-teaser-search-btn-padding;
	}

	&-button {
		.button {
			background: $article-teaser-search-btn-backgound;
			color: $article-teaser-search-btn-color;
		}

		.opened {
			 &:before {
				@include icomoonFont($icon-close, false);
			 }
		}
	}

	.input-group {
		margin-bottom: 0;
	}

	@include breakpoint(medium) {
		width: $article-teaser-search-width-medium;

		&-bar {
			background: $article-teaser-search-bar-backgound-medium;
			box-shadow: $article-teaser-search-bar-shadow-medium;
			padding: $article-teaser-search-btn-padding-medium;
			position: static;
		}

		.button {
			background: $article-teaser-search-btn-backgound;
			color: $article-teaser-search-btn-color;
		}
	}
}


// article sort by
@mixin article-teaser-sort() {
	padding: $article-teaser-sort-padding;
	white-space: nowrap;

	.sort-by-label {
		color: $article-teaser-sort-label-color;
		display: $article-teaser-sort-label-display;
		font-family: $article-teaser-sort-label-font-family;
		font-size: $article-teaser-sort-label-font-size;
		font-weight: $article-teaser-sort-label-font-weight;
	}

	.sort-by-options {
		margin: 0;
		width: auto;
	}
}


// article filters
@mixin article-teaser-filters() {
	border-bottom: $article-teaser-filters-border;
	border-top: $article-teaser-filters-border;
	margin-bottom: $article-teaser-filters-margin-bottom;
	margin-top: $article-teaser-filters-margin-top;
	padding-bottom: $article-teaser-filters-padding-bottom;
	padding-top: $article-teaser-filters-padding-top;
	width: $article-teaser-filters-width;
	text-align: $article-teaser-filters-align;

	div,
	ul,
	li {
		display: $article-teaser-filters-display;
	}

	li {
		padding: $article-teaser-filters-item-padding;
		text-decoration: $article-teaser-filters-text-decoration;
		text-transform: $article-teaser-filters-text-transform;

		@if $article-teaser-filters-seperator-enabled == true {
			&::after {
				color: $article-teaser-filters-seperator-color;
				content: $article-teaser-filters-seperator-content;
				display: inline-block;
				padding: $article-teaser-filters-seperator-padding;
			}

			&:last-of-type {
				&::after {
					display: none;
				}
			}
		}

		a {
			color: $article-teaser-filters-item-color;
			font-family: $article-teaser-filters-font-family;
			font-size: $article-teaser-filters-font-size;
			font-weight: $article-teaser-filters-font-weight;
			text-decoration: $article-teaser-filters-item-decoration;

			&:focus,
			&:hover {
				color: $article-teaser-filters-item-color-hover;
			}
		}

		&.active {
			a {
				color: $article-teaser-filters-item-color-active;
				text-decoration: $article-teaser-filters-item-decoration-active;
			}
		}
	}

	&.filters-more-menu {
		@include article-teaser-filters-more-menu;
	}
}

// article filters more menu
@mixin article-teaser-filters-more-menu() {
	border-bottom: 0;
	border-top: 0;
	flex-grow: 1;
	margin: $article-teaser-filters-menu-margin;
	padding: $article-teaser-filters-menu-padding;
	position: relative;
	width: auto;

	// filters list
	.articleteaser-filters-list {
		display: flex;
		margin: $article-teaser-filters-menu-list-margin;
		padding: $article-teaser-filters-menu-list-padding;
		position: absolute;

		li {
			padding: $article-teaser-filters-menu-filter-padding;
			white-space: nowrap;

			&:first-child {
				padding-left: 0;
			}
		}
	}

	.filters-more {
		// filters section
		&-menu {
			border-bottom: $article-teaser-more-label-border-width solid $article-teaser-more-label-border-color;
			position: relative;

			&.is-active {
				border-color: $article-teaser-more-label-border-color-active;

				.filters-more {
					&-dropdown {
						display: block;
					}
				}

				.icon-arrow-down-indicator-open {
					transform: rotate(180deg);
				}
			}
		}

		// dropdown list
		&-dropdown {
			background: $article-teaser-more-dropdown-background;
			border: $article-teaser-more-dropdown-border;
			display: none;
			margin-top: $article-teaser-more-label-border-width;
			max-height: $article-teaser-more-dropdown-max-height;
			overflow-y: auto;
			padding: $article-teaser-more-dropdown-padding;
			position: absolute;
			left: $article-teaser-more-dropdown-left;
			text-align: $article-teaser-more-dropdown-align;
			top: 100%;
			z-index: z-index(topnav) - 1;

			li {
				display: block;
				padding: $article-teaser-more-dropdown-item-padding;
			}

			a {
				font-size: $article-teaser-more-dropdown-filter-font-size;
				font-weight: $article-teaser-more-dropdown-filter-font-weight;
			}

			@include breakpoint(medium) {
				left: auto;
				right: $article-teaser-more-dropdown-right-medium;
			}
		}

		// dropdown label
		&-label {
			.icon-arrow-down-indicator-open {
				display: inline-block;
				left: $article-teaser-more-label-icon-left;
				position: relative;
				top: $article-teaser-more-label-icon-top;
			}
		}
	}
}
