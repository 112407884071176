@import 'global/z-indexes';

// =================
// Mini Cart
// -----------------
//used in panels.scss,_toppanels.scss, _rightpanels.scss
	$miniCartFontFamily: inherit !default;
	$minicartFontSize: 0.8125em !default;
	$miniCartShopcartLableFontSize: $topbar-lower-menu-font-size !default;
	$miniCartWidth: 300px !default;
	$rightMiniCartBackgroundColor: $backgroundColor !default;
	$topMiniCartBackgroundColor: #eeeeee !default;
	$miniCartPanelTop: 30px !default;
	$miniCartPanelRight: 394px !default;
	$topMiniCartPanelWidth: 329px !default;
	$miniCartArrowMobileRight: 80px !default;
	$miniCartArrowBackgroundColor: $topMiniCartBackgroundColor !default;
	$miniCartNotificationIconCloseDisplay: null !default;
	$miniCartNotificationTextAlign: left !default;
	$miniCartNotificationTextTransform: uppercase !default;
	$miniCartNotificationFont: inherit !default;
	$miniListNotificationMobileBkgdColor: $backgroundColorDark !default;
	$miniListNotificationMobileColor: #f2f2f2 !default;
	$miniListNotificationMobileItemAttributeColor: #f2f2f2 !default;
	$miniCartArrowMobileBackgroundColor: $miniListNotificationMobileBkgdColor !default;
	$miniListNotificationMobileCloseBtnColor: $miniListNotificationMobileColor !default;
	$miniCartInactiveTabColor: #ccc !default;
	$miniCartCloseIconFloat: right !default;
	$miniCartCloseIconPosition: null !default;
	$miniCartCloseIconBorder: null !default;
	$miniCartCloseIconBorderColorHover: null !default;
	$miniCartCloseIconPadding: null !default;
	$miniCartCloseIconSize: 16px !default;
	$miniCartCloseIconDisplay: null !default;
	$miniCartCloseIconFontWeight: 600 !default;
	$miniCartCloseIconOutline: auto !default;
	$miniCartScrollButtonSize: 20px !default;
	$miniCartWavedSepDisplay: null !default;
	$miniCartActionDisplay: block !default;
	$miniCartActionContinueShoppingMarginTop: 10px !default;
	$miniCartActionPaypalButtonBorder: null !default;
	$miniCartContentHeadMarginTop: 14px !default;
	$miniCartItemNamePaddingRight: null !default;
	$miniCartItemNameLineHeight: null !default;
	$miniCartItemNameMarginTop: 0 !default;
	$miniCartItemAttrLineHeight: null !default;
	$miniCartItemPriceColor: $brandColorPalette1 !default;
	$miniCartOriginalPriceMarginRight: null !default;
	$miniCartOriginalPriceTextDecoration: null !default;
	$miniCartCurrentPriceColor: null !default;
	$miniCartItemLinksPaddingTop: 10px !default;
	$miniCartItemRowMarginTop: 10px !default;
	$miniCartItemRowPadding: null !default;
	$miniCartItemRowLastChildPadding: null !default;
	$miniCartItemRowEvenMargin: null !default;
	$miniCartItemRowEvenPadding: null !default;
	$miniCartItemRowOddMarginBottom: null !default;
	$miniCartItemPosition: null !default;
	$miniCartItemMarginBottom: 20px !default;
	$miniCartItemMarginTop: 20px !default;
	$miniCartItemTextColor: $fontGreyDark !default;
	$miniCartItemNavHeight: 25px !default;
	$miniCartItemNavBgColor: #ccc !default;
	$miniCartActionItemContainerMarginBottom: 10px !default;
	$miniCartActionItemContainerPadding: null !default;
	$miniCartPrevNextButtonSize: 18px !default;
	$miniCartNotificationMargin: 10px 15px 0 !default;
	$pageTitleTextTransform: uppercase !default;

	//top mini cart variables
	$topMiniCartSeparator: 1px #2a2a2a solid !default;
	$topMiniCartCartBorder: $BGSectionSeperatorC !default;
	$topMiniCartSectionTitleColor: #2a2a2a !default;
	$topMiniCartPageTitleColor: null !default;
	$topMiniCartProductInfoPadding: null !default;
	$miniListItemSepBorder: $BGSectionSeperatorC !default;

	$miniCartNotificationCTACheckoutButtonContainerMargin: 0 auto !default;
	$miniCartNotificationCTACheckoutButtonMarginTop: $column-gutter !default;
	$miniCartNotificationCTACheckoutButtonWidth: null !default;
	$miniCartNotificationCTACheckoutButtonContainerPadding: null !default;
	$miniCartNotificationCTACheckoutButtonContainerMaxWidth: null !default;

	//mini cart custom header variables
	$miniCartCustomHeadPadding: 10px 0 !default;
	$miniCartCustomHeadFontWeight: 700 !default;
	$miniCartCustomHeadFontSize: 1em !default;

	// mini cart balance variables
	$miniCartBalanceTextColor: get-color(secondary) !default;
	$miniCartBalanceFontSize: rem-calc(12) !default;
	$miniCartBalanceMarginBottom: null !default;

	// cart balance modal variables
	$cartBalanceModalFontSize: rem-calc(14) !default;
	$cartBalanceModalMargin: rem-calc(40) 0 !default;


.mini-cart-section, // ECOM legacy TopNav and Sidebar minicart
.topnav-minicart-panel { // CMS TopNav minicart
	@include z-index(minicart);

	ul.tags {
		left: -35px;
		list-style: none;
		list-style-type: none;
		margin: 0;
		position: absolute;
		top: 275px;
		width: 35px;

		li {
			background-color: $button-primary;
			color: #fff;
			cursor: pointer;
			float: none;
			list-style: none;
			margin: 0 0 1px;
			padding: 12px 0;
			text-align: center;

			&.cart {
				background-color: $button-cta;
			}

			&.wishlist {
				background-color: #1c1c1c;
			}

			&.inactive {
				opacity: 0.6;
			}
		}
	}

	.content,
	article {
		box-sizing: border-box;
		height: 100%;
		left: 0;
		overflow: hidden;
		overflow-y: hidden;
		padding: 20px 15px;
		position: absolute;
		top: 0;
		width: $miniCartWidth;
	}

	.content {
		display: none;
		position: relative;

		@include breakpoint(medium) {
			display: block;
		}
	}

	article {
		font-size: $minicartFontSize;
		left: $miniCartWidth;
		z-index: 1;
	}

	.notification {
		background-color: $backgroundColorSuccess;
		color: $fontSuccess;
		font-family: $miniCartNotificationFont;
		margin: $miniCartNotificationMargin;
		padding: 15px 20px;
		text-align: $miniCartNotificationTextAlign;
		text-transform: $miniCartNotificationTextTransform;

		.close-btn {
			display: none;
		}

		.icon-close {
			display: $miniCartNotificationIconCloseDisplay;
		}

		.mini-cart-action-go-to-checkout-container {
		margin: $miniCartNotificationCTACheckoutButtonContainerMargin;
	    margin-top: $miniCartNotificationCTACheckoutButtonMarginTop;
	    max-width: $miniCartNotificationCTACheckoutButtonContainerMaxWidth;
	    padding: $miniCartNotificationCTACheckoutButtonContainerPadding;
	    text-align: center;

		    .mini-cart-action-go-to-checkout {
		    	width: $miniCartNotificationCTACheckoutButtonWidth;
		    }
		}
	}

	.wishlist-actions {
		text-align: center;

		&.first {
			display: block;
		}
	}

	.espot-container {
		.mini-cart-promo-header {
			padding-bottom: 3px;
		}

		p {
			color: #64686b;
			margin-bottom: 17px;
			text-align: center;
		}
	}

	.cart-list .cart-item-container.three-column {
		.cart-item-col.product-image {
			width: 33.333%;
		}

		.cart-item-col.cart-item-links {
			width: 16.667%;
		}
	}

	&::before {
		border: solid transparent;
		border-bottom-color: $miniCartArrowMobileBackgroundColor;
		border-width: 10px;
		content: '\00a0';
		height: 0;
		margin-left: -9px;
		position: absolute;
		right: $miniCartArrowMobileRight;
		top: -20px;
		width: 0;

		@include breakpoint(medium) {
			display: none;
		}
	}
}

.cart-list { // styling for general mini-list template
	font-size: $minicartFontSize;

	.mini-cart-icon-close {
		@include close-button;
		@include close-button-size(small);
		border: $miniCartCloseIconBorder;
		display: $miniCartCloseIconDisplay;
		float: $miniCartCloseIconFloat;
		font-size: $miniCartCloseIconSize;
		font-weight: $miniCartCloseIconFontWeight;
		padding: $miniCartCloseIconPadding;
		position: $miniCartCloseIconPosition;
		outline: $miniCartCloseIconOutline;

		&:hover {
			border-color: $miniCartCloseIconBorderColorHover;
			}
	}

	form,
	.dropdown {
		margin: 0;
	}

	.close-btn {
		cursor: pointer;
		position: absolute;
		right: 20px;
		top: 20px;
		z-index: 100;

		span {
			display: none !important;
		}

		&:hover {
			background-position-y: -3059px;
		}
	}

	.cart-actions,
	.content-head {
		text-align: center;
	}

	.content-head {
		margin-top: $miniCartContentHeadMarginTop;
	}

	.mini-cart-custom-head {
		border-bottom: 1px solid $light-gray;
		font-size: $miniCartCustomHeadFontSize;
		font-weight: $miniCartCustomHeadFontWeight;
		padding: $miniCartCustomHeadPadding;
		text-align: center;
		text-transform: uppercase;
	}

	.content-foot {
		text-align: center;

		a {
			margin-right: 15px;
		}
	}

	.button {
		width: 100%;
	}

	.section-title,
	.page-title {
		line-height: 1em;
		text-transform: $pageTitleTextTransform;
	}

	.page-title {
		font-size: convert-to-em(24);
	}

	.section-title {
		font-size: convert-to-em(16);
	}

	.cart-actions {
		@include grid-row;
		order: 3;
		width: 100%;

		&.first {
			display: $miniCartActionDisplay;
		}
	}

	.cart-actions-item {
		float: left;
		margin-bottom: $miniCartActionItemContainerMarginBottom;
		padding: $miniCartActionItemContainerPadding;
	}

	.mini-cart-continue-shopping {
		margin-top: $miniCartActionContinueShoppingMarginTop;
	}

	.mini-cart-action-paypal-button {
		border: $miniCartActionPaypalButtonBorder;
	}

	.cart-item-nav {
		height: $miniCartItemNavHeight;
		position: relative;
		z-index: 2;

		&.prev {
			margin-bottom: 10px;
		}

		.nav-button {
			cursor: pointer;
			height: 100%;
			width: 100%;

			&:hover {
				background-color: $miniCartItemNavBgColor;
			}
		}

		.prev-btn,
		.next-btn {
			font-size: $miniCartPrevNextButtonSize;
			margin: 0 auto;
			text-align: center;
			width: $miniCartScrollButtonSize;
		}

		&.disabled .nav-button {
			color: #ccc;
			cursor: default;

			&:hover {
				background-color: transparent;
			}
		}
	}

	.cart-item-container {
		@include grid-column-collapse;
		float: left;
		order: 6;
		overflow: hidden;
		position: relative;

		.cart-item-slide {
			position: absolute;
			width: 100%;
		}
	}

	.cart-item {
		margin-top: $miniCartItemMarginTop;
		position: $miniCartItemPosition;

		&:first-child {
			margin-top: 0;
		}

		&:last-child:not(:first-child) {
			margin-bottom: $miniCartItemMarginBottom;
		}

		&.show-desktop-only {
			display: none;

			@include breakpoint(large) {
				display: block;
			}
		}
	}

	.cart-item-links {
		padding-top: $miniCartItemLinksPaddingTop;
	}

	.cart-item-row {
		@include grid-row(12);
		margin-top: $miniCartItemRowMarginTop;
		padding: $miniCartItemRowPadding;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			padding: $miniCartItemRowLastChildPadding;
		}

		//Row that has absolutely placed close icon
		&:nth-child(even) {
			margin: $miniCartItemRowEvenMargin;
			padding: $miniCartItemRowEvenPadding;
		}

		//Row that has actual item
		&:nth-child(odd) {
			margin-bottom: $miniCartItemRowOddMarginBottom;
		}
	}

	.cart-item-sep {
		border-bottom: $miniListItemSepBorder;
	}

	.cart-item-col {
		color: $miniCartItemTextColor;
		line-height: 1.3em;

		&.actions {
			padding-top: 0;
		}

		p {
			line-height: 1.5em;
			margin: 0;
			word-wrap: break-word;

			&:first-child {
				line-height: $miniCartItemNameLineHeight;
				margin-top: $miniCartItemNameMarginTop;
			}

			&.mini-cart-item-attr {
				line-height: $miniCartItemAttrLineHeight;
			}
		}

		.mini-cart-item-name {
			padding-right: $miniCartItemNamePaddingRight;
		}

		.item-price {
			color: $miniCartItemPriceColor;
		}

		.mini-cart {
			&-original-price {
				margin-right: $miniCartOriginalPriceMarginRight;
				text-decoration: $miniCartOriginalPriceTextDecoration;

			}

			&-current-price {
				color: $miniCartCurrentPriceColor;
			}
		}

		.item-count {
			color: $darkText;
		}
	}

	.cart-subtotal {
		@include setFontStyle($miniCartSubtotalFontStyle);
	}

	.cart-item-col-action {
		p {
			margin: 0;
		}
		.mini-cart-remove-item-text {
			color: $darkText;
		}
	}

	.cart-balance-container {
		margin-bottom: $miniCartBalanceMarginBottom;

		&-message {
			color: $miniCartBalanceTextColor;
			font-size: $miniCartBalanceFontSize;
		}
	}

}

// Check Balance Reveal
.cart-balance-modal {
	p {
		font-size: $cartBalanceModalFontSize;
	    margin: $cartBalanceModalMargin;
	    text-align: center;
	}

	&-button {
	    align-items: center;
		display: flex;
	    justify-content: center;
	}
}
