//
// Sitewide manifest of major z-index values
// This ensures consistent stacking between brands.
// Derived values should be calculated from these
//

$reveal-zindex: 1005 !default;

$z-indexes: (
  default: 0,
  topbarfilter: 13,
  filtersubmenu: 12,
  breadcrumbdropdown: 14,
  carousels: 50,
  badges: 75,
  stickyfilter: 95,
  topnav: 100,
  fixedbuttons: 85,
  reveal: $reveal-zindex + 1,
  revealactions: $reveal-zindex + 2,
  minicart: 5001,
  offcanvas: 1000,
  heroviewer: 1,
  heroviewercontrols: 2,
  heroviewerfixed: -1,
) !default;

@function z-index($name) {
  @return map-get($z-indexes, $name);
}

@mixin z-index($name) {
  z-index: z-index($name);
}
