// Custom tooltip content styles
// Sets the common styles for tooltip content

////
/// @group VFDP tooltip
////

// .375rem is 1/2 the default padding used for the tooltip container
$foundation-tooltip-content-image-margin-bottom: 0.375rem !default;
$foundation-tooltip-content-title-font-weight: null !default;
$foundation-tooltip-content-title-text-align: null !default;

// All tooltip content is inside a container with class `tooltip`
@mixin tooltip-content {
  .tooltip {
    // only add margin if the image is not the last child
    & &-image:not(:last-child) {
      margin-bottom: $foundation-tooltip-content-image-margin-bottom;
    }

    & &-title {
      font-weight: $foundation-tooltip-content-title-font-weight;
      text-align: $foundation-tooltip-content-title-text-align;
    }
  }
}
