////////////////////////////////////////////////
// TODO: move all HTML and SCSS calls to xy-grid in foundation 6
//
// Grid migration mixin that allows deprecated paramaters to be used
// without refactoring in foundation 6.
// To use, replace instance of mixin 'grid-colum' with 'grid-column-f4'
//
////////////////////////////////////////////////

//
// Grid Function
//

// Creates a column, should be used inside of a media query to control layouts
//
// $columns - The number of columns this should be.  Can use %, <1 or decimals
// $last-column - Is this the last column? Default: false.
// $center - Center these columns? Default: false.
// $offset - # of columns to offset. Default: false.
// $push - # of columns to push. Default: false.
// $pull - # of columns to pull. Default: false.
// $collapse - Get rid of gutter padding on column? Default: false. Options: false.
// $float - Should this float? Default: true. Options: true, false, left, right.

@mixin grid-column-f4(
  $columns: false,
  $row: 'row',
  $column: 'column',
  $last-column: false,
  $center: false,
  $offset: false,
  $push: false,
  $pull: false,
  $collapse: false,
  $float: true,
  $position: false,
  $include_position_relative: false,
  $gutter: 'gutter') {

  // If positioned for default .column, include relative position
  // push and pull require position set
  @if $position or $include_position_relative or $push or $pull {
    position: relative;
  }

  // If a column number is given, calculate width
  @if $columns {
    @if $columns < 1 {
      width: grid-column(percentage($columns/12));
    } @else {
        @include grid-column-size($columns);
    }

    @if $float == true or $float == left {
      float: $global-left;
      //maybe add grid-column-end
    } @else if $float == false or $float == none {
      float: none;
    }

    @if $collapse {
      @include grid-column($columns: $columns, $gutters: 0);
    } @else if $collapse == false or $collapse == null {
      @include grid-column-gutter();
    }

    // If last column, float naturally instead of to the right
    @if $last-column {
        float: $global-right;
    } @else if $last-column == false {
        @if $float != true or $float != left {
          float: $global-left;
        }
    }
  }

  // Source Ordering, adds left/right depending on which you use.
  @if $push {
    #{$global-left}: grid-column($push);
    #{$global-right}: auto;
  }

  @if $pull {
    #{$global-left}: auto;
    #{$global-right}: grid-column($pull);
  }

  //@debug "1 value of float: #{$float}";

  @if $last-column == false {
    @if $float == right {
      float: $global-right;

      &,
      &:last-child:not(:first-child) {
          float: $global-right;
        }
      } @else if $float == none or $float == false {
        float: none;

        &,
        &:last-child:not(:first-child) {
          float: none;
        }
    }
  }

//@debug "2 value of float: #{$float}";

  // If centered, get rid of float and add appropriate margins
  @if $center {
    margin-#{$global-left}: auto;
    margin-#{$global-right}: auto;
    float: none !important; // sass-lint:disable-line no-important
  }

  // If offset, calculate appropriate margins
  @if $offset {
      margin-#{$global-left}: grid-column(percentage($offset/12));
    }
}

//
// Fix nesting within collapsed rows
//
// Use to remove negative margin from nested rows within row.collapse as well
// as reintroduce inner column padding. This bug has been resolved in
// Foundation 5: https://github.com/zurb/foundation/pull/2739
//
// TODO: Remove in VFDP-522 / VFDP-85205
//
@mixin grid-fix-row-collapse {
  .row {
      margin: 0;

      .columns {
          padding: $column-gutter;
      }
  }
}


// **
// * TODO: Remove in VFDP-85205
// *
// * .{size}-offset-half
// * Use to center an uneven number of columns when the left over space is 1 column
// *  [offset 0.5 grid 3] [grid 3] [grid 2] [grid 3 (end)] [remaining 0.5]
// *
// * .{size}-offset-1half
// * Use to center an uneven number of columns when the left over space is 3 columns
// *  [offset 1.5 grid 3] [grid 3 (offset 1.5)] [grid 3] [grid 3 (end)] [remaining 1.5]
// **
@mixin foundation-4-offset-classes {
    @media only screen {
      .small-offset-half {
          @include grid-column-f4($offset: 0.5, $collapse: null, $float: false);
      }

      .small-offset-1half {
          @include grid-column-f4($offset: 1.5, $collapse: null, $float: false);
      }
  }

  @include breakpoint(medium) {
      .medium-offset-half {
          @include grid-column-f4($offset: 0.5, $collapse: null, $float: false);
      }

      .medium-offset-1half {
          @include grid-column-f4($offset: 1.5, $collapse: null, $float: false);
      }
  }

  @include breakpoint(large) {
      .large-offset-half {
          @include grid-column-f4($offset: 1.5, $collapse: null, $float: false);
      }

      .large-offset-1half {
          @include grid-column-f4($offset: 1.5, $collapse: null, $float: false);
      }
  }

  .row {
      // Fix nested grid collapse globally
      &.collapse {
          @include grid-fix-row-collapse;
      }

      &.full-bleed {
          max-width: 100%;
      }
  }
}
