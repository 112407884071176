// ****
// * Article Teasers are previews of articles displayed in various components
// ****

// container
$articleTeaserPaddingBottom: em-calc(20) !default;
$articleTeaserTextAlign: null !default;
$articleTeaserVerticalAlign: null !default;

// image
$articleTeaserImageMarginBottom: null !default;

// tags
$articleTeaserTagColor: null !default;
$articleTeaserTagSize: null !default;
$articleTeaserTagWeight: null !default;
$articleTeaserTagLineHeight: null !default;
$articleTeaserTagListMargin: null !default;
$articleTeaserTagListStyle: null !default;
$articleTeaserTagTransform: null !default;
$articleTeaserTagItemMargin: null !default;

// info
$articleTeaserInfoColor: null !default;
$articleDetailInfoFontSize: rem-calc(12) !default;
$articleDetailInfoMargin: 0 0 em-calc(10) !default;

// author
$articleTeaserAuthorBorder: null !default;
$articleTeaserAuthorFontWeight: null !default;
$articleTeaserAuthorMargin: rem-calc(10) !default;
$articleTeaserAuthorPadding: rem-calc(10) !default;

// details
$articleTeaserDetailsHideOnSmall: false !default;
$articleTeaserDetailsColor: null !default;
$articleTeaserDetailsFontSize: null !default;
$articleTeaserDetailsLetterSpacing: null !default;
$articleTeaserDetailsLineHeight: null !default;
$articleTeaserDetailsMargin: null !default;

// caption
$articleDetailCaptionColor: $body-font-color !default;
$articleDetailCaptionFontFamily: null !default;
$articleDetailCaptionFontSize: null !default;
$articleDetailCaptionFontSizeMedium: null !default;
$articleDetailCaptionFontWeight: null !default;
$articleTeaserCaptionLetterSpacing: null !default;
$articleDetailCaptionLineHeight: null !default;
$articleDetailCaptionMargin: em-calc(15) 0 !default;
$articleDetailCaptionPadding: null !default;
$articleDetailCaptionTransform: null !default;

// read more
$articleTeaserReadMoreHideOnSmall: false !default;
$articleTeaserReadMoreFontSize: null !default;
$articleTeaserReadMoreLetterSpacing: null !default;
$articleTeaserReadMoreLineHeight: null !default;

// list-view (alternative display, default is grid)
$articleTeaserListBorder: null !default;
$articleTeaserListMargin: null !default;
$articleTeaserListPadding: null !default;
$articleTeaserListMarginMedium: null !default;
$articleTeaserListPaddingMedium: null !default;
$articleTeaserListContentMarginMedium: rem-calc(40) !default;
$articleTeaserListImageMargin: 0 rem-calc(15) rem-calc(15) 0 !default;
$articleTeaserListImageWidth: 75px !default;
$articleTeaserListImageWidthMedium: 125px !default;
$articleTeaserListTagWidthMedium: 160px !default;

@mixin cms-article-teaser {
    .article.teaser {
        padding-bottom: $articleTeaserPaddingBottom;
		text-align: $articleTeaserTextAlign;
        vertical-align: $articleTeaserVerticalAlign;

        picture {
            margin-bottom: $articleTeaserImageMarginBottom;
			width: 100%;
        }

        p {
			color: $articleTeaserDetailsColor;
            font-size: $articleTeaserDetailsFontSize;
            letter-spacing: $articleTeaserDetailsLetterSpacing;
            line-height: $articleTeaserDetailsLineHeight;
            margin-bottom: $articleTeaserDetailsMargin;

            @if $articleTeaserDetailsHideOnSmall == true {
                display: none;

                @include breakpoint(medium) {
                    display: block;
                }
            }
        }

        .info {
            color: $articleTeaserInfoColor;
            font-size: $articleDetailInfoFontSize;
            margin: $articleDetailInfoMargin;
        }

        .author {
            border-right: $articleTeaserAuthorBorder;
            font-weight: $articleTeaserAuthorFontWeight;
            margin-right: $articleTeaserAuthorMargin;
            padding-right: $articleTeaserAuthorPadding;
        }

        // The caption is always a link but presented as an H5 by default
        .caption {
            color: $articleDetailCaptionColor;
            display: block;
			font-family: $articleDetailCaptionFontFamily;
			font-size: $articleDetailCaptionFontSize;
			font-weight: $articleDetailCaptionFontWeight;
            letter-spacing: $articleTeaserCaptionLetterSpacing;
			line-height: $articleDetailCaptionLineHeight;
            margin: $articleDetailCaptionMargin;
            padding: $articleDetailCaptionPadding;
            text-decoration: none;
			text-transform: $articleDetailCaptionTransform;

			@include breakpoint(medium) {
			    font-size: $articleDetailCaptionFontSizeMedium;
			}
        }

        .read-more-link {
            font-size: $articleTeaserReadMoreFontSize;
            letter-spacing: $articleTeaserReadMoreLetterSpacing;
            line-height: $articleTeaserReadMoreLineHeight;

            @if $articleTeaserReadMoreHideOnSmall == true {
                display: none;

                @include breakpoint(medium) {
                    display: block;
                }
            }
        }

        .tag {
            color: $articleTeaserTagColor;
            font-size: $articleTeaserTagSize;
            font-weight: $articleTeaserTagWeight;
            line-height: $articleTeaserTagLineHeight;
            list-style: $articleTeaserTagListStyle;
            margin: $articleTeaserTagListMargin;
            text-transform: $articleTeaserTagTransform;

            li {
                margin: $articleTeaserTagItemMargin;
            }
        }

        &.list-view {
            @include cms-article-teaser-list-view;
        }
    }
}

@mixin cms-article-teaser-list-view {
    border-bottom: $articleTeaserListBorder;
    display: block;
    margin: $articleTeaserListMargin;
    padding: $articleTeaserListPadding;

    .caption {
        margin-top: 0;
    }

    .details {
        clear: both;
    }

    .thumb {
        float: left;
        margin: $articleTeaserListImageMargin;
        width: $articleTeaserListImageWidth;
    }

    @if $articleTeaserListBorder {
        .cat-dynamic-grid > .row:last-child > .columns:last-child & {
            border-bottom: 0;
        }
    }

    @include breakpoint(medium) {
        display: flex;
        flex-wrap: nowrap;
        margin: $articleTeaserListMarginMedium;
        padding: $articleTeaserListPaddingMedium;

        .content {
            margin-right: $articleTeaserListContentMarginMedium;
            width: 100%;
        }

        .details {
            clear: none;
        }

        .tag {
            min-width: $articleTeaserListTagWidthMedium;
            width: $articleTeaserListTagWidthMedium;
        }

        .thumb {
            min-width: $articleTeaserListImageWidthMedium;
            width: $articleTeaserListImageWidthMedium;
        }
    }
}
