// ********************************
// *
// * Custom HTML Component
// *
// ********************************

@mixin cms-custom-html {
	// Custom HTML classes
	.customhtml,
    .customhtml2 {
		.garnish-full-width {
            &.bottom-pad-default {
                padding-bottom: 0;
            }

            &.top-pad-default {
                padding-top: 0;
            }

            &.bottom-margin-default {
                margin-bottom: 0;
            }

            &.top-margin-default {
                margin-top: 0;
            }
		}
	}
}
