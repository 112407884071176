// ********************************
// *
// * Product Grid Component
// *
// * Displays CMS products in a 4-across grid.
// *
// *
// * Requires the brand-specific sass/cms/_mixins.scss
// *
// ********************************

@import 'cms/components/product-grid';

@mixin napa-product-grid {
  .productgrid {
    .product {
      &-teaser-wrapper {
        margin-bottom: 20px;

        @include breakpoint(large) {
          margin-bottom: 30px;
        }
      }
    }
  }
}
