// **
// * Implicit Image Ratios
// * http://stackoverflow.com/questions/1495407/how-to-maintain-the-aspect-ratio-of-a-div-using-only-css
// *
// * Use as <picture class="ratio-XXXX"> where XXXX is the ratio name such as "square" or "16-9"
// *
// **

//
// ImagesLoaded doesn't work with HTML5 picture elements and srcset attribute
//
// As a workaround for our needs, we can enforce aspect ratios on <picture> elements
// https://github.com/desandro/imagesloaded/issues/175
//


@mixin ratio($width, $height) {
	position: relative;
	display: block;

	&::after {
		content: '';
		display: block;
		height: 0;
		padding-bottom: percentage($height / $width);
		width: 100%;
	}

	> * {
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

@mixin ratio-classes {

	// Use to indicate square image assets
	.ratio-square {
		@include ratio(600, 600);
	}

	// Traditional TV/Monitor display ratio
	.ratio-4-3 {
		@include ratio(400, 300);
	}

	// Typical widescreen display ratio
	.ratio-16-9 {
		@include ratio(1600, 900);
	}

	// Width double the height
	.ratio-2-1 {
		@include ratio(2, 1);
	}

	// Special-case use when we won't force a display ratio. This might have problems in correctly sizing contents vertically
	.ratio-unbound {
		display: block;
		position: relative;

		> * {
			display: block;
			width: 100%;
		}
	}
}
