// ********************************
// *
// * Carousel Container Component
// *
// * Displays various complex CMS slides in a single carousel
// *
// *
// * Requires the global sass/cms/widgets/_carousel-controls.scss
// * Requires the brand-specific sass/cms/_mixins.scss
// *
// ********************************

// Use the global shared overlay arrow styles by default
$carousel-container-overlay-arrow-size: 			$carousel-overlay-arrow-size !default;
$carousel-container-overlay-arrow-color: 			$carousel-overlay-arrow-color !default;
$carousel-container-overlay-arrow-background: 		$carousel-overlay-arrow-background !default;
$carousel-container-overlay-arrow-color-hover: 		$carousel-overlay-arrow-color-hover !default;
$carousel-container-overlay-arrow-background-hover: $carousel-overlay-arrow-background-hover !default;
$carousel-container-overlay-arrow-width: 			$carousel-overlay-arrow-width !default;
$carousel-container-overlay-arrow-height: 			$carousel-overlay-arrow-height !default;
$carousel-container-overlay-arrow-opacity:          $carousel-overlay-arrow-opacity !default;
$carousel-container-overlay-arrow-opacity-hover:    $carousel-overlay-arrow-opacity-hover !default;

// Use the global shared lower arrow styles by default
$carousel-container-lower-arrow-size: 				$carousel-lower-arrow-size !default;
$carousel-container-lower-arrow-color: 				$carousel-lower-arrow-color !default;
$carousel-container-lower-arrow-color-hover: 		$carousel-lower-arrow-color-hover !default;
$carousel-container-lower-arrow-padding:            $carousel-lower-arrow-padding !default;
$carousel-container-lower-arrow-background:         $carousel-lower-arrow-background !default;
$carousel-container-lower-arrow-background-hover:   $carousel-lower-arrow-background-hover !default;
$carousel-container-lower-container-margin:         $carousel-lower-container-margin !default;

// Use the global pager indicator styles by default
$carousel-container-pager-indicator-size:				$carousel-pager-indicator-size !default;
$carousel-container-pager-indicator-margin: 			$carousel-pager-indicator-margin !default;
$carousel-container-pager-indicator-border-radius:		$carousel-pager-indicator-border-radius !default;
$carousel-container-pager-indicator-background:			$carousel-pager-indicator-background !default;
$carousel-container-pager-indicator-border:				$carousel-pager-indicator-border !default;
$carousel-container-pager-indicator-active-background: 	$carousel-pager-indicator-active-background !default;
$carousel-container-pager-indicator-active-border: 		$carousel-pager-indicator-active-border !default;
$carousel-container-pager-indicator-hover-background:	$carousel-pager-indicator-hover-background !default;
$carousel-container-pager-indicator-hover-border:		$carousel-pager-indicator-hover-border !default;

// Carousel Container styles applied to all section.carousel-container elements
@mixin cms-carousel-container() {

    section.carousel-container {

        @include component-headline();

        .carousel-wrapper {
            position: relative;

            @include carousel-overlay-arrows(
                $carousel-container-overlay-arrow-width,
                $carousel-container-overlay-arrow-height,
                $carousel-container-overlay-arrow-size,
                $carousel-container-overlay-arrow-color,
                $carousel-container-overlay-arrow-background,
                $carousel-container-overlay-arrow-color-hover,
                $carousel-container-overlay-arrow-background-hover,
                $carousel-container-overlay-arrow-opacity,
                $carousel-container-overlay-arrow-opacity-hover
            );

            .carousel {
                overflow: hidden; // Prevent scrollbar on cycle
                @include clearfix; // Prevent the div from collapsing
            }

            .slide {
                @include clearfix; // Prevent the div from collapsing

                // Hide slides until component initiates so we don't have page jumpiness
                // before init. Then slides sizes are determined by Cycle 2 and displayed.
                display: none;

                // Ensure slides don't have weird scaling animations
                width: 100%;

                // Show the first slide even before the carousel initializes
                &:first-of-type {
                    display: block;
                }

                // prevent inactive slides from receiving focus
                &.inactive {
                    & > div {
                        visibility: hidden;
                    }
                }
            }
        }

        &.carousel-fx-carousel {
            .slide {
                padding-left: $column-gutter / 2;
                padding-right: $column-gutter / 2;
            }
        }

        @include carousel-lower-controls(
            $carousel-container-lower-arrow-size,
            $carousel-container-lower-arrow-color,
            $carousel-container-lower-arrow-color-hover,
            $carousel-container-lower-arrow-padding,
            $carousel-container-lower-arrow-background,
            $carousel-container-lower-arrow-background-hover,
            $carousel-container-pager-indicator-size,
            $carousel-container-pager-indicator-margin,
            $carousel-container-pager-indicator-border-radius,
            $carousel-container-pager-indicator-background,
            $carousel-container-pager-indicator-border,
            $carousel-container-pager-indicator-active-background,
            $carousel-container-pager-indicator-active-border,
            $carousel-container-pager-indicator-hover-background,
            $carousel-container-pager-indicator-hover-border,
            $carousel-container-lower-container-margin
        );

        // display overlay carousel controls when carousel receives focus
        &.show-controls-on-focus {
            .carousel-wrapper {
                @include carousel-controls-on-hover(
                    $color-hover: $carousel-container-overlay-arrow-color-hover,
                    $background-hover: $carousel-container-overlay-arrow-background-hover,
                    $opacity-hover: $carousel-container-overlay-arrow-opacity-hover
                );
            }
        }
    }
}
