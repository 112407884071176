// ********
// *
// * Global Variables Setup
// *
// * Set all brand-specific Foundation and SCSS variables here
// *
// * This file should be variable definitions only! No styling allowed!
// *
// ********

@import '../../fonts/icomoon/variables'; // Icon fonts
@import 'global/default-variables'; // Platform defaults

//---------------------------------------------------------------
//          DO NOT MODIFY ANYTHING ABOVE THIS LINE
//---------------------------------------------------------------





//---------------------------------------------------------------
//          Custom Variables
//---------------------------------------------------------------
// Leave default values commented out so it's
// easy to tell what the result will be if a variable is undone.
//
// Always use Foundation variables first before creating new variables below!
//
// See shared/sass/global/default-variables.scss for more details
//---------------------------------------------------------------
$column-gutter: column-gutter();
$subSectionHeaderBackLinkLeft: 10px;

$anchor-transition: all;
$anchor-transition-speed: 200ms;

$loading-spinner-width: 166px;
$loading-spinner-height: $loading-spinner-width;
$loading-spinner-border-radius: 10px;
$loading-spinner-background: $medium-gray;
$loading-spinner-text-bottom: 30px;
$loading-spinner-indicator-top: 40px;
$loading-spinner-indicator-size: 64px;
$loading-spinner-opacity: 1;
$loading-spinner-font: (
  'font': (
    'font-size': 16px
  )
);

$formLabelMediumUpFloat: none;
$formLabelMediumUpPaddingRight: 0;
$formLabelMediumUpTextAlign: left;
$formLabelMediumUpWidth: auto;

$mobileLargeInputWidth: 100%;

// Forms
$formInValidBackground: transparent;
$errorText: get-color(cinnabar);
$fontError: get-color(cinnabar);


// Animations
// $hoverDuration: 250ms;

//---------------------------------------------------------------
// CMS-Hosted Top Nav
//
// Manages to the top nav and does NOT use Foundation TopBar
//---------------------------------------------------------------
// $include-html-topnav-logo-classes: $include-html-classes;

$topnav-color: $black;
$topnav-bg: $white;
$topnav-button-color: $topnav-color;
// $topnav-button-color-hover: $topnav-color;
// $topnav-button-bg: null;
// $topnav-button-width: 44px;
$topnav-button-height: 53px;
$topnav-button-font-size: 16px;
$topnav-button-active-color: get-color(eclipse);
$topnav-button-active-bg: transparent;

// Top Nav - Logo
$store-logo: url('../images/logos/logo.svg');
$topnav-logo-width: 113px;
$topnav-logo-height: 19px;
$topnav-logo-top: -6px;
$topnav-logo-margin: 17px $column-gutter/2;
$topnav-logo-width__medium: 282px;
$topnav-logo-height__medium: 48px;
$topnav-logo-top__medium: -8px;
$topnav-logo-margin__medium: $column-gutter/2 $column-gutter/2 12px $column-gutter/2;

// Top Nav - Header
// $topnav-header-bg: $topnav-bg;

// Top Nav - Dropdown
$topnav-dropdown-padding: 30px $column-gutter/2;
$topnav-dropdown-close-padding__medium: 20px $column-gutter;
// $topnav-dropdown-bg-color: $white;
// $topnav-dropdown-close-font-size: 19px;
// $topnav-dropdown-item-color: get-color(primary);
// $topnav-dropdown-close-color: get-color(primary);
$topnav-dropdown-l1-active-bg: transparent;
$topnav-dropdown-promo-padding-top: 53px;
//$topnav-dropdown-promo-padding-bottom__large: 30px;

// Top Nav - Main Nav
$topnav-mainnav-l1-height: 47px; // to make dropdown border align with header border
// $topnav-mainnav-more-dropdown-width: 250px;
// $topnav-mainnav-more-dropdown-link-color: $topnav-dropdown-item-color;
// $topnav-mainnav-more-dropdown-link-font-size: 15px;
// $topnav-mainnav-more-dropdown-link-line-height: $topnav-mainnav-more-dropdown-link-font-size + 2;
// $topnav-mainnav-more-dropdown-close-font-size: 19px;

// $topnav-mainnav-more-dropdown-close-padding: 11px 10px;

$topnav-mainnav-more-dropdown-close-padding__medium: 6px 6px 7px 7px;
//$topnav-mainnav-more-dropdown-close-padding__large: null;
$topnav-mainnav-more-dropdown-close-right__medium: 10px;
//$topnav-mainnav-more-dropdown-close-right__large: null;
$topnav-mainnav-more-dropdown-close-top__medium: 5px;
//$topnav-mainnav-more-dropdown-close-top__large: null;

// $topnav-mainnav-more-dropdown-link-padding: 8px 0;
$topnav-mainnav-more-dropdown-padding: 5px $column-gutter/2;
// $topnav-mainnav-more-dropdown-bg: $topnav-dropdown-bg-color;

// Top Nav - Search
$topnav-search-container-padding: 8px $column-gutter/2;
$topnav-search-container-padding__medium: 16px 10px 0;
$new-topnav-search-container-padding__medium: 0 !important;
// $topnav-searchbutton--color: get-color(primary);
$topnav-search-button-text-font-size: 16px;
// $topnav-search-button-text-font-size__medium: null;
// $topnav-search-button-text-font-family: null;
$topnav-search-button-text-font-weight: bold;
$topnav-search-button-height: 34px;
$topnav-search-button-text-placeholder-color: $black;
// $topnav-search-button-icon-color: $input-font-color;
// $topnav-search-button-height__medium: $topnav-search-button-height;
// $topnav-search-button-padding__medium: 5px;
// $topnav-search-button-bg: $input-background;
// $topnav-search-border-color: get-border-value($input-border, 'color');
$topnav-search-container-width__medium: 20%;
$topnav-search-container-max-width__medium: em-calc(170);
$topnav-search-menu-border: 1px solid $light-gray;
$topnav-search-clear-button-background: $white;
$topnav-search-clear-button-background: transparent;
$topnav-search-clear-button-border: 0;

//  Bloomreach
$brCarouselCycleWrapDisplay: flex;
$brCarouselCycleWrapFlexDirection: row;
$brCarouselCycleWrapWhiteSpace: normal !important;
$brCarouselSearchMerchContZIndex: 0 !important;
$brMobileCarouselArrowNextTextShadow: rem-calc(2) 0 0 get-color(white-smoke);
$brMobileCarouselArrowPrevTextShadow: rem-calc(-2) 0 0 get-color(white-smoke);

// Top Nav - Accordion
// $topnav-accordion--background: $white;
$topnav-accordion--font-size: rem-calc(16px);
$topnav-accordion--font-size__medium: $topnav-accordion--font-size;
$topnav-accordion--color__medium: get-color(primary);
$topnav-accordion-item-color-active__medium: get-color(primary);
// $topnav-accordion--item-active-bg__medium: null;
// $topnav-search-container-max-width__medium: 15em; // This is a fallback in case the search SCSS is not loaded before the accordion
$topnav-accordion-item--border: 1px solid $light-gray;
$topnav-accordion-item--height: 40px;
// $topnav-accordion-item--indent: 0; // Additional indent offset for each level
$topnav-accordion-item--line-height: 18px;
// $topnav-accordion-item--line-height__medium: 13px;
$topnav-accordion-item--padding-base: 15px;
$topnav-accordion-item--padding__medium: 16px 10px;
// $topnav-accordion-indicator--height: 0.5em;
$topnav-accordion-indicator--arrow-icon: $icon-arrow-down-indicator;
// $topnav-accordion-shopall-indicator--spacing: 1em;
$topnav-accordion-l2--font-size: 14px;
// $topnav-accordion-l2--line-height: 17px;
// $topnav-accordion-l2--font-family__medium: null;
//$topnav-accordion-l2--margin-bottom__medium: 0;
$topnav-accordion-l2--font-size__medium: 13px;
$topnav-accordion-l2--padding__medium: 0 0 16px 0;
// $topnav-accordion-l2--font-weight__medium: 700;
// $topnav-accordion-l2--line-height__medium: 15px;
$topnav-accordion-l3--font-size: 12px;
// $topnav-accordion-l3--line-height: 16px;
$topnav-accordion-l3--font-size__medium: 13px;
$topnav-accordion-l3--font-weight__medium: 700;
// $topnav-accordion-l3--line-height__medium: $topnav-accordion-l2--line-height__medium;
// $topnav-accordion-l3--font-family__medium: null;
$topnav-accordion-vertical-override: true;
$topnav-accordion-l3--vertical-padding: 10px;
$topnav-accordion-item-l1-shopall-padding-top: 17px;
$topnav-accordion-item-l1-shopall-padding-bottom: 16px;
$topnav-accordion-item-l2-shopall-padding-top: 10px;
$topnav-accordion-item-l2-shopall-padding-bottom: 10px;
//$topnav-accordion-indicator-font-size: null;
$new-napa-design-font-family: get-font('AkkuratPro');
$about-us-mega-menu-dropdown-font-family: get-font('AkkuratPro');
$topnav-accordion-shopall--font-size: 12px;
$topnav-accordion-shopall--font-weight: 700;
$topnav-accordion-shopall--line-height: 15px;
$topnav-accordion-shopall--font-size__medium: 12px;
//$topnav-accordion-shopall--font-weight__medium: $topnav-accordion-l2--font-weight__medium;
//$topnav-accordion-shopall--line-height__medium: $topnav-accordion-l2--line-height__medium;
$topnav-accordion-shopall--margin-bottom__medium: 18px;
$topnav-accordion-l3--margin-bottom: 10px;
$topnav-accordion-l3--margin-bottom__medium: rem-calc(1);
$topnav-accordion-border-top: $topnav-accordion-item--border;
$topnav-accordion-border-top__medium: 0;
$topnav-accordion-margin__medium: 23px 0 0 13px;
$topnav-accordion-search-padding: 51px;
$topnav-dropdown-imagegrid-caption-font-size__med: rem-calc(16);

// l1 active border
$topnav-accordion-l1-active-borders: true;
$topnav-accordion-l1-active-border-weight__medium: 2px;
$topnav-accordion-l1-active-border-position-bottom__medium: -3px;
$topnav-accordion-l1-active-border-initial-color__medium: transparent;
$topnav-accordion-l1-active-border-duration__medium: $anchor-transition-speed;
$topnav-accordion-l1-active-border-color__medium: get-color(napa-orange);
$topnav-accordion-l1-active-border-position-bottom__large: null;

// Top Nav - Utility Nav
$topnav-utility-item--font-size: 10px;
$topnav-utility-item--font-size__medium: 10px;
$topnav-utility-item--height: 1em;
// $topnav-utility-item--line-height: $topnav-utility-item--height;
$topnav-utility-item--padding: 12px;
// $topnav-utility-item--indent: $topnav-accordion-item--padding-base - $topnav-utility-item--padding; // Indent + Padding should equal the main L1 padding for alignment
$topnav-utility-item--icon-width: 15px;
$topnav-utility-item--icon-spacer: 15px;
$topnav-utility-item--icon-spacer__medium: 3px;
// $topnav-utility-item-padding--medium: 0;
$topnav-utility-item-height--medium: 43px;
// $topnav-utility-item-width--medium: 30px;
$topnav-utility-item-icon-font-size: 13px;
$topnav-utility-item-icon-font-size--medium: 11px;
$topnav-utility-item-height--large: $topnav-utility-item-height--medium;
$topnav-utility-item-separation-padding--large: 13px;
$topnav-utility-item-color: get-color(eclipse);
$topnav-utility-item-color-hover: $black;

// Top Nav - Small Header
$topnav-smallheader-item-color: $topnav-utility-item-color;
$topnav-smallheader-item-color-hover: $topnav-utility-item-color-hover;

// Top Nav - Utility Nav - Flag
$topnav-utility-item--flag-line-height__medium: $topnav-utility-item-height--medium;
$topnav-utility-item--flag-line-height__large: $topnav-utility-item-height--large;
// $topnav-utility-item--flag-label-padding: em-calc(2px, $topnav-utility-item--font-size);
// $topnav-utility-item--flag-link-color: null;
// $topnav-utility-item--flag-link-color-hover: null;
// $topnav-utility-item--flag-link-padding__medium: 0;
$topnav-utility-item--flag-width: 19px;
$topnav-utility-item--flag-width__medium: 15px;
$topnav-utility-item-flag-position: 0;

// Top Nav - Cart
$topnav-cart-height: auto;
// $topnav-cart-width: $topnav-button-width; // Cart can be wider than the other buttons due to the counter
$topnav-cart-qty--size: 2em;
$topnav-cart-qty--color: inherit;
$topnav-cart-qty--bg: transparent;
// $topnav-cart-height__medium: $topnav-cart-height;
$topnav-cart-icon-font-size__medium: $topnav-utility-item-icon-font-size--medium;
$topnav-cart-separator-padding__medium: 5px;
$topnav-cart-icon-padding__medium: 0 $topnav-cart-separator-padding__medium;
// $topnav-cart-label--font-size: $topnav-utility-item--font-size;
// $topnav-cart-padding-right__medium: 0 $column-gutter/2 0 0;
// $topnav-cart-padding-top__medium: 11px;
$topMiniCartBackgroundColor: $white;
$topMiniCartNotificationBackgroundColor: transparent;
$topMiniCartCartBorder: 1px solid $light-gray;
$topMiniCartItemColPaddingLeft: 0;
$topMiniCartItemColPaddingRight: 0;
$topPanelBorderTop: $topMiniCartCartBorder;
$miniCartCloseIconDisplay: none;
$miniListNotificationMobileBkgdColor: get-color(white-smoke);
$miniCartBalanceMarginBottom: rem-calc(11);
$miniCartBalanceTextColor: get-color(napa-orange);
$miniCartBalanceFontSize: rem-calc(13);
$checkout-balance-container-message-color: get-color(napa-orange);

//Top nav carousel
$carousel-mobile-slide-image-width: rem-calc(80);
//leave the carousel image height to auto in order for the image to stretch to the appropriate height to preserve the aspect ratio (this may be restricted if necessary)
$carousel-mobile-slide-image-height: rem-calc(54);
$carousel-mobile-slides-min-horizontal-spacing: rem-calc(5);
$carousel-mobile-slide-total-width: $carousel-mobile-slide-image-width + $carousel-mobile-slides-min-horizontal-spacing;
$carousel-mobile-margin: rem-calc(16) 0 rem-calc(16) rem-calc(16);
$carousel-mobile-slide-padding-left: rem-calc(0); // set the carousel left offset and calculate the right
//$carousel-mobile-slide-padding-right: $carousel-mobile-slide-total-width - $carousel-mobile-slide-padding-left;
$carousel-mobile-slide-padding-right: $carousel-mobile-slides-min-horizontal-spacing;
$carousel-mobile-slide-padding-top: null !default;
$carousel-mobile-slide-padding-bottom: null !default;
$carousel-mobile-slide-inter-paragraph-vertical-spacing: rem-calc(4);
$carousel-mobile-slide-paragraph-font-family: get-font(AkkuratPro);
$carousel-mobile-slide-paragraph-font-size: rem-calc(8);
$carousel-mobile-slide-paragraph-line-height: rem-calc(16);
$carousel-mobile-slide-paragraph-font-weight: 700;
$carousel-mobile-slide-paragraph-margin-bottom: 0;
$carousel-mobile-slide-paragraph-text-align: center;
$carousel-mobile-slide-paragraph-text-transform: capitalize;
$carousel-mobile-carouselimg-width: $carousel-mobile-slide-image-width;
$carousel-mobile-carouselimg-max-width: null !default;
//$carousel-mobile-carouselimg-max-width: $carousel-mobile-slide-image-width;
$carousel-mobile-carouselimg-height: $carousel-mobile-slide-image-height;
$carousel-mobile-carouselimg-object-fit: cover;
$carousel-mobile-carouselimg-transform: null !default;
//$carousel-mobile-carouselimg-transform: translate3d(0, 0, 0); //could be used if the images appear too pixalated
$carousel-mobile-width: 100%;
$carousel-mobile-slide-width: $carousel-mobile-slide-image-width + $carousel-mobile-slides-min-horizontal-spacing;
$carousel-mobile-slide-wrapper-left: 0 !important;

$carousel-mobile-nav-button-border-radius: 0;
$carousel-mobile-nav-button-margin-top: 0;
$carousel-mobile-nav-button-top: 0;
$carousel-mobile-nav-button-height: 100%;
$carousel-mobile-nav-button-background: transparent;
$carousel-mobile-nav-button-background-hover: transparent;
$carousel-mobile-nav-button-icon: '';
$carousel-mobile-vertical-overflow: auto !important;
$carousel-mobile-wrapper-margin-right: null !default;
$carousel-container-overlay-arrow-color-hover: transparent;
$carousel-container-overlay-arrow-background-hover: transparent;
$carousel-container-overlay-arrow-opacity-hover: 0;

// My Account Registration
$myAccountBalanceContainerColor: get-color(napa-orange);

// Top Nav - Promo Bar
//$topnav-promobar--height: 30px !default;
$topnav-promobar--bgColor: $white;
$topnav-promobar--textColor: $black;
//$topnav-promobar--linkColor: inherit;
//$topnav-promobar--linkDecoration: none;
$topnav-promobar--fontSize: rem-calc(10px);
$topnav-promobar--medium-fontSize: rem-calc(11px);
$topnav-promobar--large-fontSize: $topnav-promobar--medium-fontSize;
//$topnav-promobar--lineHeight: 1.1em !default;
//$topnav-promobar--medium-lineHeight: $topnav-promobar--lineHeight !default;
//$topnav-promobar--large-lineHeight: $topnav-promobar--medium-lineHeight !default;
$topnav-promobar--zIndex: auto;

//Sticky nav
$include-html-top-nav-classes: true;
$topnav-sticky-minimized-border: 1px solid $light-gray;
$topnav-sticky-minimized--height__small: rem-calc(43);
$topnav-sticky-minimized--height__medium: rem-calc(43);
$topnav-sticky-minimized-accordion--margin__medium: 0 0 -6px 13px;
$topnav-sticky-minimized-logo--top__medium: 15px;
$topnav-sticky-minimized-l1-link--padding__medium: 20px 6px 15px 6px;
$topnav-sticky-minimized-l1-link--padding__large: $topnav-sticky-minimized-l1-link--padding__medium;
$topnav-sticky-minimized-search-container--padding__medium: 9px 4px;
$topnav-sticky-minimized-search-container--padding__large: $topnav-sticky-minimized-search-container--padding__medium;

// Topnav Animations
$topnav-animate-utility-item-max-width: 100px;
$topnav-animate-mainnav-height-medium-minimized: 55px;
$topnav-animate-mainnav-height-medium-maximized: 115px;
$topnav-animate-mainnav-height-large-minimized: 55px;
$topnav-animate-mainnav-height-large-maximized: 115px;

$colorSwatchSize: rem-calc(32);
$colorSwatchSizeMedium: rem-calc(26);

///////////////////////////////////////////////////
// Auto suggest
//file location : _shared/sass/widgets/_autocomplete.scss
///////////////////////////////////////////////////
$search-autosuggest-container-shadow: 1px 2px 6px rgba($black, 0.3);
$search-autosuggest-container-position-left-medium: auto;
$search-autosuggest-container-position-right-medium: 0;
// $search-autosuggest-text-color-highlight: get-color(napa-orange);

// Autosuggest Expanded View
$search-autosuggest-expanded-img-width-medium: 145px;
$search-autosuggest-expanded-img-width-large: 125px;
$search-autosuggest-expanded-img-width-xlarge: 135px;
$search-autosuggest-expanded-suggestion-color-hover: get-color(primary);
$search-autosuggest-expanded-suggestion-decoration: none;
$search-autosuggest-expanded-suggestion-decoration-hover: underline;
$search-autosuggest-expanded-suggestion-weight: 200;
$search-autosuggest-expanded-suggestions-title-margin-medium: 0 0 20px;
$search-autosuggest-expanded-price-current-color: get-color(napa-orange);
$search-autosuggest-expanded-products-border-large: 1px solid $dark-gray;
$search-autosuggest-expanded-products-item-align-medium: start;
$search-autosuggest-expanded-products-item-width-medium: 300px;
$search-autosuggest-expanded-products-margin-medium: 0 20px;
$search-autosuggest-expanded-title-margin-bottom-medium: 20px;
$search-autosuggest-expanded-query-display: none;
$search-autosuggest-overlay-top: 55px;


//---------------------------------------------------------------
// CMS Media Block component
//---------------------------------------------------------------
$media-block-video-position: absolute;

//---------------------------------------------------------------
// CMS Hover Caption component
//
// Displays captions on images via a hover behavior
//---------------------------------------------------------------

// $hover_caption_transition_duration: 0.25s;
// $hover_caption_overlay_color: $black;
// $hover_caption_overlay_font_color: $white;
// $hover_caption_overlay_font_family: $header-font-family;
// $hover_caption_overlay_font_size: $global-font-size;
// $hover_caption_overlay_font_weight: null;
// $hover_caption_overlay_text_transform: normal;


//---------------------------------------------------------------
// CMS Image Overlay component
//
// Displays an image with a content box overlaid
//---------------------------------------------------------------

// $image_overlay_inset_small: 5%;
// $image_overlay_inset_medium: $image_overlay_inset_small * 2;
// $image_overlay_inset_large: $image_overlay_inset_medium * 2;
// $image_overlay_padding: $column-gutter;
// $image_overlay_background: $body-background;
// $image_overlay_color: $body-font-color;
// $image_overlay_headline_color: $header-color;
// $image_overlay_text_align: left;


//---------------------------------------------------------------
// CMS Previous / Next Bar
//
// Displays a previous next bar for articles
//---------------------------------------------------------------

// $prev_next_border_top: null;
// $prev_next_border_bottom: null;
// $prev_next_row_background: null;
// $prev_next_row_padding: null;
// $prev_next_link_height_desktop: 50px;
// $prev_next_link_height_mobile: 100px;
// $prev_next_arrow_color: null;
// $prev_next_arrow_color_hover: null;
// $prev_next_arrow_font: 'icomoon';
// $prev_next_arrow_font_size: 1em;
// $prev_next_arrow_width: 1em;
// $prev_next_link_previous_arrow_code: $icon-arrow-left-indicator-open;
// $prev_next_link_previous_mobile_border: null; // TNF
// $prev_next_link_next_arrow_code: $icon-arrow-right-indicator-open;
// $prev_next_link_use_arrow_borders: false; // Vans
// $prev_next_link_previous_arrow_border_padding: null; // Vans
// $prev_next_link_next_arrow_border_padding: null; // Vans
// $prev_next_link_arrow_border: null; // Vans
// $prev_next_link_image_margin_inside: 1em;
// $prev_next_link_image_margin_outside: 1em;
// $prev_next_middle_border_left: null;
// $prev_next_middle_border_right: null;
// $prev_next_middle_border_top_on_mobile: null;


//---------------------------------------------------------------
// CMS Product Thumbnails
//
// Displays an product thumbnail with details
//---------------------------------------------------------------

// $product_teaser_image_padding: null;
// $product_teaser_image_margin: null;
// $product_teaser_info_padding: null;
$product_teaser_info_margin: 15px 0;
// $product_teaser_text_align: center;
// $product_teaser_title_font_family: $header-font-family;
$product_teaser_title_font_weight: normal;
// $product_teaser_title_color: $header-color;
$product_teaser_title_font_size: em-calc(12);
// $product_teaser_title_line_height: $header-lineheight;
// $product_teaser_title_letter_spacing: inherit;
// $product_teaser_title_top_margin: $header-margin-bottom;
$product_teaser_title_bottom_margin: 0.75em;
// $product_teaser_title_text_transform: null;
// $product_teaser_swatch_height: 25px;
// $product_teaser_swatch_gutter: 3px;
// $product_teaser_swatch_count: 5;
// $product_teaser_swatch_more_font_family: $header-font-family;
// $product_teaser_swatch_more_font_weight: $header-font-weight;
// $product_teaser_swatch_more_color: $header-color;
// $product_teaser_swatch_more_font_size: inherit;
// $product_teaser_swatch_more_letter_spacing: inherit;
// $product_teaser_swatch_more_border: 1px solid $body-font-color;
// $product_teaser_swatch_more_background: $white;
// $product_ratings_color: get-color(primary);
$product_ratings_color: $black;
// $product_ratings_off_color: get-color(secondary);
$product_ratings_off_color: $light-gray;
// $product_ratings_size: 1.2em;
$product_ratings_size: 1.5em;
// $product_ratings_spacing: -4px; // Fine-tune the gap between stars
// $product_ratings_line-height: null;
// $product_teaser_eyebrow_font_family: null;
// $product_teaser_eyebrow_font_weight: null;
// $product_teaser_eyebrow_color: null;
// $product_teaser_eyebrow_font_size: null;
// $product_teaser_eyebrow_line_height: null;
// $product_teaser_eyebrow_letter_spacing: null;
// $product_teaser_eyebrow_text_transform: null;
// $product_teaser_promo_font_family: inherit;
// $product_teaser_promo_color: inherit;
// $product_teaser_promo_font_size: inherit;
// $product_teaser_promo_line_height: inherit;
// $product_teaser_promo_letter_spacing: inherit;
// $product_teaser_price_font_family: inherit;
// $product_teaser_price_color: inherit;
// $product_teaser_price_font_size: inherit;
// $product_teaser_price_font_weight: null;
// $product_teaser_price_line_height: inherit;
// $product_teaser_price_letter_spacing: inherit;
// $product_teaser_price_previous_font_family: null;
// $product_teaser_price_previous_color: null;
// $product_teaser_price_previous_font_size: null;
$product_teaser_price_previous_font_weight: bold;
// $product_teaser_price_previous_line_height: null;
// $product_teaser_price_previous_letter_spacing: null;
// $product_teaser_price_previous_text-decoration: line-through;
// $product_teaser_price_current_font_family: null;
// $product_teaser_price_current_color: null;
// $product_teaser_price_current_font_size: null;
$product_teaser_price_current_font_weight: bold;
// $product_teaser_price_current_line_height: null;
// $product_teaser_price_current_letter_spacing: null;
// $product_teaser_price_compare_font_family: null;
$product_teaser_price_compare_color: get-color(burnt-orange);
// $product_teaser_price_compare_font_size: null;
$product_teaser_price_compare_font_weight: normal;
// $product_teaser_price_compare_line_height: null;
// $product_teaser_price_compare_letter_spacing: null;
// $product_teaser_qty_font_family: inherit;
// $product_teaser_qty_color: inherit;
// $product_teaser_qty_font_size: inherit;
// $product_teaser_qty_line_height: inherit;
// $product_teaser_qty_letter_spacing: inherit;
// $product_teaser_mobile_quickshop-button-font-size: null;
// $product_teaser_badge_vertical_align: top;
// $product_teaser_badge_horizontal_align: right;



//---------------------------------------------------------------
// CMS Email Signup
//
// Displays an email signup form
//---------------------------------------------------------------

// $email_signup_form_email_input_text_align_mobile: center;
// $email_signup_form_email_input_text_align_tablet_up: left;
// $email_signup_form_background_color: null;
// $email_signup_form_max_width: null;
// $email_signup_form_padding: 1em;
// $email_signup_form_submit_button_background_color: null;
// $email_signup_form_submit_button_background_color_hover: null;
$email_signup_form_submit_button_border: 0;
// $email_signup_form_submit_button_border_hover: null;
// $email_signup_form_submit_button_font_color: null;
// $email_signup_form_submit_button_font_color_hover: null;
// $email_signup_form_submit_button_before_content: null;
// $email_signup_form_submit_button_before_font_family: null;
// $email_signup_form_submit_button_after_content: null;
// $email_signup_form_submit_button_after_font_family: null;
// $email_signup_form_label_font_family: null;
$email_signup_form_label_font_color: $black;
$email_signup_form_label_font_size: em-calc(16px);
// $email_signup_form_label_font_weight: null;
// $email_signup_form_label_text_transform: null;
// $email_signup_form_label_text_align_mobile: center;
// $email_signup_form_label_text_align_tablet: right;
// $email_signup_form_label_text_align_desktop: right;
// $email_signup_form_label_letter_spacing: null;
// $email_signup_form_text_input_background_color: null;
// $email_signup_form_text_input_border: null;
$email_signup_form_text_input_font_color: $black;
// $email_signup_form_alert_box_margin: 1.5em auto 0;
// $email_signup_form_input_button_gutter_percent: 0; // Can be used as a fallback for gutter width below
// $email_signup_form_input_button_gutter_width: 0;
$email_signup_form_input_height: 36px;
// $email_signup_form_input_border_right: null;
// $email_signup_form_button_border_left: null;


//---------------------------------------------------------------
// CMS Article Teaser Grid
//
// Displays a grid of article teasers with thumnbails
//---------------------------------------------------------------
$article-teaser-filters-align: center;
$article-teaser-filters-border: 1px solid $medium-gray;
//$article-teaser-filters-display: inline;
//$article-teaser-filters-font-size: null;
$article-teaser-filters-item-color: $black;
$article-teaser-filters-item-color-hover: get-color(burnt-orange);
$article-teaser-filters-item-color-active: get-color(burnt-orange);
$article-teaser-filters-item-padding: 0 1.1em;
$article-teaser-filters-margin-bottom: 29px;
$article-teaser-filters-margin-top: 29px;
$article-teaser-filters-padding-bottom: 10px;
$article-teaser-filters-padding-top: 10px;
//$article-teaser-filters-seperator-color: null;
//$article-teaser-filters-seperator-content: null;
//$article-teaser-filters-seperator-enabled: false;
//$article-teaser-filters-seperator-padding: null;
//$article-teaser-filters-text-transform: uppercase;


//---------------------------------------------------------------
// CMS Article Teaser
//
// Article preview with thumnbails
//---------------------------------------------------------------
// $articleTeaserPaddingBottom: em-calc(20);
// $articleTeaserTextAlign: null;
// $articleTeaserVerticalAlign: null;
// $articleTeaserImageMarginBottom: null;
$articleTeaserDetailsHideOnSmall: true;
// $articleTeaserDetailsColor: null;
$articleTeaserDetailsFontSize: em-calc(14);
$articleTeaserDetailsLineHeight: 1.5;
// $articleTeaserDetailsMargin: null;
// $articleDetailCaptionColor: $body-font-color;
// $articleDetailCaptionFontFamily: null;
// $articleDetailCaptionFontSize: null;
// $articleDetailCaptionFontSizeMedium: null;
// $articleDetailCaptionFontWeight: null;
$articleDetailCaptionLineHeight: 1.25;
// $articleDetailCaptionMargin: em-calc(15) 0;
// $articleDetailCaptionTransform: null;
$articleTeaserReadMoreHideOnSmall: true;
$articleTeaserReadMoreFontSize: em-calc(14);


//---------------------------------------------------------------
// CMS Content List
//
// Displays a list of articles suitable for a sidebar
//---------------------------------------------------------------
// $contentlist_margin: $column-gutter 0 $column-gutter 0;
// $contentlist_list_style: none;


//---------------------------------------------------------------
// CMS Search Results
//
// Displays a list of articles in a grid as search results
//---------------------------------------------------------------

// Tabs
$include-html-search-results-tabs-classes: true;
$searchTabBackground: get-color(white-smoke);
// $searchTabFont: null;
// $searchTabFontColor: null;
$searchTabFontWeight: 700;
$searchTabBorderActive: 4px solid get-color(burnt-orange);
$searchTabBorderDefault: 4px solid transparent;

//---------------------------------------------------------------
// Garnish for AEM components
//---------------------------------------------------------------
// $garnish-full-width-top-padding: null !default;
// $garnish-full-width-bottom-padding: null !default;
// $garnish-full-width-top-margin: null !default;
// $garnish-full-width-bottom-margin: null !default;
// $garnish-full-width-top-padding-medium-and-up: null !default;
// $garnish-full-width-bottom-padding-medium-and-up: null !default;
// $garnish-full-width-top-margin-medium-and-up: null !default;
// $garnish-full-width-bottom-margin-medium-and-up: null !default;
// $garnish-full-width-top-padding-large-and-up: null !default;
// $garnish-full-width-bottom-padding-large-and-up: null !default;
// $garnish-full-width-top-margin-large-and-up: null !default;
// $garnish-full-width-bottom-margin-large-and-up: null !default;

//---------------------------------------------------------------
// CMS Carousel Controls
//
// Global shared styles for CMS Carousels
//---------------------------------------------------------------
//
// -------- Overlay Arrows --------------------------------------
$carousel-overlay-arrow-size: em-calc(16);
$carousel-overlay-arrow-color: $header-color;
$carousel-overlay-arrow-background: transparent;
$carousel-overlay-arrow-color-hover: $white;
$carousel-overlay-arrow-background-hover: transparent;
$carousel-overlay-arrow-width: 2 * $column-gutter;
$carousel-overlay-arrow-height: $carousel-overlay-arrow-width;
$carousel-overlay-arrow-opacity: 1 !default;
$carousel-overlay-arrow-opacity-hover: 1 !default;
//
// -------- Arrows that display below the carousel --------------
$carousel-lower-arrow-size: em-calc(16);
$carousel-lower-arrow-color: $header-color;
$carousel-lower-arrow-color-hover: null;
$carousel-lower-arrow-padding: 0;
$carousel-lower-arrow-background: null;
$carousel-lower-arrow-background-hover: null;
$carousel-lower-container-margin: 0 $column-gutter/2;
//
// -------- Pager that displays below the carousel --------------
$carousel-pager-indicator-size: em-calc(8);
$carousel-pager-indicator-margin: em-calc(15) em-calc(8);
$carousel-pager-indicator-border-radius: 50%;
$carousel-pager-indicator-background: none;
$carousel-pager-indicator-border: 1px solid $black;
$carousel-pager-indicator-active-background: get-color(burnt-orange);
$carousel-pager-indicator-active-border: 1px solid get-color(burnt-orange);
$carousel-pager-indicator-hover-background: $medium-gray;
$carousel-pager-indicator-hover-border: 1px solid get-color(burnt-orange);
//---------------------------------------------------------------
// CMS Product Carousel
//
// Displays products in a carousel
//---------------------------------------------------------------
//
// -------- Overlay Arrows --------------------------------------
// $product-carousel-overlay-arrow-size: $carousel-overlay-arrow-size;
// $product-carousel-overlay-arrow-color: $carousel-overlay-arrow-color;
// $product-carousel-overlay-arrow-background: $carousel-overlay-arrow-background;
// $product-carousel-overlay-arrow-color-hover: $carousel-overlay-arrow-color-hover;
// $product-carousel-overlay-arrow-background-hover: $carousel-overlay-arrow-background-hover;
// $product-carousel-overlay-arrow-width: $carousel-overlay-arrow-width;
// $product-carousel-overlay-arrow-height: $carousel-overlay-arrow-height;
// $product-carousel-overlay-arrow-opacity: $carousel-overlay-arrow-opacity;
// $product-carousel-overlay-arrow-opacity-hover: $carousel-overlay-arrow-opacity-hover;
//
// -------- Product Styles --------------------------------------
// $product-carousel-product-wrapper-padding: 0 $column-gutter/2;


//---------------------------------------------------------------
// CMS Carousel Container
//
// Displays complex CMS slides in a carousel
//---------------------------------------------------------------
//
// -------- Overlay Arrows --------------------------------------
// $carousel-container-overlay-arrow-size: $carousel-overlay-arrow-size;
// $carousel-container-overlay-arrow-color: $carousel-overlay-arrow-color;
// $carousel-container-overlay-arrow-background: $carousel-overlay-arrow-background;
// $carousel-container-overlay-arrow-color-hover: $carousel-overlay-arrow-color-hover;
// $carousel-container-overlay-arrow-background-hover: $carousel-overlay-arrow-background-hover;
// $carousel-container-overlay-arrow-width: $carousel-overlay-arrow-width;
// $carousel-container-overlay-arrow-height: $carousel-overlay-arrow-height;
// $carousel-container-overlay-arrow-opacity: $carousel-overlay-arrow-opacity;
// $carousel-container-overlay-arrow-opacity-hover: $carousel-overlay-arrow-opacity-hover;
//
// -------- Arrows that display below the carousel --------------
// $carousel-container-lower-arrow-size: $carousel-lower-arrow-size;
// $carousel-container-lower-arrow-color: $carousel-lower-arrow-color;
// $carousel-container-lower-arrow-color-hover: $carousel-lower-arrow-color-hover;
// $carousel-container-lower-arrow-padding: $carousel-lower-arrow-padding;
// $carousel-container-lower-arrow-background: null;
// $carousel-container-lower-arrow-background-hover: null;
// $carousel-container-lower-container-margin: 0 $column-gutter/2;
//
// -------- Pager that displays below the carousel --------------
// $carousel-container-pager-indicator-size: $carousel-pager-indicator-size;
// $carousel-container-pager-indicator-margin: $carousel-pager-indicator-margin;
// $carousel-container-pager-indicator-border-radius: $carousel-pager-indicator-border-radius;
// $carousel-container-pager-indicator-background: $carousel-pager-indicator-background;
// $carousel-container-pager-indicator-border: $carousel-pager-indicator-border;
// $carousel-container-pager-indicator-active-background: $carousel-pager-indicator-active-background;
// $carousel-container-pager-indicator-active-border: $carousel-pager-indicator-active-border;
// $carousel-container-pager-indicator-hover-background: $carousel-pager-indicator-hover-background;
// $carousel-container-pager-indicator-hover-border: $carousel-pager-indicator-hover-border;

//---------------------------------------------------------------
// Delivery Tile Wrapper Component
//
// Creates container/tiles for delivery options on delivery/shipping page
//---------------------------------------------------------------
$include-delivery-tile-wrapper-classes: true;
$delivery_tile_border_color: $light-gray;
$delivery_tile_text_color: $light-gray;
$delivery_tile_border_color_active: get-color(burnt-orange);
$delivery_tile_bg_color: transparent;

$topPanelEnabled: true;

//---------------------------------------------------------------
// Single Category Tile Component
//
// Displays categories as single title
//---------------------------------------------------------------

// $single_category_title_font_family: null !default;
// $single_category_title_font_weight: null !default;
// $single_category_title_color: null !default;
// $single_category_title_font_size: null !default;
// $single_category_title_line_height: null !default;
// $single_category_title_letter_spacing: null !default;
// $single_category_title_top_margin: null !default;
// $single_category_title_bottom_margin: null !default;
// $single_category_title_text_transform: null !default;


//------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------
// WCS VARIABLES
//------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------

//background and accent colors
$pageBackground: $body-background;
// $moduleBackground: $white;
// $hairlineRules: get-color(feta);
// $dashedRules: get-color(feta);
// $filterHairlineRules: get-color(regent-st-blue);
// $blueHighlight: get-color(alice-blue);
// $errorHighlight: get-color(wisp-pink);
// $successHighlight: get-color(white-ice);
// $genericHighlight: get-color(black-squeeze);
// $womensPageBG: get-color(white-smoke);
// $productReview: get-color(elm);

//text color palette
$defaultText: get-color(primary);
// $negativeText: $white;
// $negativeLightText: get-color(nobel);
// $errorText: $input-error-color; // replace $errorText globally with Foundation's $input-error-color
// $hyperlinkText: get-color(dark-tangerine);
// $lightHyperlinkText: get-color(golden-tainoi);
// $promoText: get-color(orange-red);
// $breadcrumbHyperLinkHover: $brandColorPalette2;

$fontInvert: $white;
$fontInvertLight: $light-gray;
$fontGrey: $medium-gray;
$fontGreyLight: $light-gray;
$fontGreyDark: get-color(eclipse);
$fontDark: $black;
$fontLink: $anchor-color;
$fontLinkHover: $anchor-color-hover;
// $fontFilterLinkHover: $white;
// $fontButtonLink: $white;
// $fontButtonLinkHover: $white;
// $fontPdpDrawerProHeadline: get-color(dark-tangerine);
// $fontPdpDrawerProBtnPrimary: get-color(aubergine);
// $fontPdpPrice: $darkText;
// $currentPriceFontColor: get-color(cinnabar);
// $offerPriceColor: $darkText;

// $fontError: get-color(alizarin);
// $fontInfo: get-color(lily-white);
// $fontGeneric: get-color(night-rider);
$fontSuccess: get-color(burnt-orange);
// $fontWarning: get-color(lily-white);

//---------------------------------------------------------------
// Account - Overview
//---------------------------------------------------------------
// $iconQuestion: $icon-help !default;
// $iconQuestionColor:  inherit !default;
// $checkoutIconQuestionPadding: 5px !default;
// $ccOverviewIcon: url('') !default;

//---------------------------------------------------------------
// Product Block
//---------------------------------------------------------------

// Product Block Promo Badge
$productBlockPromoBadgeWidth: fit-content;
$productBlockPromoBadgeWidthTablet: fit-content;
$productBlockPromoBadgeWidthMobile: fit-content;
$productBlockPromoBadgeAlign: left;
$productBlockPromoBadgeTop: rem-calc(15);
// $productBlockPromoBadgeBottom: auto;
$productBlockPromoBadgeLeft: 0;
// $productBlockPromoBadgeRight: 5px;
// $productBlockInfoHeight: auto;
// $productBlockStyleNumberFontWeight: null;

// Product block promo eyebrow
// $productBlockPromoEyebrowTop: -30px;
// $productBlockPromoEyebrowAlign: center;

$productInfoPromoEyebrowFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-size': convert-to-em(13px),
    'font-weight': bold,
    'color': $medium-gray,
    'text-transform': lowercase
  )
);

// Product Block Name
// $productBlockNameHeight: auto;

// Product Block Rating
// $ratingMargins: 0;
// $ratingContainerHeight: auto;

// Product Block Dismensions
// $productBlockDesktopHeight: 360px;
// $productColumnsInBlock: 4;
// $productColumnsInBlockTablet: 6;
// $productColumnsInBlockMobile: 6;

// Product Block Styling
// $productBlockTextAlign: left;
// $productLanesLayoutEnabled: false;
// $productBlockBorderHover: 0;
$promoBadgeEnabled: true;
// $infoBadgeEnabled: false;
// $promoEyebrowEnabled: false;
// $promoPriceEnabled: false;
// $pricingEnabled: false;

$productColumnsInLane: 4;
// $productColumnsInLaneTablet: 4;
// $productColumnsInLaneMobile: 6;

// Quick shop button
// $quickshopBtnFontSize: 15px;
// $quickshopBtnWidth: 60%;
// $quickshopBtnTop: 70%;
// $quickshopBtnBottom: auto;
// $quickshopBtnLeft: 20%;

// $heroQuickshopBtnWidth: 20%;
// $heroQuickshopBtnTop: 70%;
// $heroQuickshopBtnBottom: auto;
// $heroQuickshopBtnLeft: 40%;

// $reviewCounterFontStyle: (
//   'font': (
//     'color': $body-font-color,
//     'line-height': 1.4em,
//     'font-size': 0.75em,
//     'margin': 0 0 0 2px,
//     'vertical-align': top
//   )
// )!default;

$productInfoFontStyle: (
  'font': (
    'font-family': $body-font-family,
    'color': $body-font-color,
    'font-size': em-calc(12px)
  )
);

// Mobile social web share
$socialMediaWebShareMobileBottom: rem-calc(15);
$socialMediaWebShareBottom: 0;
$socialMediaWebShareMobileFontSize: rem-calc(20);
$socialMediaWebShareMobilePosition: absolute;
$socialMediaWebShareMobileRight: 0;
$socialMediaWebShareMobileRightMedium: 0;
$socialMediaWebShareMobileTextDecoration: none;
$socialMediaWebShareDisplay: block;
$socialMediaWebShareDisplayMedium: block;
$lowest-price-social-media-web-share-mobile-display-small: none !important;
$lowest-price-social-media-web-share-mobile-display-medium: block !important;
$socialMediaModalLabelDisplay: none;
$socialMediaModalTitleFontSize: rem-calc(26);
$socialMediaModalInputBorder: rem-calc(1) solid get-color(white-smoke);
$socialMediaModalContainerPadding: 0 0 rem-calc(59) 0;
$socialMediaModalContainerTitleFontSize: rem-calc(14);
$socialMediaModalContainerTitleLineHeight: rem-calc(18);
$socialMediaModalContainerTitlePadding: rem-calc(35) 0  rem-calc(12) 0;
$socialMediaModalInputHeight: rem-calc(40);
$socialMediaModalInputWidth: rem-calc(292);
$socialMediaModalButtonHeight: rem-calc(40);
$socialMediaModalButtonWidth: rem-calc(180);
$socialMediaModalIconFontSize: rem-calc(24);
$socialMediaModalIconHeight: rem-calc(50);
$socialMediaModalIconWidth: rem-calc(50);
$socialMediaModalButtonFontSize: rem-calc(14);
$socialMediaModalButtonLineHeight: rem-calc(18);
$socialMediaModalButtonFontWeight: 700;
$socialMediaModalCloseIconFontSize: rem-calc(15);
$socialMediaModalPadding: rem-calc(14) rem-calc(57);
$socialMediaWebShareTop: rem-calc(-5);
$productInfoPaddingMedium: 0 rem-calc(35) 0 0;
$socialMediaModalIconHoverColor: get-color(napa-orange);
$socialMediaModalTop: 30%!important;
$socialMediaWebShareMaxHeight: rem-calc(36);

$socialMediaWebShareHeight: rem-calc(36);
$socialMediaWebShareWidth: rem-calc(36);
$socialMediaWebShareIconRight: rem-calc(12);
$socialMediaWebShareIconTop: rem-calc(6);
$socialMediaWebShareIconPosition: absolute;

//---------------------------------------------------------------
// Quick View
//---------------------------------------------------------------
$icon-button-full: $icon-enlarge !default;
$quickViewBGColor: $white;

//---------------------------------------------------------------
// Price
//---------------------------------------------------------------
$pricingEnabled: true;
// $productInfoOriginalPriceMobileDisplay: none !default;
// $productBlockPriceContent: '/' !default;
$priceFontColor: $black;
$originalPriceColor: $priceFontColor;
$offerPriceColor: $priceFontColor;
$currentPriceFontColor: get-color(burnt-orange);

//---------------------------------------------------------------
// Filters
//---------------------------------------------------------------

$filterGroupFontStyle: (
  'font': (
    'font-family': $header-font-family
  )
);

 $filterRefineSearchFontStyle: (
   'font': (
     'font-family': get-font('AkkuratPro'),
     'font-size': $input-font-size,
     'color': get-color(primary),
     'text-transform': lowercase
   )
 );

$facetValueCounterFontSize: 12px;
// $facetValuePriceFontColor: null !default;
// $filterRefineSearchPaddingTop: 27 !default;
// $filterRefineSearchPaddingLeft: 27 !default;
// $filterRefineSearchPaddingBottom: 27 !default;

// grid filter UI
$filterMinWidthSmall: 150px;
// $filterMinWidthMedium: null !default;
// $filterOverlayBg: null !default;
$filterGroupBorderBottom: 1px solid $light-gray;
// $filterGroupMarginBottom: null !default;
$filterGroupPadding: 20px 0;
$filterTitleColor: $medium-gray;
$filterTitleHeight: 30px;
$filterTitleFontSize: convert-to-em(16px);
// $filterTitleFontWeight: null !default;
$filterTitleTextTransform: lowercase;
$filterTitlePadding: 0 30px 10px 0;
// $filterTitletoggleColor: $fontLink !default;
$filterOptionsMargin: 0;
// $filterOptionsPadding: null !default;
// $filterOptionsItemHeight: 40px !default;
// $filterOptionsItemLineHeight: 40px !default;
// $filterOptionsItemHeightMobile: 40px !default;
// $filterOptionsItemLineHeightMobile: 40px !default;
// $filterOptionsItemPadding: 0 0 0 10px !default;
// $filterOptionsItemMargin: 0 0 5px 0 !default;
// $filterOptionsItemBorder: 2px solid $white !default;
$filterOptionsItemFontSize: em-calc(12px);
$filterOptionsMoreItemFontSize: em-calc(12px);
$filterOptionsItemSizeFontSize: em-calc(12px);
$filterOptionsSelectedItemBgColor: get-color(white-smoke);
$filterOptionsSelectedItemBgColorHover: get-color(white-smoke);
$filterOptionsItemLineHeightReset: 2;

$filterOptionTitleColor: $medium-gray;
$filterOptionTitlePadding: 0 rem-calc(30) rem-calc(10) 0;

// $filterOptionsSelectedItemFontColor: $body-font-color !default;
$filterOptionsSelectedItemFontColorHover: $anchor-color-hover;
$filterAttrboxSize: 30px;
$filterAttrboxBorder: 1px solid $light-gray;
$filterAttrboxBorderHover: 1px solid $medium-gray;
// $filterAttrboxInsetBorderHover: 0 0 0 1px get-color(secondary) inset !default;
// $filterAttrboxBgHover: $backgroundColor !default;
// $filterAttrboxFontColor: $fontLink !default;
// $filterAttrboxFontColorHover: $white !default;
// $filterAttrboxSelectedBg: get-color(secondary) !default;
$filterAttrboxSpacing: 0 5px 5px 0;

// $filterLinkColor: $fontLink !default;
$filterLinkColorHover: $fontLinkHover !default;
// $filterLinkSizeAttrColorHover: $fontLink !default;
$filterCounterColor: inherit;
// $filterLinkHeight: convert-to-em(25) !default;
$filterLinkActionsMargin: 5px 0 0;
$filterLinkFontWeight: normal;
$filterLinkTextDecoration: null underline;

// $filterRefineSearchButtonIcon: $icon-arrow-down-indicator !default;
// $filterRefineSearchButtonIconSelected: $icon-arrow-up-indicator !default;
// $filterRefineSearchButtonIconColor: null !default;
// $filterBoxHeightMobile: 38px !default;
// $filterBoxLineHeightMobile: 38px !default;
// $filterBoxPaddingTopMobile: 0 !default;
// $filterAttrBoxCenterLineHeight: null !default;

// //variables for filter accordion.
$filterBarAccordionEnabled: true;
$filterAccordianExpandedStateIconfont: $icon-minus;
$filterAccordianCollapsedStateIconfont: $icon-plus;
// $filterAccordianExpandedStateIconBorder: null !default;
// $filterAccordianExpandedStateIconPadding: null !default;
$filterAccordianIconHeight: $filterTitleHeight;
$filterAccordianIconfontsize: 10px;
// $filterAccordianIconRightPosition: 10px !default;
$filterOptionOpacity: 1;
// $filterOptionMaxHeight: null !default;
$filterOptionMaxHeight: 1500px;
$filterOptionCollapsedMaxHeight: 0;
$filterOptionCollapsedOverflow: hidden;
$filterOptionCollapsedOpacity: 0;
$filterTitleAccordianCursor: pointer;
// $fitlerBreadcrumbFacetListResetDisplay: block !default;
// $filtersResetAllFontFamily: null !default;
// $filtersResetAllDisplay: block !default;
// $filtersResetAllFloat: null !default;
// $filtersResetAllTextDecoration: null !default;


//---------------------------------------------------------------
// Sort By
//---------------------------------------------------------------
 $sortBySectionTopPosition: 0;
// $sortBySectionDesktopTopMargin: 0 !default;
// $sortBySectionWidth: 3 !default;
// $sortBySectionWidthMedium: 3 !default;


//---------------------------------------------------------------
// Facet Breadcrumb
//---------------------------------------------------------------
$facetBreadcrumbEnabled: true !default;
$facetValueFontSize: 12px;
// $facetValueFontFamily: null !default;
$faceValueFontWeight: bold;
$facetSelectedBackroundColor: get-color(white-smoke);
$facetBreadcrumbDisplay: block;
$facetFilterRemoveFloat: right;
$facetBreadcrumbMarginLandR: $column-gutter/4;
// $facetBreadcrumbPaddingLandR: 12px !default;
// $facetBreadcrumbPaddingTandB: 16px !default;
// $facetBreadcrumbLineHeight: 0 !default;

$topFacetsEnabled: true;

//---------------------------------------------------------------
// Grid Header
//---------------------------------------------------------------
$resultCounterFontSize: $breadcrumbs-item-font-size;
$breadcrumbLastItemFontSize: $breadcrumbs-item-font-size;
$breadcrumbLastItemFontColor: $black;
$breadcrumbLastItemContent: '\\';
$breadcrumbContent: '\\';
// $breadcrumbLastItemLineHeight: 1em !default;
// $gridHeaderSearchSummaryWidth: 6 !default;
// $gridHeaderOptionsWidth: 3 !default;
// $gridHeaderOptionsMobileTextAlign: center !default;
// $gridHeaderOptionsTextAlign: left !default;
// $gridHeaderOptionsWidthTablet: 3.7 !default;
// $gridHeaderSearchSummaryMarginTop: 10px !default;
// $gridHeaderOptionsMarginTopMobile: 20px !default;
// $gridHeaderOptionsMarginTop: 25px !default;
// $gridHeaderOptionsMarginTopTablet: 25px !default;

//---------------------------------------------------------------
// Grid Search Header
//---------------------------------------------------------------
$gridSearchHeaderEnabled: false;
$searchResultProductListTitleFontFamily: get-font('AkkuratPro');
$headerHeroDescFontSize: 26px;

//---------------------------------------------------------------
// Account - Navigation
//---------------------------------------------------------------
$myAccountMenuInsidePageUtilContainerEnabled: true;
// $accountNavLargeScreenMidpoint: 1128px !default;

//---------------------------------------------------------------
// Account - Wishlist
//---------------------------------------------------------------
$wishlistContainerMaxwidth: $global-width;
// $wishlistTitleFont: Arial, sans-serif !default;
// $wishlistBtnFont: Arial, sans-serif !default;
// $productInfoFont: Arial, sans-serif !default;
// $removeWishlistFont: Arial, sans-serif !default;
// $shareBtnBG: $white !default;
// $shareBtnBGHover: $brandColorPalette3  !default;
// $shareBtnColor: $brandColorPalette3  !default;
// $shareBtnColorHover: $white  !default;
// $privateContinueShoppingBtnBG: $brandColorPalette1 !default;
// $privatePageTitleFontSize: 2.65em;
// $productPhotoBG: inherit  !default;
// $fullWidthContainerBG: $white  !default;
// $viewOptionSelectedBtnColor: $brandColorPalette3  !default;
// $viewOptionSelectedBtnFontSize: em-calc(16px) !default;
// $viewOptionSelectedBtnFontWeight: bold !default;
// $viewOptionSelectedBtnFontFamily: $header-font-family !default;
// $viewOptionSelectedBtnTextTransform: inherit !default;
// $viewOptionUnselectedBtnColor: $hyperlinkText  !default;
// $viewOptionUnselectedBtnFontSize: em-calc(16px) !default;
// $viewOptionUnselectedBtnFontFamily: $header-font-family !default;
// $viewOptionUnselectedBtnFontWeight: bold !default;
// $viewOptionUnselectedBtnTextTransform: inherit !default;
// $viewOptionUnselectedBtnFontWeight: bold !default;
// $viewOptionDefaultBtnColor: $defaultText !default;
// $viewOptionDefaultBtnFontWeight: initial !default;
// $viewOptionNoneFontSize: 9px !default;
$viewRemoveFromWishlistDisplay: none !default;
// $viewOptionsBtnLetterSpacing: initial !default;
// $photoContainerBtnColor: $white  !default;
// $photoContainerBtnColorHover: $brandColorPalette3  !default;
// $productInfoPriceColor: $brandColorPalette1  !default;
// //$productInfoOriginalPriceMobileDisplay: none !default;
$wishlistPrivacyContainerBG: $white;
$wishlistPrivacyContainerColor: $black;
// $wishlistHeaderBorderStyle: 8px dotted get-color(milk-white) !default;
$copyLinkBG: transparent !default;
// $wishlistTitleFontSize: 2.5em !default;
// $wishlistPrivacySettingBorderBottomStyle: 1px dashed $borderColor !default;
$privacyDetailsFontSize: em-calc(22) !default;
$copyLinkLabelFontSize: em-calc(18) !default;
$copyLinkColor: $black !default;
$copyLinkFontSize: em-calc(12) !default;
$mol-media-gallery-full-width-atc: 100%;
// $wishlistHeroControlShopFontSize: 0.75em !default;
// $wishlistHeroControlViewFontSize: 1em !default;
// $wishlistHeroControlViewFontSizeMobile: 1.375em !default;
// $wishlistButtonFontSize: 1em !default;
// $wishlistNoneButtonFontSize: 0.75em !default;
// $wishlistRedButtonFontSize: 0.875em !default;
// $wishlistPhotoContainerBtnFontSize: 0.6875em !default;
// $wishlistRemoveWishlistFontSize: 0.875em !default;
// $wishlistRemoveWishlistFontWeight: null !default;
// $wishlistRemoveWishlistTextTransform: null !default;
// //$wishlistGridViewProductInfoFontSize: 0.875em !default;
// $wishlistGridViewProductInfoFontSizeMobile: 1.3125em !default;
// $wishlistListViewProductDetailFontSize: em-calc(16) !default;
// $wishlistListviewTitleFontSize: 2.0625em !default;
// $wishlistListviewTitleFontSizeMobile: convertPxToVw(42px, 640) !default;
// $wishlistListviewTitleFontpriceMobile: 1.375em !default;
// $wishlistRemoveWishlistIcon: $icon-wishlist !default;
// $wishlistRemoveWishlistIconMarginRight: 3px !default;

// $wishlistGridViewButtonAlign: right !default;
// $wishlistGridViewButtonPaddingRight: $column-gutter / 2 !default;
// $wishlistListViewButtonAlign: left !default;
// $wishlistListViewButtonPaddingLeft: $column-gutter / 2 !default;
$wishlistGridViewButtonLeftPlacement: 0 !default;

// //new styles 12/118/2015
// $wishlistGridViewDisplayStyle: inline-block !default;
// $wishlistListViewDisplayStyleColor: none !default;
// $wishlistGridViewDisplayColor: none !default;
// $wishlistListViewDisplayStyleRemove: none !default;
// $wishlistListviewButtonMarginTop: 20px !default;
// $wishlistListviewButtonMarginBottom: 0 !default;
// $wishlistControlsPaddingTop: 0 !default;
// $wishlistH3FontSize: initial !default;
// $wishlistH3ListViewSpanFontWeight: 1em !default;
// $wishlistListViewH3FontSize: 1em !default;
// $wishlistControlsBtnFontFamily: $header-font-family !default;
// $wishlistControlsBtnFontSize: 9px !default;
// $wishlistControlsBtnFontWeight: bold !default;
// $wishlistHRBorder: inherit !default;
// $wishlistHRBorderWidth: inherit !default;

// // product photo
// $wishlistProductPhotoBkgdColor: $white !default;

// //product view title
// $wishlistProductTitleFontFamily: $header-font-family !default;
// $wishlistProductTitleFontSize: em-calc(16px) !default;
// $wishlistProductTitleFontSizeMobile: convertPxToVw(42px, 640) !default;
// $wishlistProductTitleFontSizeTablet: em- calc(16px)!default;
// $wishlistProductTitleColor: $black !default;
// $wishlistProductTitleFontWeight: normal !default;
// $wishlistProductTitleFontStyle: normal !default;
// $wishlistProductTitleTextTransform: none !default;
// $wishlistProductTitleLetterSpacing: inherit !default;
// $wishlistProductTitleAfterDisplay: inline-block !default;
// $wishlistProductTitlePaddingLeft: 15px !default;
// $searchResultSummaryFontFamily: $header-font-family !default;

// //product view price
// $wishlistProductPriceFontFamily: $searchResultSummaryFontFamily !default;
// $wishlistProductPriceFontSize: em-calc(16px) !default;
// $wishlistProductPriceFontSizeMobile: em-calc(16) !default;
// $wishlistProductPriceColor: $brandColorPalette1 !default;
// $wishlistProductPriceFontWeight: 500 !default;
// $wishlistProductPriceFontStyle: normal !default;
// $wishlistProductPriceTextTransform: none !default;

// //product view color
// $wishlistProductColorFontFamily: $searchResultSummaryFontFamily !default;
// $wishlistProductColorFontSize: em-calc(16) !default;
// $wishlistProductColorFontSizeMobile: em-calc(16px) !default;
// $wishlistProductColorColor: $brandColorPalette1 !default;
// $wishlistProductColorFontWeight: 500 !default;
// $wishlistProductColorFontStyle: normal !default;
// $wishlistProductColorTextTransform: none !default;

// //product view quantity
// $wishlistProductQuantityFontFamily: $searchResultSummaryFontFamily !default;
// $wishlistProductQuantityFontSize: em-calc(16px) !default;
// $wishlistProductQuantityFontSizeMobile: em-calc(16) !default;
// $wishlistProductQuantityColor: $black !default;
// $wishlistProductQuantityFontWeight: normal !default;
// $wishlistProductQuantityFontStyle: normal !default;
// $wishlistProductQuantityTextTransform: none !default;

// //share control icons
// $wishlistShareControlIconFontSize: 20px !default;
// $wishlistShareControlIconPadding: 9px !default;
// $wishlistFacebookIconKey: $icon-facebook !default;
// $wishlistTwitterIconKey: $icon-twitter !default;
// $wishlistTumblrIconKey: $icon-tumblr !default;
// $wishlistPinterestIconKey: $icon-pinterest !default;
// $wishlistGooglePlusIconKey: $icon-googleplus !default;
// $wishlistEmailIconKey: $icon-mail !default;
// $wishlistMobileIconKeyValue: $icon-mobile !default;

// //back to wishlist link
$wishlistBackToWishlistLinkTop: em-calc(20) !default;

// //Wishlist Mobile View with Links
// // $wishlistMobileLinksEnabled:false !default;
// $wishlistMobileLinksEnabledLinksPadding: 5px !default;
// $wishlistMobileLinksEnabledLinksWidth: 50% !default;
// $wishlistMobileLinksEnabledLinksMarginTop: 20px !default;
// $wishlistMobileLinksEnabledLinksTextAlign: left !default;
// $wishlistMobileLinksEnabledLinksFloat: left !default;
// $wishlistMobileLinksEnabledGridViewButtonPaddingRight: 0 !default;
// $wishlistMobileLinksEnabledListViewButtonPaddingLeft: 0 !default;
// $wishlistMobileLinksEnabledGridViewButtonTextAlign: center !default;
// $wishlistMobileLinksEnabledAddToCartLinkClear: left !default;
// $wishlistBackToWishlistLinkLeft: null !default;
// $wishlistBackToWishlistLinkPosition: absolute !default;

// //Wishlist alert dialog
// $wishlistAlertFloat: left !default;
// $wishlistAlertPadding: 15px !default;

// //Wishlist Page Contents
$wishlistPageContentsPaddingTop: em-calc(80) !default;
// $wishlistHeaderContentAddressPosition: static !default;
// $wishlistBodyPaddingTop: null !default;

$addressFormPhoneToolTipPadding: 10px;
$formLabelValidationErrorColor: get-color(nero);
$desktopSmallInputWidth: auto;
// Product Lane
//---------------------------------------------------------------
$productLaneEnabled: true;
// $productListHeaderImageFloat: none !important;
$viewAllContainerTopBorder: 0;
$productLaneTitleBorder: 0;
$productLaneBorder: 0;
// $productLaneIndicatorRightColor: inherit;
// $productLaneFooterMarginTop: 50px;
// $productLaneFooterMarginBottom: 50px;
// $viewAllContainerPaddingTop: null;
// $productColumnsInStackedCategory: 3;
// $productColumnsInStackedCategoryTablet: 6;
// $productColumnsInStackedCategoryMobile: 6;

//---------------------------------------------------------------
// Color Swatches
//---------------------------------------------------------------
$colorSwatchesEnabled: true;
// $productBlockColorSwatchWidth: 17%;
 $productBlockColorSwatchContainerWidth: 95%;
// $productBlockColorSwatchContainerWidthMobile: 100%;
$productBlockColorSwatchHeight: 42px;
$productBlockColorSwatchHeightMobile: 30px;
$productBlockColorSwatchHeightMobileSmall: $productBlockColorSwatchHeightMobile;
$productBlockColorSwatchDisplayDesktop: relative;
$productBlockColorSwatchMarginDesktop: 0;
// $productBlockColorSwatchGutter: 4%; //extra margin breaks the JS for view more. to be fixed in VFDP-45170
// $productBlockColorSwatchSelectedBorder: null;
// $productBlockColorSwatchBorder: null;
// $productBlockColorSwatchHoverBorder: null;
// $productBlockColorSwatchGutterMobile: 1.5%;
// $productBlockColorSwatchesMarginBottom: 0;
// $productBlockHasMoreColorSwatchWidth: 21%; //adjusting this breaks the JS for view more. to be fixed in VFDP-45170
// $productBlockHasMoreColorSwatchWidthMobile: $productBlockHasMoreColorSwatchWidth;
// $productBlockMoreSwatchWidth: 14%; //adjusting this breaks the JS for view more. to be fixed in VFDP-45170
// $productBlockMoreSwatchWidthMobile: 14%;
// $productBlockMoreSwatchesPaddingTop: 10px;
$productBlockMoreSwatchBorder: 1px solid $light-gray;
// $productBlockMoreSwatchBackgroundColor: transparent;
// $productBlockHasShowMoreContainerBGColor: $white;
// $productBlockHasShowMoreContainerprecedingText: '+';
// $productBlockHasShowMoreContainerprecedingExpandedText: '-';
// $colorSwatchesHoverStateEnabled: false;


$facetBoxBorderColor: get-color(primary);


//---------------------------------------------------------------
// Range Slider
//---------------------------------------------------------------
$include-html-range-slider-classes: true;
$range-slider-margin: 0 0 20px;
$range-slider-track-height: 5px;
$range-slider-track-full-color: get-color(white-smoke);
$range-slider-track-included-color: get-color(burnt-orange);
$range-slider-thumb-diameter: 23px;
// $range-slider-thumb-diameter-medium: $range-slider-thumb-diameter !default;
// $range-slider-thumb-diameter-large: $range-slider-thumb-diameter-medium !default;
$range-slider-thumb-color: get-color(burnt-orange);
$range-slider-output-color: get-color(eclipse);
$range-slider-output-border: none;
// $range-slider-output-border-radius: 4px !default;
// $range-slider-output-background: null !default;
// $range-slider-output-height: 2.5em !default;
// $range-slider-output-height-medium: $range-slider-output-height !default;
// $range-slider-output-height-large: $range-slider-output-height-medium !default;
$range-slider-output-bottom-spacing: 0;
// $range-slider-output-bottom-spacing-medium: $range-slider-output-bottom-spacing !default;
// $range-slider-output-bottom-spacing-large: $range-slider-output-bottom-spacing-medium !default;
// $range-slider-output-zindex: null !default;
// $range-slider-thumb-margin-top: ($range-slider-thumb-diameter / -2 + $range-slider-track-height / 2) !default; // used for centering verically
// $range-slider-thumb-margin-top-medium: ($range-slider-thumb-diameter-medium / -2 + $range-slider-track-height / 2) !default; // used for centering verically
// $range-slider-thumb-margin-top-large: ($range-slider-thumb-diameter-large / -2 + $range-slider-track-height / 2) !default; // used for centering verically

//---------------------------------------------------------
// REGISTRATION FORM BACKGROUND
//---------------------------------------------------------
$backgroundColorUtility: transparent;



//---------------------------------------------------------------
// Returns SignIn/Register
//---------------------------------------------------------------
$orderReturnEnabled: true;
//$policyBordercolor: null;
//$myAccountRegistrationTitleWidth: null;
//$myAccountRegistrationFieldsWidth: null;
$returnLookupFormLabelColor: $brandColorPalette3;

//---------------------------------------------------------------
// Returns
//---------------------------------------------------------------
$include-html-returns-page-classes: true;
$returns-page-order-detail-info-border: transparent;
$returns-page-date-picker-date-tile-background: get-color(white-smoke);
$returns-page-date-picker-date-tile-border: transparent;
$returns-page-date-picker-date-tile-disabled-background: $light-gray;
$returns-page-date-picker-date-tile-selected-background: get-color(burnt-orange);
$returns-page-date-picker-date-tile-selected-border: transparent;
$returns-page-date-picker-date-tile-selected-color: $white;
$returns-page-border: null !default;
$returns-page-create-return-instructions-background-color: transparent;
$returns-page-order-detail-form-action-box-margin-top: 0;
$returns-page-date-picker-tiles-text-align: left;
$returns-page-date-picker-date-tile-width-medium: 70px;
$policyBordercolor: get-color(narvik);


//---------------------------------------------------------------
// Return Order Confirmation
//---------------------------------------------------------------
//$returnHistoryOrderDetailItemListBorderColor: $black !default;
$returnConfirmationLabelButtonBGColor: inherit;
//$returnConfirmationShippingOptionCursor: null !default;


//---------------------------------------------------------------
// Return Order Details
//---------------------------------------------------------------
//$orderReturnItemQtyWidth: null !default;
//$orderReturnItemQtyFloat: null !default;
//$orderReturnItemPriceWidth: null !default;
//$orderReturnItemReasonMarginTop: null !default;
//$orderReturnItemReasonMarginTopSmall: null !default;


//---------------------------------------------------------------
// Return History
//---------------------------------------------------------------
//$returnHistoryOrderReturnDescFontSize: 14px !default;
$lookUpBorderColor: get-color(narvik);


//---------------------------------------------------------------
// Return History Details
//---------------------------------------------------------------
//$returnHistoryOrderDetailTooltipBkgdColor: $white !default;
//$returnHistoryOrderDetailTooltipColor: get-color(nero) !default;
//$returnHistoryOrderDetailTooltipMaxWidth: 550px !default;
//$returnHistorySubTitleFontSize: 14px !default;
//$emailInputBorderColor: get-color(feta) !default;

//View Order Status
$orderLookupRequiredFieldsDisplay: inline-block;
$orderLookupRequiredFieldsTextAlign: right;
$orderLookupRequiredFieldsWidth: 100%;

//---------------------------------------------------------------
// Secure Checkout header
//---------------------------------------------------------------
$include-header-secure-classes: true;
$checkoutNavLogoHeight: 95px;
$checkoutNavLogoWidth: 282px;
$checkoutNavLogoMinHeightMobile: 44px;
$checkoutNavLogoMinWidthMobile: 130px;
$checkoutNavLogoImage: $store-logo 0 center no-repeat;
$checkoutSmArcFontStyle: $body-font-family;
$returnToLinkFontSize: (
	'font': (
	    'font-family': $body-font-family,
	    'font-size': 1em,
	    'font-weight': bold,
	    'text-transform': uppercase,
	    'font-style': normal
    )
);


//---------------------------------------------------------------
// Shopping Bag
//---------------------------------------------------------------
$orderItemDetailLinkDisplay: inline;
$cart-payment-msg-container-display: inline-block;
$cart-payment-msg-container-margin-bottom: rem-calc(23);
$cart-payment-msg-container-margin-top: rem-calc(22);
$checkoutSummaryPaypalMsgContainerMarginBottom: 0;
$cart-klarna-message-line-height: rem-calc(20);
$cart-klarna-message-margin-bottom: 0;

//---------------------------------------------------------------
// Billing Address title margin
//---------------------------------------------------------------
$formTitleFieldMargin: 20px 0 15px;

//---------------------------------------------------------------
// Order Confirmation
//---------------------------------------------------------------
$order_confirmation_checkout_page_order: 0;
$order_confirmation_checkout_page_margin_top: 0;
$order_confirmation_checkout_page_padding_top: 0;
$order_confirmation_checkout_page_margin_top_small: 30px;
$order_confirmation_checkout_page_padding_top_small: 30px;
$order_confirmation_order_summary_container_first_child_padding_left_large: 0;
$order_confirmation_order_summary_container_padding_left_medium: 0;
$order_confirmation_order_summary_container_padding_left_large: 40px;
$order_confirmation_summary_container_padding: 25px;


//---------------------------------------------------------------
//
//Account Landing Page Layout
//
//---------------------------------------------------------------
$include-html-account-landing-page-classes: true;
//$account_landing_tile_border_bottom: null;
//$account_landing_tile_border_bottom_hover: null;
//$account_landing_tile_border_top: null !default;
//$account_landing_tile_border_top_hover: null;
//$account_landing_tile_section_background_color: null;
//$account_landing_tile_font_family: null;
$account_landing_tile_font_family: $body-font-family;
//account_landing_tile_font_color: null;
//$account_landing_icomoon_font_size: null;
$account_landing_icomoon_font_size: em-calc(30px);
$account_landing_tile_font_weight: bold;
//$account_landing_tile_line_height: 1;
//$account_landing_tile_border: none;
$account_landing_tile_border: 1px solid $light-gray;
//$account_landing_tile_heading_font_color: null !default;
//$account_landing_tile_heading_font_family: null !default;
$account_landing_tile_heading_font_family: $header-font-family;
//$account_landing_tile_heading_font_size: null;
$account_landing_tile_heading_font_size: em-calc(16px);
//$account_landing_tile_subheading_font_size: null;
$account_landing_tile_subheading_font_size: em-calc(12px);

//---------------------------------------------------------------
// Account - Product detail page
//---------------------------------------------------------------
$myAccountOrderStatusCancelledMessageBg: get-color(white-smoke);
$myAccountOrderStatusCancelledMessageColor: get-color(night-rider);
$myAccountOrderStatusCancelledMessagePadding: rem-calc(20) 0;
$myAccountOrderStatusCancelMsgTitleMargin: 0;
$myAccountOrderStatusCancelMsgTitleMarginLarge: 0 0 rem-calc(15) 0;
$myAccountOrderStatusCancelMsgTitleIconColor: get-color(success);
$myAccountOrderStatusCancelMsgTitleIconHeight: rem-calc(32);
$myAccountOrderStatusCancelMsgTitleIconMarginRight: 0;
$myAccountOrderStatusCancelMsgTitleIconMarginRightLarge: rem-calc(6);
$myAccountOrderStatusCancelMsgTitleIconWidth: rem-calc(32);
$myAccountOrderStatusCancelMsgTitleIconBeforeMarginTop: rem-calc(3);
$myAccountOrderStatusCancelMsgTitleTextFontSize: rem-calc(14);
$myAccountOrderStatusCancelMsgTitleTextFontSizeLarge: rem-calc(16);
$myAccountOrderStatusCancelMsgTitleTextFontWeight: 700;
$myAccountOrderStatusCancelMsgTitleTextLineHeight: rem-calc(24);
$myAccountOrderStatusCancelMsgTitleTextMargin: rem-calc(15) 0;
$myAccountOrderStatusCancelMsgTitleTextMarginLarge: 0;
$myAccountOrderStatusCancelMsgTitleTextPadding: 0 rem-calc(14);
$myAccountOrderStatusCancelMsgDescFontSize: rem-calc(14);
$myAccountOrderStatusCancelMsgDescFontWeight: 500;
$myAccountOrderStatusCancelMsgDescPadding: 0 rem-calc(14);

//---------------------------------------------------------------
// Address book title layout
//---------------------------------------------------------------
$include-address-book-tile-layout-html-classes: true;
$returnHistorySectionTitleFontSize: em-calc(24);
$subSectionTitleFontSizeMobile: em-calc(22);


//---------------------------------------------------------------
// VFDP S7 Viewer
//---------------------------------------------------------------
$vfdp-s7-viewer-image-proportion: 544, 631;
// $vfdp-s7-viewer-thumb-default-border: 0 !default; // default to 0 to not show a border
// $vfdp-s7-viewer-thumb-over-border: 0 !default;
$vfdp-s7-viewer-thumb-over-border: 1px solid $light-gray;
// $vfdp-s7-viewer-thumb-selected-border: 0 !default;
$vfdp-s7-viewer-thumb-selected-border: 1px solid $black;
//$vfdp-s7-viewer-thumb-overlay-color: null !default;
//$vfdp-s7-viewer-thumb-overlay-color-reversed: null !default;
$vfdp-s7-viewer-s7zoomview-s7iconeffect-standard: none;
$vfdp-s7-viewer-s7zoomview-s7iconeffect-multitouch: none;
$vfdp-s7-viewer-thumb-width: 58px;
$vfdp-s7-viewer-thumb-height: 68px;
$vfdp-s7-viewer-thumb-width-medium: $vfdp-s7-viewer-thumb-width;
$vfdp-s7-viewer-thumb-height-medium: $vfdp-s7-viewer-thumb-height;
$vfdp-s7-viewer-thumb-width-large: $vfdp-s7-viewer-thumb-width;
$vfdp-s7-viewer-thumb-height-large: $vfdp-s7-viewer-thumb-height;

$vfdp-s7-viewer-preload-split-padding: false;
$vfdp-s7-viewer-hero-adjust-height: false;
$vfdp-s7-viewer-swatch-container-size-medium: 0;
$vfdp-s7-viewer-swatch-container-size: 0;
$vfdp-s7-viewer-swatch-container-size-large: 0;
$vfdp-s7-viewer-spacing-between-swatches-and-viewer: 0;
$vfdp-s7-viewer-embedded-swatched: true;
$vfdp-s7-viewer-padding-transition: null;
$vfdp-s7-viewer-preload-fade-out-transition: null;
$vfdp-s7-viewer-fade-in-transition: null;
$vfdp-s7-viewer-progression-right-buttons-margin-left: 0;

$swiper-viewer-swatch-cta-container-bottom: rem-calc(55);
$swiper-viewer-swatch-cta-container-right: 0;
$swiper-viewer-pagination-active-bullet-color: get-color(napa-orange);
$swiper-viewer-pagination-bullet-color: $white;
$swiper-viewer-pagination-bullet-opacity: 1;
$swiper-viewer-pagination-bullets-width: auto;
$swiper-viewer-pagination-bullet-border: none;
$swiper-viewer-pagination-bullets-left: rem-calc(10);
$swiper-viewer-pagination-bullets-bottom: rem-calc(-10);
$swiper-viewer-pagination-bullet-width: rem-calc(4);
$swiper-viewer-pagination-bullet-height: rem-calc(4);
$swiper-viewer-pagination-bullet-margin: 0 rem-calc(2);
$mol-media-gallery-image-wrapper-height: auto;
$mol-media-gallery-image-wrapper-img-height: auto;
$mol-media-gallery-image-wrapper-img-left: 0;
$fullscreen-close-button-top: unset;
$fullscreen-close-button-bottom: rem-calc(24);
$fullscreen-swiper-thumbnails-position: absolute;
$fullscreen-swiper-thumbnails-bottom: rem-calc(10);
$fullscreen-zoom-button-size: rem-calc(28);
$fullscreen-disabled-button-opacity: 1;
$fullscreen-thumbnails-area-size: rem-calc(81);
$fullscreen-button-nav-display: none;
$fullscreen-main-content-min-height: 90vh;
$fullscreen-main-content-align: stretch;

$swiper-viewer-placeholder-min-height: 116vw; // Aspect ratio for napa images
$swiper-viewer-placeholder-height: 116vw;

//SHOP THE LOOK
$cta-button-overlay-enabled: false;
$cta-container-position: relative;
$cta-container-display: block;
$cta-banner-background-size: contain;
$cta-container-height: rem-calc(140);
$cta-container-width: 100%;
$cta-container-border: rem-calc(1) solid get-color(nobel);
$cta-container-padding: rem-calc(7.5) 0 rem-calc(7.5) rem-calc(9.5);
$cta-container-order: 5;
$cta-container-margin: rem-calc(30) 0 rem-calc(7) 0;
$cta-container-margin-medium: rem-calc(40) 0 rem-calc(17) 0;
$cta-banner-label-margin: 0 0 0 rem-calc(115);
$cta-banner-label-icon-margin: 0 0 0 0;
$cta-banner-label-color: $black;
$cta-banner-label-text-decoration: none;
$cta-banner-label-icon-text-decoration: none;
$cta-banner-label-leading: rem-calc(23);
$cta-banner-label-leading-medium: rem-calc(31);
$cta-banner-label-font-size: rem-calc(18)!important;
$cta-banner-label-font-size-medium: rem-calc(24)!important;
$cta-banner-label-order: 2;
$cta-banner-icon-order: 1;
$cta-banner-label-text-margin: 0;
$cta-banner-label-justify-content: space-between;
$cta-banner-label-hover-color: $black;

$stlModalArrowBackground: transparent;
$stlModalArrowColor: $black;
$stlModalArrowDisplay: none;
$stlModalArrowDisplayMedium: block;
$stlModalArrowFontSize: rem-calc(12);
$stlModalArrowNextMarginTop: 0;
$stlModalArrowPrevTop: rem-calc(40);
$stlModalPosition: relative;
$stlModalArrowNextBottomPosition: rem-calc(10);
$stlModalArrowNextRightPosition: 0;
$stlModalMaxWidth: rem-calc(1000);
$stlModalVerticallyAligned: true;
$stlModalAddToCartBtnTransition: background-color 0.25s ease-out;
$stlModalAddToCartBtnInProgressPadding: 0;
$stlModalAddToCartBtnInProgressBackground: $dark-gray;
$stlModalAddToCartBtnHoverBackground: $dark-gray;
$stlModalAddToCartBtnTxtTransform: uppercase;
$stlModalAddToCartBtnBkgdColor: get-color(primary);
$stlModalAddToCartBtnFontSize: rem-calc(12);
$stlModalAddToCartBtnHeight: auto;
$stlModalAddToCartBtnMinHeight: rem-calc(30);
$stlModalAddToCartBtnLineHeight: normal;
$stlModalAddToCartBtnHeightMedium: rem-calc(47);
$stlModalAddToCartBtnLineHeightMedium: rem-calc(18);
$stlModalAddToCartBtnHeightLarge: 100%;
$stlModalAddToCartBtnLineHeightLarge: 1;
$stlModalAddToCartBtnHeightXlarge: 100%;
$stlModalAddToCartBtnLineHeightXlarge: 1;
$stlModalAddToCartBtnMarginMedium: 0 0 rem-calc(6) 0;
$stlModalAddToCartBtnPaddingLeft: 0;
$stlModalAddToCartBtnPaddingRight: 0;
$stlModalAddToCartBtnWidth: 100%;
$stlModalAddToCartBtnWidthMedium: 100;
$stlModalAddToCartBtnPaddingMedium: rem-calc(10) 0;
$stlModalBottomButtonContainerMarginMedium: rem-calc(10) 0 0 0;
$stlModalBottomButtonContainerMarginLarge: rem-calc(14) 0 rem-calc(-5) 0;
$stlModalBottomButtonContainerMarginXLarge: rem-calc(14) 0 rem-calc(-5) 0;
$stlModalCloseButtonColor: get-color(primary);
$stlModalCloseButtonFontSize: rem-calc(15);
$stlModalCloseButtonFontSizeMedium: rem-calc(18);
$stlModalCloseButtonRight: rem-calc(19);
$stlModalCloseButtonRightMedium: rem-calc(21);
$stlModalCloseButtonTop: rem-calc(19);
$stlModalCloseButtonTopMedium: rem-calc(21);
$stlModalControlsSelectFontSize: rem-calc(10);
$stlModalControlsSelectFontSizelarge: rem-calc(14);
$stlModalControlsSelectMargin: 0;
$stlModalControlsSelectHeight: rem-calc(30);
$stlModalControlsSelectLineHeight: rem-calc(13);
$stlModalControlsSelectHeightXlarge: auto;
$stlModalControlsSelectColor: get-color(primary);
$stlModalControlsSelectMaxWidth: unset;
$stlModalControlsSelectTextAlign: left;
$stlModalDisplay: flex;
$stlModalDisplayLarge: flex;
$stlModalHeadingContainerDisplay: none;
$stlModalPricePmargin: rem-calc(-20) 0 0 auto;

$stlModalMainContainerFlexLarge: 1;
$stlModalMainContainerAddToCartBtnContainerMargin: rem-calc(8) 0 0 0;
$stlModalMainContainerAddToCartBtnContainerMarginXlarge: none;
$stlModalMainContainerAddToCartBtnContainerWidthXlarge: none;
$stlModalMainContainerMargin: rem-calc(20) 0 0 0;
$stlModalMainContainerMarginMedium: rem-calc(45) 0 0 0;
$stlModalMainContainerMarginLarge: rem-calc(50) 0 0 0;
$stlModalMainContainerMarginXLarge: rem-calc(50) 0 rem-calc(10) 0;
$stlModalMainContainerBackgroundSizeXLarge: rem-calc(570);
$stlModalMainContainerPaddingMedium: 0 0 0 rem-calc(20);

$stlModalMainContainerOverflowMedium: hidden;
$stlModalMainContainerOverflowLarge: hidden;
$stlModalProductsPriceJustifyContent: space-evenly;

$stlModalPadding: rem-calc(20);
$stlModalProductLastChildMargin: 0;
$stlModalProductBlockInfoFlexGrow: 1;
$stlModalProductBlockInfoPadding: 0 0 0 rem-calc(14);
$stlModalProductBlockInfoPosition: relative;
$stlModalProductsBlockNameFlex: 1;
$stlModalProductsBlockNameMinHeight: rem-calc(24);
$stlModalProductsBlockNameFlex: none;
$stlModalProductBlockViewsContainerImg: rem-calc(190);
$stlModalProductBlockViewsContainerImgMedium: rem-calc(166);
$stlModalProductBlockViewsContainerImgLarge: rem-calc(200);
$stlModalproductBlockInfoFlex: unset;
$stlModalProductsBlockInfoFlexGrow: 1;
$stlModalProductsBlockNameTextAlign: left;
$stlModalProductsBlockPriceFlex: 1;
$stlModalProductsBlockPriceFlexWrap: wrap;
$stlModalProductsBlockNameMargin: 0;
$stlModalProductsDisplay: block;
$stlModalProductsDisplayMedium: block;
$stlModalArrowWidth: auto;
$stlModalProductsContMaxHeightLarge: rem-calc(560);
$stlModalProductsContMaxHeightMedium: rem-calc(520);
$stlModalProductsContMaxHeightXLarge: rem-calc(640);
$stlModalArrowLeft: 50%;
$stlModalProductsContOverflowMedium: auto;
$stlModalProductsContOverflowLarge: auto;
$stlModalProductsMargin: 0 0 rem-calc(20) 0;
$stlModalProductsMarginMedium: 0 0 rem-calc(20) 0;
$stlModalProductsMarginLarge: 0 0 rem-calc(30) 0;
$stlModalProductsMarginXLarge: 0 0 rem-calc(30) 0;
$stlModalProductsPaddingTopXLarge: 0;
$stlModalProductsPaddingBottomXLarge: 0;
$stlModalProductsSalePriceColor: get-color(napa-orange);
$stlModalProductsPriceAlignItems: baseline;
$stlModalProductsPriceFlexDirection: row;
$stlModalProductsOriginalPriceTextDecoration: line-through;
$stlModalProductsOriginalPriceMarginRight: rem-calc(10);
$stlModalProductsOriginalPriceEmptyMarginRight: 0;
$stlModalProductFlexDirection: row;
$stlModalProductsPromoTextDisplay: flex;
$stlModalProductsTitleTextDecoration: none;
$stlModalProductsTitleFontWeight: 600;
$stlModalProductsPriceTextAlign: left;
$stlModalProductsMarginLeft: auto;
$stlModalProductsPriceDisplay: flex;
$stlModalProductsPriceFontWeight: 700;
$stlModalProductsPadding: 0;
$stlModalProductsPriceFontSize: rem-calc(10);
$stlModalProductsPriceFontSizeLarge: rem-calc(14);
$stlModalProductsPriceMargin: 0;
$stlModalProductsTitleFontSize: rem-calc(16);
$stlModalProductsTitleFontSizeLarge: rem-calc(14);
$stlModalProductsSalePriceFontSize: rem-calc(14);
$stlModalProductsOriginalPriceFontSize: rem-calc(14);
$stlModalProductsPriceFontSize: rem-calc(14);
$stlModalQuantityBox: none;

$stlModalHeadingTopContainerAalignSelf: flex-end;
$stlModalHeadingTopContainerAdisplay: inline-flex;
$stlModalHeadingTopContainerAfontSize: rem-calc(12);
$stlModalHeadingTopContainerAfontSizeMedium: rem-calc(14);
$stlModalHeadingTopContainerAfontWeight: 200;
$stlModalHeadingTopContainerAmarginLeft: rem-calc(30);
$stlModalHeadingTopContainerAminWidth: rem-calc(100);
$stlModalHeadingTopContainerAminWidthMedium: rem-calc(57);
$stlModalHeadingTopContainerApaddingTop: rem-calc(3);
$stlModalHeadingTopContainerAtextAlign: end;
$stlModalHeadingTopContainerAtextTransform: capitalize;
$stlModalHeadingTopContainerDisplay: block;
$stlModalHeadingTopContainerH4Display: flex;
$stlModalHeadingTopContainerH4FontSize: rem-calc(16);
$stlModalHeadingTopContainerH4FontSizeMedium: rem-calc(20);
$stlModalHeadingTopContainerH4JustifyContent: space-around;
$stlModalHeadingTopContainerMargin: 0 rem-calc(20) 0 0;
$stlModalHeadingTopContainerMarginXlarge: 0 0 0 rem-calc(14);
$stlModalHeadingTopContainerPosition: absolute;
$stlModalArrowNextTopPosition: unset;

$stlModalTopContainerBackgroundSizeLarge: cover;
$stlModalTopContainerBackgroundSizeMedium: cover;
$stlModalTopContainerDisplayMedium: flex;
$stlModalTopContainerFlexLarge: 1;
$stlModalTopContainerHeight: rem-calc(390);
$stlModalTopContainerHeightLarge: rem-calc(660);
$stlModalTopContainerHeightMedium: rem-calc(537);
$stlModalTopContainerHeightXlarge: rem-calc(660);
$stlModalProductsContHeightLarge: rem-calc(660);
$stlModalTopContainerMargin: rem-calc(45) 0 0 0;
$stlModalTopContainerMarginLarge: rem-calc(50) 0 0 0;
$stlModalTopContainerMarginMedium: rem-calc(45) 0 0 0;
$stlModalTopContainerMarginXLarge: rem-calc(50) 0 0 rem-calc(15);
$stlModalTopContainerMaxHeightLarge: rem-calc(580);
$stlModalTopContainerPadding: 0 0 0 0;
$stlModalTopContainerPaddingMedium: 0 0 0 0;
$stlModalTopContainerPositionMedium: relative;
$stlModalTopContainerWidthMedium: 70%;
$stlModalTopContainerBackgroundRepeatMedium: no-repeat;

//---------------------------------------------------------------
// Collection Point Map Results
//---------------------------------------------------------------
$collection-point-list-border-top: $light-gray;
$collection-point-field-set-border: 1px solid get-color(napa-gainsboro);
$collection-point-field-set-padding: 1.53846em;
$collection-point-accordion-open: $icon-arrow-up-indicator-open;
$collection-point-accordion-closed: $icon-arrow-down-indicator-open;
$selected-collection-point-border: 1px solid $light-gray;
$selected-collection-point-map-border: 2px solid $light-gray;
$return-back-to-items-wrapper-display: inline-block;
$return-back-to-items-wrapper-margin-top: 20px;
$collection-point-results-tooltip-position: relative;
$collection-point-results-tooltip-float: left;

//Invoice Billing
$billingInvoiceCancelBtnMarginSmall: 0;
$billingInvoiceRadioBtnGutterLarge: 10%;

//Star Rating Component (powered by Power Reviews)
$iconStarFontSize: 20px;
$iconStarTopColor: $black;
$iconStarBottomColor: $light-gray;
$iconStarBackgroundTop: transparent;
$iconStarBackgroundBottom: transparent;
$iconStarPadding: 0;
$iconStarContainerHeight: rem-calc(30);
$ratingNumberDisplayMedium: none;
$reviewsNumberDisplay: none;
$iconStarContainerPadding: 0;
$pr-checkbox-before-width: auto;
$pr-checkbox-after-width: auto;
$pr-checkbox-after-margin-left: rem-calc(3);

//---------------------------------------------------------------
// Catalog: department, category, subcategory
//---------------------------------------------------------------
$notifyMeDrawerEnabled: true;


//////////////////////////////////////
// Global Footer
//////////////////////////////////////
$footerBorderTop: 1px solid $light-gray;
$footerMargin: em-calc(20) 0 0 0;
$footerMarginMedium: em-calc(30) 0 0 0;
$footerSectionPadding: 0 0 em-calc(40);
$footerContentSectionPadding: em-calc(50) 0 em-calc(35);
$footerContentSectionPaddingMedium: em-calc(75) 0 em-calc(60);
$footerExtraSectionPaddingMedium: 0;
$footerUtilSectionPadding: 0;
$footerSocialMarginBottom: em-calc(40);
$footerLocatorMarginBottom: $footerSocialMarginBottom;

$footerHeadingColor: $medium-gray;
$footerHeadingSize: em-calc(16);
$footerHeadingPadding: 0 0 1em;
$footerHeadingTransform: none;

$footerNavListMargin: 0 0 2em;
$footerAccordionItemBorder: 0;
$footerAccordionItemPadding: 0 0 1em;
$footerAccordionItemPaddingMedium: null;

$footerBackToTopLinkColor: get-color(burnt-orange);
$footerBackToTopLinkPadding: em-calc(14);
$footerBackToTopLinkSize: em-calc(12);

$footerSocialIconMargin: 0 9px;
$footerSocialIconSize: em-calc(20);

$footerLogoPath: null;
$footerLogoHeight: 0;



//---------------------------------------------------------------
// PDP Accordion
//---------------------------------------------------------------
$accordion-header-font-size: rem-calc(14);
$accordion-header-font-size-medium: rem-calc(16);
$accordion-header-line-height: rem-calc(20);
$accordion-header-line-height-medium: rem-calc(21);

//---------------------------------------------------------------
// Payment
//---------------------------------------------------------------
$savedCCBoxBorderBottom: 0;

// APPLE PAY - PDP
$pdp_add_to_bag_button_width_with_applepay_enabled: 100%;
$pdp_add_to_bag_button_width_with_applepay_enabled_medium: 100%;
$pdp_add_to_bag_button_width_with_applepay_enabled_large: 100%;
$pdp_applepay_button_width: 100%;
$pdp_applepay_button_width_small: 100%;
$pdp_applepay_button_width_medium: 100%;
$pdp_applepay_button_padding: 23px;
$pdp_applepay_button_margin_small: 0 0 10px 0;
$pdp_out_of_stock_button_width_with_applepay_enabled: 100%;

// APPLE PAY - CART
$shopcart_applepay_button_container_margin_top_small: 0;
$shopcart_applepay_button_container_margin_top: 0;
$shopcart_applepay_button_padding: 22px;
$shopcart_applepay_button_margin_small: 0 auto;
$shopcart_applepay_button_container_padding_small: 0;

// RECYCLE PROGRAM
$recycleProductTitleFontFamily: get-font('AkkuratPro');
$recycleProductTableRowBorderColor: get-color(napa-gainsboro);
$recylceProductItemPromoTextColor: get-color(napa-orange);
$recycleReturnStepListItemBackground: get-color(napa-gainsboro);

$mol-media-gallery-product-views-promo-badge-left: rem-calc(-1);
$mol-media-gallery-product-views-promo-badge-top: rem-calc(15) !default;
$mol-media-gallery-product-views-promo-badge-background: $white;
$mol-media-gallery-product-views-promo-badge-font-weight: 400;
$mol-media-gallery-product-views-promo-badge-width: fit-content;
$mol-media-gallery-product-views-promo-badge-padding: 0.15rem 0.4rem 0.1rem 0.4rem;
$mol-media-gallery-product-views-promo-badge-font-family: get-font('AkkuratPro');
$mol-media-gallery-product-views-promo-badge-font-size: rem-calc(10);
$mol-media-gallery-product-views-promo-badge-line-height: rem-calc(17);
$mol-media-gallery-product-views-promo-badge-letter-spacing: normal;
$mol-media-gallery-product-image-wrapper-background-color: get-color(white-smoke);

$include-sticky-add-to-cart-commas: false;
$sticky-add-to-cart-product-price-current-price-color: get-color(napa-orange);
$sticky-add-to-cart-product-price-discount-price-color: get-color(napa-orange);

// Klarna
$klarnaFontSize: rem-calc(13);
$klarnaFontWeight: 200;
$klarnaFontSizeMedium: rem-calc(13);
$klarnaDetailsMargin: 0 0 0 rem-calc(15);
$klarnaDetailsFontWeight: bold;
$klarnaDetailsHoverColor: get-color(napa-orange);
$klarnaDetailsHoverTextDecoration: none;
$klarnaFontFamily: get-font('AkkuratPro');
$klarnaFontColor: $black;
$klarnaMessageLineHeight: rem-calc(20);
$klarnaMessageTextTransform: none;
$klarnaMessageMarginMedium: rem-calc(14) 0 rem-calc(14) 0;
$klarnaContainerOrder: 5;
$klarnaContainerPadding: 0;
$klarnaIconSize: rem-calc(23);

// Paypal Pay Later
$paypalPayLaterFontSize: rem-calc(13);
$paypalPayLaterFontWeight: 200;
$paypalPayLaterFontSizeMedium: rem-calc(13);
$paypalPayLaterDetailsMargin: 0 0 0 rem-calc(15);
$paypalPayLaterDetailsFontWeight: bold;
$paypalPayLaterDetailsHoverColor: get-color(napa-orange);
$paypalPayLaterDetailsHoverTextDecoration: none;
$paypalPayLaterFontFamily: get-font('AkkuratPro');
$paypalPayLaterFontColor: $black;
$paypalPayLaterMessageLineHeight: rem-calc(20);
$paypalPayLaterMessageMarginMedium: rem-calc(14) 0 rem-calc(14) 0;
$paypalPayLaterContainerOrder: 5;
$paypalPayLaterIconSize: rem-calc(23);
$paypalPayLaterLogoSize: rem-calc(16);

// Float Labels
$floatLabelActiveTop: rem-calc(-9.75px);
$floatLabelActiveFontSize: 9.88;
$floatLabelActiveAfterBG: transparent;
$floatLabelLeft: 0.4rem;
$floatLabelValidationErrorColor: get-color(nero);
$floatLabelInputPaddingBottom: 2px;
$floatLabelCollectionPointZipContainerWidthMedium: grid-column(7);
$floatLabelCollectionPointZipContainerWidthLarge: grid-column(4);
$floatLabelFormValidationIconDisplay: none;
$floatLabelCollectionPointPickupInputWidth: grid-column(8);
$floatLabelSelectPaddingTop: 0.75rem;
$floatLabelAddressErrorPaddingBottom: 5px;
$floatLabelPhoneInputWidth: grid-column(8);
$floatLabelCollectionPointPhoneInputWidth: grid-column(8);
$floatLabelCollectionPointInputContainerFloat: left;
$floatLabelCollectionPointInputContainerWidth: 100%;
$floatLabelCollectionPointInputContainerWidthMedium: grid-column(8);

// Product recommendations
$brSearchWidgetBorderTop: 1px solid get-color(napa-gainsboro);
$brSearchWidgetPadding: rem-calc(45) 0 0 0;
$brSearchWidgetMediumPadding: rem-calc(45) rem-calc(10) 0;
$brSearchWidgetDisplay: block;
$brSearchWidgetDisplayMedium: block;
$brSearchWidgetMarginTop: rem-calc(45);
$brCarouselSearchHeadingFontSize: rem-calc(14);
$brCarouselSearchHeadingFontSizeMedium: rem-calc(24);
$brCarouselSearchHeadingFontWeight: bold;
$brCarouselSearchHeadingLineHeight: rem-calc(18);
$brCarouselSearchHeadingMargin: 0 0 rem-calc(10);
$brCarouselSearchHeadingTextTransform: uppercase;
$brCarouselSearchHeadingTextTransformMedium: capitalize;
$brCarouselSearchSubHeadingFontSize: rem-calc(13);
$brCarouselSearchSubHeadingFontSizeMedium: rem-calc(16);
$brCarouselSearchSubHeadingLineHeight: rem-calc(18);
$brCarouselSearchSubHeadingMargin: 0 0 rem-calc(14);
$brCarouselSearchSubHeadingDisplay: none;
$brCarouselSearchMoreResultsMargin: 0 auto;
$brCarouselSearchMoreResultsMaxWidth: rem-calc(350);
$brCarouselSearchMoreResultsMaxWidthMedium: none;
$brCarouselSearchMoreResultsNonCarouselDisplay: inline-flex;
$brCarouselSearchMerchContFirstChildPaddingLeft: rem-calc(16);
$brCarouselSearchMerchContFirstChildPaddingLeftMedium: rem-calc(16);
$brCarouselSearchMerchContPadding: 0;
$brCarouselSearchMerchContPaddingMedium: rem-calc(5);
$brCarouselSearchMerchTitleLineHeight: rem-calc(20);
$brCarouselSearchMerchTitleMarginTop: rem-calc(16);
$brCarouselSearchMerchTitleLinkColor: $black;
$brCarouselSearchMerchTitleLinkTextDecoration: none;
$brCarouselSearchMerchTitleLinkColorHover: $black;
$brCarouselSearchMerchPriceLineHeight: rem-calc(20);
$brCarouselSearchMerchPriceMarginTop: rem-calc(3);
$brCarouselSearchMerchPricePaddingBottom: rem-calc(18);
$brCarouselSearchMerchPricePaddingBottomMedium: rem-calc(14);
$brCarouselSearchArrowBackground: transparent;
$brCarouselSearchArrowColor: $black;
$brCarouselSearchArrowPadding: rem-calc(5) rem-calc(8);
$brCarouselSearchArrowPosition: absolute;
$brCarouselSearchArrowTop: calc(50% - 28px);
$brCarouselSearchArrowNextRight: rem-calc(4);
$brCarouselSearchArrowNextRightMedium: rem-calc(6);

//$active-facets-height: null !default;
//$active-facets-letter-spacing: null !default;
//$active-facets-margin-bottom: 0 !default;
$active-filters-background: get-color(white-smoke);
//$active-filters-border-radius: null !default;
$active-filters-border: rem-calc(1) solid $black;
//$active-filters-list-max-height: 45px !default;
$active-filters-container-background: $white;
//$facet-menu-sort-font-color: null !default;
//$facet-menu-sort-font-family: null !default;
//$facets-animate-time: 0.50s !default;
//$facets-main-font-family: null !default;
//$facets-main-font-family_medium: null !default;
$filter-border: rem-calc(1) solid $black;
$facet-sub-menu-actions-width: rem-calc(190);
//$filter-color: null !default;
$filter-menu-active-title-text: $medium-gray;
//$filter-menu-checkbox-container-margin: null !default;
//$filter-menu-title-outline: null !default;
$filter-menu-title-text-align: left;
//$filter-menu-background: $white;
$filter-menu-header-background: $black;
//$filter-option-display: null !default;
//$filter-option-height-large-screen: 30px !default;
//$filter-option-line-height-large-screen: $filter-option-height-large-screen !default;
//$filter-option-margin-large-screen: null !default;
$filter-option-height: 40px;
//$filter-option-opacity: null !default;
//$filter-option-position: null !default;
//$filter-option-text-height-medium-screen: 18px !default;
//$filter-small-screen-container-background: $white !default;
//$filter-small-screen-header-height: 50px !default;
$filter-small-screen-options-max-height: rem-calc(1500);
//$left-facet-sort-bar-height: 40px !default;
//$left-facet-width: 25% !default;
//$top-bar-filter-facet-margin-top: 1em !default;
//$top-bar-filter-facet-Zindex: z-index(topbarfilter) !default;
$top-bar-sub-menu-4-facets-width: auto;
$top-bar-sub-menu-option-background-color: $white;
$filter-option-height-disabled-color: $medium-gray;
$filter-display: flex;
$filter-menu-title-font-size: rem-calc(12);
$filter-menu-title-font-size-large: rem-calc(12);
$filter-menu-title-font-size-xlarge: rem-calc(14);
$top-bar-sub-menu-margin: rem-calc(10);
$top-bar-sub-menu-width: auto;
$top-bar-sub-menu-min-width: 8vw;
$top-bar-sub-menu-min-width-xlarge: rem-calc(120);
$top-bar-sub-menu-max-height: rem-calc(55);
$top-bar-sub-menu-option-label-text-font-weight: lighter;
$filter-sub-menu-filter-container-active-width-large: rem-calc(320);
$filter-sub-menu-filter-container-border-large: rem-calc(1.5) solid $black;
$filter-menu-header-active-background: $white;
$filter-menu-header-active-border: rem-calc(3) solid $medium-gray;
$filter-menu-header-active-border-bottom: none;
$filter-menu-header-active-margin-large: rem-calc(1) rem-calc(-1);
$filter-menu-header-active-padding-top: rem-calc(18);
$filter-sub-menu-filter-container-active-margin-top-large: rem-calc(5);
$filter-sub-menu-filter-container-active-index-large: -1;
$filter-sub-menu-actions-clear-filter-background: none;
$filter-sub-menu-actions-clear-filter-color: $black;
$top-bar-sub-menu-option-label-text-text-transform-first: uppercase;
$top-bar-sub-menu-option-label-text-text-transform-second: none;

// ==========================================================================
//  View Layout Switch - global/themes/cabana/_view-layout-switch.scss
// ==========================================================================
//$inactiveColor: get-color(medium-dark-gray);
//$viewLayoutSwitchBottom: rem-calc(-3);
//$viewTextPositionTop: rem-calc(6);
//$viewTextFontSize: rem-calc(13);
//$viewTextFontWeight: 500;
//$view-layout-switch-button-border: 0;
//$view-layout-switch-button-border-color: get-color(secondary);
//$view-layout-switch-button-border-radius: 0;
//$view-layout-switch-button-border-style: none none solid none;
//$view-layout-switch-button-border-width: rem-calc(1);
$view-layout-switch-view-toggle-text-font-family: $body-font-family;
$view-layout-switch-button-background: $black;


$filter-sort-menu-white-space: nowrap;
$top-bar-sub-menu-option-border-bottom: $fontGreyLight rem-calc(1) solid;
$filter-padding: rem-calc(1);
$filter-z-index: 1;
$filter-float: none;
$filter-action-padding: rem-calc(1);
$filter-sub-menu-actions-clear-filter-padding: 0;
$filter-sub-menu-actions-clear-filter-margin-top: 0;
$filter-sub-menu-actions-clear-filter-font-size: rem-calc(14);
$filter-sub-menu-actions-clear-filter-font-weight: 300;
$filter-sub-menu-actions-clear-filter-line-height: 0;
$filter-sub-menu-actions-clear-filter-margin-left: rem-calc(7);
$filter-sub-menu-actions-button-primary-letter-spacing: rem-calc(1);
$filter-sub-menu-actions-button-primary-height: rem-calc(45);
$filter-sub-menu-actions-button-primary-margin-top: rem-calc(10);
$filter-sub-menu-actions-button-primary-medium-font-size: rem-calc(14);
$filter-sub-menu-actions-button-primary-medium-font-weight: 300;
$filter-sub-menu-actions-button-primary-medium-margin-bottom: rem-calc(2);
$filter-sub-menu-actions-button-primary-medium-margin-top: 0;
$filter-sub-menu-actions-button-primary-medium-height: rem-calc(40);
$filter-sub-menu-actions-action-apply-padding-medium: 0;
$filter-sub-menu-actions-action-apply-width: 100%;
$filter-sub-menu-actions-action-apply-width-medium: 70%;
$facet-sub-menu-actions-align-items: center;
$facet-sub-menu-actions-display: flex;
$facet-sub-menu-actions-padding-bottom: rem-calc(15) ;
$facet-sub-menu-actions-padding-top: rem-calc(20);
$filter-sub-menu-actions-margin-top: rem-calc(14);
$filter-sub-menu-actions-display: flex;
$filter-option-checkbox-height: rem-calc(16);
$filter-option-checkbox-width: rem-calc(16);
$filter-sub-menu-option-label-align-items: flex-start;
$filter-sub-menu-option-label-font-size: rem-calc(14);
$top-bar-sub-menu-option-span-margin-right: 0;
$top-bar-sub-menu-option-span-padding-left: 0;
$top-bar-sub-menu-option-label-text--num-products-padding: 0;


//---------------------------------------------------------------
// CMS Shop the Look
//
// Displays cards or grid tiles representing shop the look bundles
//---------------------------------------------------------------

$shop-the-look-background-color: $body-background;
$shop-the-look-nav-li-border-hover: 0;
$shop-the-look-nav-li-border-active: 0;
$shop-the-look-body-color: $light-gray;
$shop-the-look-nav-li-text-transform: none;
$shop-the-look-nav-li-active-text-decoration: underline;
$shop-the-look-nav-li-active-font-weight: bold;
$shop-the-look-nav-li-margin-large: 0 rem-calc(15) 0 0;
$shop-the-look-nav-width: auto;
$shop-the-look-nav-width-medium: auto;
$shop-the-look-nav-width-large: auto;
$shop-the-look-nav-font-size: rem-calc(20);
$shop-the-look-nav-li-button-border-radius: 0px;
$shop-the-look-nav-li-button-border: 0px !important;
$shop-the-look-nav-li-button-font-size: rem-calc(11);
$shop-the-look-nav-li-button-font-weight: 700;
$shop-the-look-nav-li-button-padding: 0;
$shop-the-look-nav-li-button-height: rem-calc(40);
$shop-the-look-header-color: $black;
$shop-the-look-header-font-size: rem-calc(40);
$shop-the-look-header-font-size-large: rem-calc(40);
$shop-thelook-header-h1-margin: rem-calc(21) 0 0 0;
$shop-the-look-subheader-text-transform: none;
$shop-the-look-subheader-font-weight: 300;
$shop-the-look-font-face: get-font('AkkuratPro');
$shop-the-look-overlay-button-font-size: rem-calc(16);
$shop-the-look-overlay-button-font-weight: 700;
$shop-the-look-grid-mobile-button-font-size: rem-calc(13);
$shop-the-look-grid-mobile-button-font-weight: 700;
$mobile-button-font-weight: 700;
$shop-the-look-nav-li-button-active-background: $black;
$shop-the-look-nav-li-button-active-colo: $black;
$shop-the-look-nav-li-button-hover-color: $white !important;
$shop-the-look-subheader-padding-top: rem-calc(10px);
$shop-the-look-header-text-transform: uppercase;
$shop-the-look-nav-li-active-color: $black;
$shop-the-look-nav-li-color: $black;
$shop-the-look-subheader-font-size: rem-calc(14);
$shop-the-look-grid-mobile-button-background: $black;
$shop-the-look-bundle-grid-width-medium: rem-calc(620);
$shop-the-look-grid-cell-display: none;
$shop-the-look-button-border-medium:  0 !important;
$shop-the-look-button-hover-border: 0 !important;
$shop-the-look-grid-mobile-button-background: $white;
$shop-the-look-grid-mobile-button-border: 0 !important;
$shop-the-look-grid-mobile-button-color: $black;
$shop-the-look-grid-mobile-button-font-weight: 300;
$shop-the-look-grid-mobile-button-letter-spacing: rem-calc(0.5);
$shop-the-look-grid-mobile-button-line-height: rem-calc(16);
$shop-the-look-grid-mobile-button-text-decoration: underline;
$shop-the-look-grid-mobile-button-text-display: flex;
$shop-the-look-header-font-size-medium:  rem-calc(44);
$shop-the-look-header-height-medium:  rem-calc(44);
$shop-the-look-header-letter-spacing-medium:  rem-calc(4.89);
$shop-the-look-header-letter-spacing: rem-calc(2.89);
$shop-the-look-header-line-height-medium:  rem-calc(53);
$shop-the-look-header-width-medium:  rem-calc(590);
$shop-the-look-height-medium: rem-calc(721.29);
$shop-the-look-li-font-weight: 300;
$shop-the-look-li-letter-spacing: rem-calc(1.44);
$shop-the-look-nav-li-active-font-size: rem-calc(13);
$shop-the-look-nav-li-active-height: rem-calc(13);
$shop-the-look-nav-li-active-letter-spacing: rem-calc(1.44);
$shop-the-look-nav-li-active-text-align: center;
$shop-the-look-nav-li-button-border: 0 !important;
$shop-the-look-nav-li-button-color: $white !important;
$shop-the-look-nav-li-font-size: rem-calc(13);
$shop-the-look-nav-li-height: rem-calc(13);
$shop-the-look-nav-ul-display-medium: flex;
$shop-the-look-subheader-font-size-medium: rem-calc(26);
$shop-the-look-subheader-font-weight-medium: 100;
$shop-the-look-subheader-height-medium: rem-calc(99);
$shop-the-look-subheader-height: rem-calc(90);
$shop-the-look-subheader-letter-spacing-medium: rem-calc(1);
$shop-the-look-subheader-letter-spacing: rem-calc(0.54);
$shop-the-look-subheader-line-height-medium: rem-calc(33);
$shop-the-look-subheader-line-height: rem-calc(18);
$shop-the-look-subheader-text-align-medium: center;
$shop-the-look-subheader-width-medium: rem-calc(590);
$shop-the-look-subheader-width: rem-calc(280);
$shop-the-look-width-medium: rem-calc(1260);
$shop-the-look-header-mobile-fixed-position: fixed;
$shop-the-look-header-mobile-fixed-top: rem-calc(80);
$shop-the-look-header-mobile-fixed-left: 0;
$shop-the-look-header-mobile-fixed-width: 100%;
$shop-the-look-header-mobile-fixed-z-index: 99;
$shop-the-look-header-mobile-fixed-background-color: $white;
$shop-the-look-overlay-button-text-decoration: underline;
$shop-the-look-heaer-mobile-fixed-border-bottom: rem-calc(1) solid;

// ==========================================================================
//  Top Facets - global/themes/cabana/_top-facets.scss
// ==========================================================================
$active-facets-height: rem-calc(30);
$active-facets-letter-spacing: rem-calc(1);
$face-menu-line-height: rem-calc(12);
$facet-container-background: $white;
$facet-menu-active-facets-border-color: get-color(primary);
$facet-menu-active-facets-check-left: rem-calc(5);
$facet-menu-active-facets-check-top: rem-calc(5);
$facet-menu-active-facets-font-size: rem-calc(13);
$facet-menu-background-active: $white;
$facet-menu-background: get-color(primary);
$facet-menu-background: get-color(primary);
$facet-menu-border-radius: 0;
$facet-menu-clear-filter-bg-color: transparent;
$facet-menu-clear-filter-font-size: rem-calc(13);
$facet-menu-clear-filter-margin: 0;
$facet-menu-clear-filter-padding: 0 rem-calc(5) 0 0;
$facet-menu-clear-filter-text-color: get-color(primary);
$facet-menu-color-active: get-color(primary);
$facet-menu-color: $white;
$facet-menu-dropdown-active-bg-color: get-color(primary);
$facet-menu-dropdown-active-border-color: get-color(primary);
$facet-menu-dropdown-active-border-radius: rem-calc(13);
$facet-menu-dropdown-active-color: $white;
$facet-menu-dropdown-active-padding: 0 rem-calc(10);
$facet-menu-dropdown-border-color: get-color(primary);
$facet-menu-dropdown-color-min-width: rem-calc(220);
$facet-menu-dropdown-disabled-color: $light-gray;
$facet-menu-dropdown-height: rem-calc(230);
$facet-menu-dropdown-line-separator-color: get-color(primary);
$facet-menu-dropdown-max-number-columns: 7;
$facet-menu-dropdown-padding-left: rem-calc(15);
$facet-menu-dropdown-padding-right: rem-calc(20);
$facet-menu-dropdown-width-per-column-color: rem-calc(50);
$facet-menu-dropdown-width-per-column: rem-calc(260);
$facet-menu-face-close-icon-font-size: rem-calc(10);
$facet-menu-filter-checkbox-color: get-color(primary);
$facet-menu-filter-height: rem-calc(32);
$facet-menu-font-size: rem-calc(16);
$facet-menu-sort-font-color: get-color(primary);
$facet-menu-sort-font-family: inherit;
$facet-menu-sticky-borders: 0;
$facet-menu-sticky-padding: rem-calc(18) 0;
$facet-menu-sticky-mobile-main-nav-offset-custom-search: rem-calc(53);
$facet-menu-sticky-mobile-main-nav-offset-custom: rem-calc(80);
$facet-menu-dropdown-active-border: rem-calc(2) solid get-color(primary);
$facet-menu-checked-border-color: get-color(primary);
$facet-menu-checked-before-background: get-color(primary);
$facet-menu-checked-before-outline: none;
$facet-menu-color-swatch-label-font-size: rem-calc(11);
$facet-menu-color-swatch-label-text-align: center;
$facet-menu-sticky-mobile-main-nav-offset: rem-calc(80);
$facet-menu-sticky-position: sticky;
$facet-menu-sticky-z-index: z-index(topnav) - 1;
$facet-active-filter-counter-display: none;
$off-canvas-color-swatch-selected-check-left: rem-calc(5);
$off-canvas-color-swatch-selected-check-top: rem-calc(5);
$off-canvas-content-width-large: 100%;
$off-canvas-content-width: 90%;
$off-canvas-facets-active-background: transparent;
$off-canvas-facets-active-border-width: rem-calc(2);
$off-canvas-facets-active-color: get-color(primary);
$off-canvas-facets-active-filters-border-bottom: rem-calc(1) solid $light-gray;
$off-canvas-facets-active-filters-close-icon-color: get-color(primary);
$off-canvas-facets-active-filters-collapsed-height: rem-calc(40);
$off-canvas-facets-active-filters-meta-margin-left: 0;
$off-canvas-facets-active-filters-meta-margin-top: rem-calc(15);
$off-canvas-facets-active-filters-sticky-bkgd: linear-gradient($white, $white);
$off-canvas-facets-active-filters-sticky-height: auto;
$off-canvas-facets-category-border-bottom: unset;
$off-canvas-filter-controls-button-background-color: get-color(primary);
$off-canvas-filter-controls-button-color: $white;
$off-canvas-filter-controls-button-text-transform: uppercase;
$off-canvas-filter-menu-size-menu-column-count: 1;
$off-canvas-filter-menu-sub-menu-header-margin: 0;
$off-canvas-filter-sub-menu-color-column-count: 5;
$off-canvas-filter-menu-size-menu-max-height: unset;
$off-canvas-filter-sub-menu-options-selection-color-margin-right: 0;
$off-canvas-filter-sub-menu-options-selection-color-padding: rem-calc(1) 0 0 0;
$off-canvas-filter-sub-menu-options-selection-color-width: rem-calc(45);
$off-canvas-filter-sub-menu-options-selection-label-display-active: block;
$top-bar-facets-background: $white;
$active-filters-list-display: inline-block;
$off-canvas-no-active-filters-display: none;
$off-canvas-active-filter-container-display: block;
$off-canvas-active-filters-meta-button-padding-right: unset;
$off-canvas-open-content-width: 100%;
$off-canvas-filter-menu-sub-menu-header-background-color: transparent;
$off-canvas-filter-menu-sub-menu-header-active-border-bottom: unset;
$off-canvas-filter-menu-title-text-color: $black;
$viewLayoutSwitchDisplay: none;
$viewLayoutSwitchDisplayMedium: inline-block;
$off-canvas-close-button-padding-top: rem-calc(7);
$off-canvas-active-filters-list-container-margin-bottom: 0;
$off-canvas-active-filters-list-container-margin-top: rem-calc(-4);
$off-canvas-active-filters-list-container-padding-left: 0;
$off-canvas-active-filters-list-container-max-width: 100%;
$off-canvas-filter-sub-menu-options-selection-label-margin-active: rem-calc(5) 0 0 0;
$off-canvas-facets-active-font-weight: normal;
$off-canvas-filter-menu-sub-menu-sort-radio-margin-top: rem-calc(16);
$off-canvas-active-filters-meta-button-font-size: rem-calc(14);
$off-canvas-active-filters-meta-button-font-weight: 400;
$off-canvas-active-filters-meta-button-padding-left: 0;
$off-canvas-filter-mobile-container-label-font-size: rem-calc(14);
$off-canvas-filter-sub-menu-actions-size: rem-calc(12);
$off-canvas-filter-sub-menu-actions-button-secondary-size: rem-calc(14);
$off-canvas-filter-sub-menu-actions-button-secondary-weight: 400;
$off-canvas-filter-menu-sub-menu-header-type-font-size: rem-calc(18);
$off-canvas-filter-menu-sub-menu-header-type-font-weight: normal;
$off-canvas-filter-menu-sub-menu-header-type-line-height: rem-calc(22);
$off-canvas-filter-menu-title-text-font-size: rem-calc(18);
$off-canvas-filter-menu-title-text-font-weight: normal;
$off-canvas-filter-menu-title-text-line-height: rem-calc(22);

$facet-menu-color-swatch-inverse-check-color: $black;
$facet-menu-clear-all-button-icon-padding: 0 0 0 rem-calc(4);
$filter-menu-category-background-color: get-color(whisper);
$filter-sub-menu-actions-mobile-background: get-color(whisper);
$filter-sub-menu-actions-mobile-padding: rem-calc(16) rem-calc(10);
$filter-menu-category-line-height: rem-calc(26);

$product-sort-radio-select-margin-top: rem-calc(15);
$active-filters-clear-all-link-color: $black;
$active-filters-clear-all-link-font-size: rem-calc(12);
$active-filters-clear-all-link-weight: 400;

$viewMoreResultsMarginMedium: 0;
$viewMoreResultsGridMedium: 12;
$viewMoreResultsMargin: 0 rem-calc(85) 0 rem-calc(85);
$viewMoreResultsGrid: 6;

$product-block-views-container-skeleton-width: rem-calc(324);
$product-block-views-container-skeleton-height: rem-calc(379);
$product-block-views-container-skeleton-display: block;
$product-block-views-container-skeleton-background: get-color(white-smoke);
$history-lowest-price-show-percentage-price-color: get-color(burnt-orange);
$tooltipster-lowest-price-history-font-family: get-font('AkkuratPro');
$topnav-transparent-header-bg: linear-gradient(180deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%);

$lowest-history-price-tooltip-color: get-color(feta);
$history-lowest-price-show-font-family: get-font('AkkuratPro') !important;
$history-current-price-font-size-small: rem-calc(16) !important;
$history-current-price-font-size-medium: rem-calc(18) !important;
$history-current-price-font-weight: 700;
$history-lowest-price-show-title-font-weight: 300 !important;
$price-total-min-width-small: auto;
$price-total-min-width-medium: auto;
$price-total-margin-right-small: rem-calc(4);
$price-total-margin-right-medium: rem-calc(4);
$price-total-font-weight-small: normal !default;
$price-total-font-weight-medium: 700 !important;
$price-total-percentage-font-weight-small: normal !important;
$price-total-percentage-font-weight-medium: 700 !important;
$history-lowest-price-show-font-weight: 500 !important;
$history-lowest-price-product-content-info-price-padding: 0 !important;
$history-current-price-line-height-small: rem-calc(22s) !important;
$history-lowest-price-show-font-size-small: rem-calc(14) !important;
$product-price-span-display: inline-flex !important;
$history-lowest-price-show-title-margin-right-small: rem-calc(8);
$history-lowest-price-show-display: flex !important;
$price-total-color: get-color(nero) !important;
$product-price-30days-lowest-price-show-margin: rem-calc(4) 0 0 0;
$lowest-discount-price-margin-small: rem-calc(8) 0 0 0;
$history-price-enabled-product-block-range-price-width: fit-content !important;
$product-block-views-container-min-height: rem-calc(226);
$product-block-views-container-min-width: auto;
$topnav-logo-mobile: unset;
$topnav-promobar-innercontainer-position-mobile: relative;

// DO NOT ADD ANYTHING BELOW THIS IMPORT
// See _variables-alt.scss for a detailed
// explanation
// -------------------------------------
@import 'variables-alt';
// -------------------------------------
