// ***************************************************************************************
// * VFDP Visibility Styles
// * Structured based on Foundation Visibility
// *
// * These work by leveraging classes added to the <html> tag
// * via javascript, based on the user detection provided from WCS.
// ***************************************************************************************

$user-group-classes: ('guest', 'registered', 'loyalty');

/// Show an element by default, and hide it for a certain user group
/// @param {Keyword} $group - User group to use.
@mixin hide-for-group($group) {
  .vfdp-user-#{$group} & {
    display: none !important; // sass-lint:disable-line no-important // Match Foundation visibility classes
  }
}

/// Hide an element by default, and unhide it only for a certain user group
/// @param {Keyword} $group - User group to use.
@mixin show-for-group($group) {
  display: none !important; // sass-lint:disable-line no-important // Match Foundation visibility classes

  .vfdp-user-#{$group} & {
    display: inherit !important; // sass-lint:disable-line no-important // Match Foundation visibility classes
  }
}


@mixin vfdp-visibility-classes {

  // Group-based visibility classes
  @each $group in $user-group-classes {
    .hide-for-#{$group} {
      @include hide-for-group($group);
    }

    .show-for-#{$group} {
      @include show-for-group($group);
    }
  }
}
