// ********************************
// *
// * Image Hover Captions
// *
// * This allows for images that have a content box overlayed as provided via the Image Overlay
// * component.
// *
// *
// * ---- This depends on cms/widgets/_media.scss ----
// *
// * Usage:
//	<div class="media image-overlay">
//		<--- picturefill image inserts here-->
//		<div class="overlay">
//			<div class="wrapper">
//				<div class="inner">
//					centered overlay content goes here
//				</div>
//			</div>
//		</div>
//	</div>
// *
// ********************************

$image_overlay_inset_small: 5% !default;
$image_overlay_inset_medium: $image_overlay_inset_small * 2 !default;
$image_overlay_inset_large: $image_overlay_inset_medium * 2 !default;
$image_overlay_padding: $column-gutter !default;
$image_overlay_background: $body-background !default;
$image_overlay_color: $body-font-color !default;
$image_overlay_headline_color: $header-color !default;
$image_overlay_text_align: left !default;

// We use this mixin to create image hover captions.
@mixin cms-image-overlay() {

	.media.image-overlay {

		@include media;

		.overlay .wrapper {
				background: transparent;
				padding: $image_overlay_inset_small;

				.inner {
					background: $image_overlay_background; // This can be overwritten by the Content Editor
					color: $image_overlay_color;
					padding: $image_overlay_padding;
					text-align: $image_overlay_text_align;
					//p {}

					//.button {}
				}

				h1,
				h2,
				h3,
				h4,
				h5 {
					color: $image_overlay_headline_color;
				}

				@include breakpoint(medium) {
					padding: $image_overlay_inset_medium;
				}

				@include breakpoint(large) {
					padding: $image_overlay_inset_large;
				}
		}
	}
}
