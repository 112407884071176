// =================
// Top Mini Cart
// -----------------
$topPanelEnabled: false !default;
$topPanelContentHeadMargin: 7px !default;
$topPanelShopCartPadding: 10px 25px !default;
$topPanelShopCartPaddingTablet: 10px 15px !default;
$topPanelIconShopcartFontSize: em-calc(16) !default;
$topPanelIconShopcartPadding: 0 5px 0 0 !default;
$topPanelShopCartActiveColor: $topbar-upper-font-color !default;
$topPanelShopCartStyleColor: $brandColorPalette2 !default;
$topPanelShopCartStyleBackground: $brandColorPalette1 !default;
$topPanelBorderTop: 3px solid $brandColorPalette1 !default;
$topPanelUtilityLinkPaddingTop: 10px !default;
$topPanelCloseIconDisplay: none !default;
$topPanelBoxShadow: null !default;

$topMiniCartPadding: 15px !default;
$topMiniCartBorder: null !default;
$topPanelBoxShadow: null !default;
$topMiniCartFooterMargin: 14px 0 !default;
$topMiniCartContentHeadDisplay: null !default;
$topMiniCartSubtotalContainerOrder: 2 !default;
$topMiniCartSubTotalTextAlign: left !default;
$topMiniCartSubTotalPriceFloat: right !default;
$topMiniCartSubTotalPadding: 0 0 20px !default;
$topMiniCartFontFamily: null !default;
$topMiniCartSubTotalFontWeight: null !default;
$topMiniCartPanelFontSize: null !default;
$topMiniCartSubTotalPriceFontSize: null !default;
$topMiniCartSubTotalTextTransform: uppercase !default;
$topMiniCartButtonWidth: null !default;
$topMiniCartButtonPadding: null !default;
$topMiniCartPanelWidthSmall: 100% !default;
$topMiniCartButtonMargin: null !default;
$topMiniCartFontSize: null !default;
$topMiniCartButtonLetterSpacing: 1.5px !default;
$topMiniCartIconCloseDisplay: null !default;
$topMiniCartButtonFontSize: null !default;
$topMiniCartListBorder: $topMiniCartCartBorder !default;
$topMiniCartLineSepBorderTop: $topMiniCartSeparator !default;
$topMiniCartNavNextBorderBottom: $topMiniCartSeparator !default;
$topMiniCartNavNextMargin: 8px 0 2px !default;
$topMiniCartNavNextMarginBottom: 0 !default;
$topMiniCartNavNextMarginTop: null !default;
$topMiniCartNavNextBtnPaddingTop: null !default;
$topMiniCartLineSepBackground: null !default;
$topMiniCartLineSepMargin: null !default;
$topMiniCartItemPriceColor: null !default;
$topMiniCartItemFontWeight: null !default;
$topMiniCartItemSeparatorMargin: 20px 0 0 !default;
$topMiniCartItemSeparatorLastOfTypeDisplay: null !default;
$topMiniCartRemoveItemTextColor: null !default;
$topMiniCartRemoveItemTextFontSize: null !default;
$topMiniCartRemoveItemTextPadding: null !default;
$topMiniCartRemoveItemTextPosition: null !default;
$topMiniCartRemoveItemTextPositionRight: 0 !default;
$topMiniCartRemoveItemTextWidth: null !default;
$topMiniCartNotificationBackgroundColorSM: transparent !default;
$topMiniCartNotificationFontColorSM: $miniListNotificationMobileColor !default;
$topMiniCartNotificationBackgroundColor: get-color(success) !default;
$topMiniCartNotificationFontColor: $fontSuccess !default;
$topMiniCartNotificationFontSize: null !default;
$topMiniCartNotificationMarginTop: null !default;
$topMiniCartNotificationFontSizeMedium: null !default;
$topMiniCartNotificationMarginTopMedium: null !default;
$topMiniCartNotificationTextAlign: null !default;
$topMiniCartEspotMarginBottom: null !default;
$topMiniCartEspotBorderBottom: null !default;
$topMiniCartEspotBorderTop: null !default;
$topMiniCartEspotOrder: 1 !default;
$topMiniCartEspotPadding: null !default;
$topMiniCartEspotMargin: null !default;
$topPanelMarginSmall: 0 !default;
$topPanelShoppingBagLinkPadding: 0 !default;
$topMiniCartEspotBackgroundColor: null !default;
$topMiniCartItemColumnWidth: null !default;
$topMiniCartItemMarginTop: null !default;
$topMiniCartItemColPaddingLeft: null !default;
$topMiniCartItemColPaddingRight: null !default;
$miniCartIconClosePadding: null !default;
$miniCartIconCloseTextAlign: null !default;
$miniCartCaretBorder: null !default;

@if($topPanelEnabled) {
	#master-header {
		.top-bar .upper {
			li {
				a,
				.region {
					padding-top: $topPanelUtilityLinkPaddingTop;
				}
			}

			.unav-shoppingbag {
				border-top: 0;
				padding: $topPanelShopCartPadding;

				@include breakpoint(medium) {
					padding: $topPanelShopCartPaddingTablet;
				}

				@include breakpoint(large) {
					padding: $topPanelShopCartPadding;
				}

				a {
					padding: $topPanelShoppingBagLinkPadding;
				}

				&.active a {
					color: $topPanelShopCartActiveColor;
				}

				&.has-products {
					background-color: $topPanelShopCartStyleBackground;
					border: 1px solid $topPanelShopCartStyleBackground;
					border-top: 0;

					a {
						background-color: $topPanelShopCartStyleBackground;
						color: $topPanelShopCartStyleColor;
					}
				}
			}

			.icon-shopcart::before {
				font-size: $topPanelIconShopcartFontSize;
				padding: $topPanelIconShopcartPadding;
			}
		}
	}

	.mini-cart-section.l-top, // ECOM legacy TopNav
	.topnav-minicart-panel { // CMS TopNav
		background-color: $miniListNotificationMobileBkgdColor;
		border: $topMiniCartBorder;
		border-top: 0;
		box-shadow: $topPanelBoxShadow;
		height: auto;
		margin: $topPanelMarginSmall;
		position: absolute;
		top: $mobile-topbar-panel-offset;
		width: $topMiniCartPanelWidthSmall;

		@include breakpoint(medium) {
			background-color: $topMiniCartBackgroundColor;
			border-top: $topPanelBorderTop;
			box-shadow: $topPanelBoxShadow;
			height: auto;
			position: absolute;
			right: 0;
			top: $miniCartPanelTop;
			width: $topMiniCartPanelWidth;
		}

		&::before {
			border: $miniCartCaretBorder;
		}

		&.hide {
			display: block;
			visibility: hidden;
		}

		.mini-cart-action {
			color: $button-color;
			font-size: $topMiniCartButtonFontSize;
			letter-spacing: $topMiniCartButtonLetterSpacing;
			margin: $topMiniCartButtonMargin;
			padding: $topMiniCartButtonPadding;
			width: $topMiniCartButtonWidth;
		}

		.mini-cart-multi-button-container {
			justify-content: center;

			.mini-cart-action {
				font-size: 1rem;
				padding: 15px 25px;
				width: auto;

				&:last-child {
					margin-left: 15px;
				}
			}
		}

		.cart-list { // styling for top-panel
			border: $topMiniCartListBorder;
			border-top: 0;
			padding: $topMiniCartPadding;
			position: relative;
			width: 100%;



			.mini-cart-icon-close {
				display: $topMiniCartIconCloseDisplay;
			}

			.content-head {
				display: $topMiniCartContentHeadDisplay;
				margin-bottom: $topPanelContentHeadMargin;
			}

			.mini-cart-remove-item-text {
				color: $topMiniCartRemoveItemTextColor;
				font-size: $topMiniCartRemoveItemTextFontSize;
				padding: $topMiniCartRemoveItemTextPadding;
				position: $topMiniCartRemoveItemTextPosition;
				right: $topMiniCartRemoveItemTextPositionRight;
				top: 0;
				width: $topMiniCartRemoveItemTextWidth;
			}

			.content-foot {
				margin: $topMiniCartFooterMargin;
			}

			.section-title {
				color: $topMiniCartSectionTitleColor;
			}

			.page-title {
				color: $topMiniCartPageTitleColor;
			}

			.waved-line-sep {
				background: $topMiniCartLineSepBackground;
				border-top: $topMiniCartLineSepBorderTop;
				display: $miniCartWavedSepDisplay;
				margin: $topMiniCartLineSepMargin;
				order: 4;
			}

			.cart-item-nav {
				@include grid-column-collapse;
				float: left;
				width: 100%;

				&.next {
					border-bottom: $topMiniCartNavNextBorderBottom;
					margin: $topMiniCartNavNextMargin;
					order: 7;
				}

				&.prev {
					bottom: 15px;
					margin-bottom: $topMiniCartNavNextMarginBottom;
					margin-top: $topMiniCartNavNextMarginTop;
					order: 5;
				}

				.next-btn {
					padding-top: $topMiniCartNavNextBtnPaddingTop;
				}

				.prev-btn {
					margin: 5px auto;
				}

				.nav-button:hover {
					background-color: $topMiniCartBackgroundColor;
				}
			}

			.cart-item-sep {
				margin: $topMiniCartItemSeparatorMargin;
			}

			//Last Item in List on Mini Cart
			.mini-cart-item:last-of-type {
				.cart-item-sep {
					display: $topMiniCartItemSeparatorLastOfTypeDisplay;
				}
			}

			.cart-item-col {
				padding-left: $topMiniCartItemColPaddingLeft;
				padding-right: $topMiniCartItemColPaddingRight;
				width: grid-column(4.5);

				.item-name a {
					@include setFontStyle($miniCartItemTitleFontStyle);
				}

				.item-price {
					color: $topMiniCartItemPriceColor;
					font-weight: $topMiniCartItemFontWeight;
				}

				.mini-cart-item-name {
					margin-top: $topMiniCartItemMarginTop;
					width: $topMiniCartItemColumnWidth;
				}
			}

			.product-info {
				float: left;
				font-family: $miniCartFontFamily;
				padding: $topMiniCartProductInfoPadding;
				width: grid-column(7.5);
			}

			.mini-cart-subtotal-container {
				order: $topMiniCartSubtotalContainerOrder;
			}

			.cart-subtotal {
				@include grid-column-collapse;
				float: left;
				font-family: $topMiniCartFontFamily;
				font-size: $topMiniCartPanelFontSize;
				font-weight: $topMiniCartSubTotalFontWeight;
				padding: $topMiniCartSubTotalPadding;
				text-align: $topMiniCartSubTotalTextAlign;
				text-transform: $topMiniCartSubTotalTextTransform;
				width: 100%;

				.mini-cart-subtotal-price {
					float: $topMiniCartSubTotalPriceFloat;
					font-size: $topMiniCartSubTotalPriceFontSize;
				}
			}
		}

		.notification {
			background: $topMiniCartNotificationBackgroundColorSM;
			box-sizing: border-box;
			color: $topMiniCartNotificationFontColorSM;
			font-size: $topMiniCartNotificationFontSize;
			margin-top: $topMiniCartNotificationMarginTop;
			padding: 30px 15px 20px;

			@include breakpoint(medium) {
				background: $topMiniCartNotificationBackgroundColor; //$backgroundColorSuccess
				color: $topMiniCartNotificationFontColor; // $fontSuccess
				font-size: $topMiniCartNotificationFontSizeMedium;
				margin-top: $topMiniCartNotificationMarginTopMedium;
				padding: 15px 20px;
			}

			.close-btn {
				color: $miniListNotificationMobileCloseBtnColor;
				cursor: pointer;
				display: block;
				position: absolute;
				right: 10px;
				top: 10px;
				z-index: 100;

				@include breakpoint(medium) {
					display: none;
				}
			}

			.icon-close {
				padding: $miniCartIconClosePadding;
				text-align:  $miniCartIconCloseTextAlign;
			}

			.product-name,
			.shopping-cart-text {
				color: $miniListNotificationMobileItemAttributeColor;
			}
		}

		.content {
			padding: 0;
			width: 100%;
		}

		.mini-cart-espot {
				background-color: $topMiniCartEspotBackgroundColor;
				border-bottom: $topMiniCartEspotBorderBottom;
				border-top: $topMiniCartEspotBorderTop;
				margin: $topMiniCartEspotMargin;
				margin-bottom: $topMiniCartEspotMarginBottom;
				order: $topMiniCartEspotOrder;
				padding: $topMiniCartEspotPadding;

			.mini-cart-promo-header {
				text-align: center;
			}

			p {
				margin-bottom: 0;
			}
		}

		article {
			font-size: $topMiniCartFontSize;
			left: 0;
		}
	}

	.mini-cart-notification {
		.icon-close {
			display: $topPanelCloseIconDisplay;
		}

		.notification-text {
			h2 {
				text-align: $topMiniCartNotificationTextAlign;
			}
		}
	}

	.topnav-minicart-panel {
		animation-duration: 250ms;
		animation-iteration-count: 1;
		animation-timing-function: ease-in;

		&.fade-in {
			animation-name: fadeInOpacity;
			opacity: 1;
		}

		&.fade-out {
			animation-name: fadeOuOpacity;
			opacity: 0;
		}

		@keyframes fadeInOpacity {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}

		@keyframes fadeOutOpacity {
			0% {
				opacity: 1;
			}

			100% {
				opacity: 0;
			}
		}
	}
}
