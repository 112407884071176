// ==================================
// Pop-up Loading Spinner
// ==================================
@import 'recursive-map-merge';

// Base page-overlay spinner
$loading-spinner-width: 200px !default;
$loading-spinner-height: $loading-spinner-width !default;
$loading-spinner-border-radius: 15px !default;
$loading-spinner-background: #656565 !default;
$loading-spinner-color: #fff !default;
$loading-spinner-opacity: 0.9 !default;
$loading-spinner-base-top: 50% !default;
$loading-spinner-text-bottom: 30px !default; // Position the text label
$loading-spinner-indicator-top: 40px !default; // Position the spinning element
$loading-spinner-indicator-size: 74px !default; // Size the spinning element
// Smaller spinner used in panels like wishlist
$loading-spinner-inline-base-top: null !default;
$loading-spinner-inline-width: $loading-spinner-width/2 !default;
$loading-spinner-inline-height: $loading-spinner-width/2 !default;
$loading-spinner-inline-border-radius: $loading-spinner-border-radius !default;
$loading-spinner-inline-background: null !default;
$loading-spinner-inline-text-bottom: $loading-spinner-text-bottom/2 !default; // Position the text label
$loading-spinner-inline-indicator-top: 15px !default; // Position the spinning element
$loading-spinner-inline-indicator-size: 40px !default; // Size the spinning element
$loading-spinner-font-baseline: (
  'font': (
    'font-family': $body-font-family,
    'font-size': em-calc(30px),
    'font-weight': 700,
    'font-style': normal
  )
);
$loading-spinner-font: () !default;
$loading-spinner-inline-font-size: 1.5em !default;
$loading-spinner-image: url('../images/svg/spinner.svg') !default;
$loading-spinner-text-display: null !default;
$dot-wave-color: $white !default;
$dot-wave-top: null !default;
$dot-wave-icon-done: null !default;
$dot-wave-icon-done-size-mobile: inherit !default;
$dot-wave-icon-done-size: inherit !default;
$dot-wave-icon-done-color: inherit !default;
$dot-wave-done-font-size-mobile: null !default;
$dot-wave-done-font-size: null !default;


@mixin loading-spinner-text($width: $loading-spinner-width, $bottom: $loading-spinner-text-bottom) {
	@include setFontStyle(recursive-map-merge($loading-spinner-font-baseline, $loading-spinner-font));

	bottom: $bottom;
	color: $loading-spinner-color;
	position: absolute;
	display: $loading-spinner-text-display;
	text-align: center;
	width: $width;

	// Make the font smaller for inline spinners
	.inline & {
		bottom: $loading-spinner-inline-text-bottom;
		font-size: $loading-spinner-inline-font-size;
		width: $loading-spinner-inline-width;
	}
}

// Used to generated a loading spinner for a page overlay
// There can only be one of these on a page
@mixin loading-spinner-base(
	$width: $loading-spinner-width,
	$height: $loading-spinner-height,
	$border-radius: $loading-spinner-border-radius,
	$background: $loading-spinner-background,
	$text-bottom: $loading-spinner-text-bottom,
	$position: fixed,
	$opacity: $loading-spinner-opacity,
	$top: $loading-spinner-base-top
) {
	background: $background;
	border-radius: $border-radius;
	height: $height;
	left: 50%;
	opacity: $opacity;
	position: $position;
	top: $top;
	transform: translate(-50%, -50%);
	width: $width;
	z-index: 99999;

	&.inline {
		background: $loading-spinner-inline-background;
		height: $loading-spinner-inline-height;
		position: absolute;
		top: $loading-spinner-inline-base-top;
		width: $loading-spinner-inline-width;
	}
}

@mixin loading-background {
	height: 100%;
    left: 50%;
    position: fixed;
    top: 50%;
    width: 100%;
    z-index: 99999;
    transform: translate(-50%, -50%);
}

// Used to generate the graphic that has an animated spin
@mixin loading-spinner-indicator {
	background: $loading-spinner-image center/contain no-repeat;
	display: block;
	height: $loading-spinner-indicator-size;
	left: 50%;
	position: absolute;
	top: $loading-spinner-indicator-top;
	transform: translateX(-50%);
	width: $loading-spinner-indicator-size;

	.inline & {
		height: $loading-spinner-inline-indicator-size;
		top: $loading-spinner-inline-indicator-top;
		width: $loading-spinner-inline-indicator-size;
	}
}


@mixin loading-spinner {
	// Page overlay version
	.background-loader {
		@include loading-background;
	}
	
	.loading-spinner {
		@include loading-spinner-base;
	}

	.activity-indicator {
		@include loading-spinner-indicator;
	}

	// Style the text label shown in the spinner
	.loading-spinner-text {
		@include loading-spinner-text;
	}
}

@keyframes dotWave {
	0% {
	  	box-shadow: rem-calc(485) rem-calc(2) 0 0 rgba($dot-wave-color, 0.75), rem-calc(500) 0 0 0 $dot-wave-color, rem-calc(515) 0 0 0 $dot-wave-color;
    }

	20% {
	  	box-shadow: rem-calc(485) rem-calc(4) 0 0 rgba($dot-wave-color, 0.75), rem-calc(500) rem-calc(2) 0 0 rgba($dot-wave-color, 0.75), rem-calc(515) 0 0 0 $dot-wave-color;
    }

	40% {
	  	box-shadow: rem-calc(485) rem-calc(4) 0 0 rgba($dot-wave-color, 0.75), rem-calc(500) rem-calc(4) 0 0 rgba($dot-wave-color, 0.75), rem-calc(515) rem-calc(2) 0 0 rgba($dot-wave-color, 0.75);
    }

	60% {
	  	box-shadow: rem-calc(485) rem-calc(2) 0 0 rgba($dot-wave-color, 0.75), rem-calc(500) rem-calc(4) 0 0 rgba($dot-wave-color, 0.75), rem-calc(515) rem-calc(4) 0 0 rgba($dot-wave-color, 0.75);
    }

	80% {
	  	box-shadow: rem-calc(485) 0 0 0 $dot-wave-color, rem-calc(500) rem-calc(2) 0 0 rgba($dot-wave-color, 0.75), rem-calc(515) rem-calc(4) 0 0 rgba($dot-wave-color, 0.75);
    }

	100% {
	  	box-shadow: rem-calc(485) 0 0 0 $dot-wave-color, rem-calc(500) 0 0 0 $dot-wave-color, rem-calc(515) rem-calc(2) 0 0 rgba($dot-wave-color, 0.75);
    }
}

.dot-wave {
	animation: dotWave 0.5s infinite linear;
	background-color: transparent;
	border-radius: rem-calc(5);
	box-shadow: rem-calc(485) 0 0 0 $dot-wave-color, rem-calc(500) 0 0 0 $dot-wave-color, rem-calc(515) 0 0 0 $dot-wave-color;
	color: $dot-wave-color;
	height: rem-calc(10);
	left: rem-calc(-500);
	margin: rem-calc(3) auto;
	position: relative;
	width: rem-calc(10);

	@include breakpoint(medium) {
		margin: rem-calc(5) auto;
	}
}

.dot-wave-position {
	top: $dot-wave-top;
}

.circular-loader {
	background: url('../images/svg/circular-loader.svg');
	display: none;
	height: rem-calc(24);
	width: rem-calc(24);

	@include breakpoint(medium) {
	  	height: rem-calc(30);
	  	width: rem-calc(30);
	}
}

.dot-wave-loading {
	font-size: 0 !important;
	.dot-wave {
		display: inline-block;
		vertical-align: middle;
		bottom: 3px;
		height: rem-calc(8);
		width: rem-calc(8);
	}
}

.dot-wave-done {
	position: relative;
	.dot-wave {
		display: none;
	}
	font-size: $dot-wave-done-font-size-mobile;
	animation: dotWaveDoneTextShowMobile 3s;
	@include breakpoint(medium) {
		font-size: $dot-wave-done-font-size;
		animation: dotWaveDoneTextShow 3s;
	}

	@if ($dot-wave-icon-done) {
		.icon-done {
			position: absolute;
			left: 0;
			right: 0;
			display: inline-block;
			vertical-align: middle;
			font-size: $dot-wave-icon-done-size-mobile;
			color: $dot-wave-icon-done-color;
			animation: dotWaveDoneIconZoomIn 0.5s, dotWaveDoneIconHide 3s;
			visibility: hidden;
			&::before {
				@include icomoonFont($dot-wave-icon-done);
			}

			@include breakpoint(medium) {
				font-size: $dot-wave-icon-done-size;
			}
		}
	}
}

@keyframes dotWaveDoneIconZoomIn {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes dotWaveDoneIconHide {
	0% {
		visibility: visible;
	}
	100% {
		visibility: hidden;
		display: none;
	}
}

@keyframes dotWaveDoneTextShowMobile {
	0% {
		font-size: 0;
	}
	99.9% {
		font-size: 0;
	}
	100% {
		font-size: $dot-wave-done-font-size-mobile;
	}
}

@keyframes dotWaveDoneTextShow {
	0% {
		font-size: 0;
	}
	99.9% {
		font-size: 0;
	}
	100% {
		font-size: $dot-wave-done-font-size;
	}
}
