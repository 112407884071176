//
// Search results
//

@mixin napa-search-results-query {
  color: get-color(burnt-orange);
  font-weight: bold;
}

@mixin napa-search-results-summary {
  @include grid-column(12);
}

@mixin napa-search-results-summary-heading {
  background: $white;
  border-bottom: 1px solid $light-gray;
  text-align: center;
  font-size: 26px;
  padding: 2em;
  font-weight: normal;
  text-transform: lowercase;
}

@mixin napa-search-results-filters {
  margin-top: 10px;
  min-height: 30px;

  div,
  ul,
  li {
    display: inline-block;
  }

  ul {
    margin: 0;
    max-width: 50%;

    @include breakpoint(medium) {
      max-width: 70%;
    }
  }

  li {
    font-size: 16px;
    font-weight: bold;
    text-transform: lowercase;

    &.active {
      color: get-color(burnt-orange);
    }

    &::after {
      content: '\00a0-\00a0';
      color: $black;
      // padding: 0 1.1em;
    }

    &:last-child {
      &::after {
        content: '';
      }
    }
  }

}

@mixin napa-search-results-sort {
  float: right;
  width: 50%;
  position: relative;
  z-index: 1;

  @include breakpoint(large) {
    width: 28%;
  }

  .sort-by {

    .prefix {
      border: 1px solid $medium-gray;
      border-right: 0;
      color: $medium-gray;
      padding-left: $column-gutter/2;
      white-space: nowrap;
    }

    .dropdown.custom {
      border-left: 0;
    }

    color: $black;
    height: 100%;
    position: absolute;
    right: $column-gutter/2;
    top: 0;
    width: 100%;
  }
}

@mixin napa-search-results-article {
  padding-bottom: 2.5em;

  .thumb {
    display: block;
    margin-bottom: 1em;
  }

  h5 {
    font-size: 18px;
    margin-bottom: 1em;

    a {
      text-decoration: none;
    }
  }

  p {
    font-size: 12px;
    margin: 0;
  }

  .read-more-link {
    font-size: 12px;
  }
}

@mixin napa-search-results-load-more {
  .num-articles {
    display: none;
  }
}

@mixin napa-search-results {
  .searchresults {

    .search-results-summary {
      @include napa-search-results-summary;

      h1 {
        @include napa-search-results-summary-heading;
      }
    }

    .search-term {
      @include napa-search-results-query;
    }

    .wall-filters {
      @include napa-search-results-filters;
    }

    // Select field to filter search results
    .sort-by-section {
      @include napa-search-results-sort;
    }

    // Results
    .results {
      article {
        @include napa-search-results-article;
      }
    }

    .load-more-content {
      @include napa-search-results-load-more;
    }
  }
}
