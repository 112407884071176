$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon/fonts" !default;

$icon-clear-search: "\e931";
$icon-slim-search: "\e932";
$icon-menu: "\e930";
$icon-shopcart: "\e92e";
$icon-search: "\e92f";
$icon-single-square: "\e92a";
$icon-grid-icon: "\e92b";
$icon-paypal: "\e929";
$icon-hand-payments: "\e927";
$icon-favorite: "\e925";
$icon-favorite-full: "\e926";
$icon-wishlist: "\e925";
$icon-wishlist-full: "\e926";
$icon-upload: "\e924";
$icon-reset: "\e921";
$icon-size-chart-ruler: "\e928";
$icon-accessibility: "\e922";
$icon-controls-pause: "\e92c";
$icon-controls-play: "\e92d";
$icon-recycle: "\e923";
$icon-add-user: "\e920";
$icon-bookmark: "\e909";
$icon-list: "\e900";
$icon-map: "\e901";
$icon-play: "\e037";
$icon-circle-minus: "\e15d";
$icon-circle-plus: "\e3ba";
$icon-refresh: "\e5d5";
$icon-share: "\e600";
$icon-mail: "\e601";
$icon-facebook: "\e602";
$icon-twitter: "\e603";
$icon-tumblr: "\e604";
$icon-pinterest: "\e605";
$icon-googleplus: "\e606";
$icon-vimeo: "\e609";
$icon-print: "\e60b";
$icon-arrow-right-indicator: "\e60c";
$icon-arrow-left-indicator: "\e60d";
$icon-arrow-down-indicator: "\e60e";
$icon-arrow-up-indicator: "\e60f";
$icon-close: "\e610";
$icon-search-old: "\e611";
$icon-arrow-down-indicator-open: "\e612";
$icon-arrow-up-indicator-open: "\e613";
$icon-arrow-right-indicator-open: "\e614";
$icon-arrow-left-indicator-open: "\e615";
$icon-flickr: "\e616";
$icon-picasa: "\e617";
$icon-wordpress: "\e618";
$icon-blogger: "\e619";
$icon-star: "\e61a";
$icon-wanelo: "\e61b";
$icon-check: "\e61c";
$icon-location: "\e61d";
$icon-instagram: "\e622";
$icon-menu-old: "\e626";
$icon-minus: "\e629";
$icon-plus: "\e62a";
$icon-mobile: "\e902";
$icon-shrink: "\e903";
$icon-info: "\e906";
$icon-lock: "\e907";
$icon-handset: "\e908";
$icon-address: "\e90a";
$icon-payment: "\e90b";
$icon-edit: "\e90c";
$icon-remove: "\e90d";
$icon-help: "\e90e";
$icon-clock: "\e90f";
$icon-sign-out: "\e910";
$icon-refer-a-friend: "\e911";
$icon-chat: "\e912";
$icon-gift: "\e913";
$icon-download: "\e914";
$icon-credit-card: "\e915";
$icon-save: "\e916";
$icon-applepay: "\e917";
$icon-enlarge: "\e918";
$icon-arrow-right: "\e919";
$icon-arrow-down: "\e91a";
$icon-arrow-up: "\e91b";
$icon-shopcart-old: "\e91c";
$icon-account: "\e91d";
$icon-profile: "\e904";
$icon-arrow-left: "\e91e";
$icon-youtube: "\e91f";
$icon-tiktok: "\e934";
$icon-hour-glass: "\e979";
$icon-checkmark: "\ea10";
$icon-sign-in: "\ea13";
$icon-success: "\f058";
$icon-error: "\f06a";
$icon-vine: "\f1ca";
$icon-home: "\e905";
$icon-clock2: "\e94f";
$icon-grid-plp-filter: "\e92b";
$icon-block-plp-filter:"\e92a";
$icon-close-thin: "\e933";

