// ********************************
// *
// * Single Category Tile Component
// *
// * Displays categories as single tile
// *
// ********************************


$single_category_title_font_family: null !default;
$single_category_title_font_weight: null !default;
$single_category_title_color: null !default;
$single_category_title_font_size: null !default;
$single_category_title_line_height: null !default;
$single_category_title_letter_spacing: null !default;
$single_category_title_top_margin: null !default;
$single_category_title_bottom_margin: null !default;
$single_category_title_text_transform: null !default;

@mixin single-category-title() {
	font-family: $single_category_title_font_family;
	font-weight: $single_category_title_font_weight;
	color: $single_category_title_color;
	font-size: $single_category_title_font_size;
	line-height: $single_category_title_line_height;
	letter-spacing: $single_category_title_letter_spacing;
	margin-top: $single_category_title_top_margin;
	margin-bottom: $single_category_title_bottom_margin;
	white-space: normal; // Ensure word-wrapping works properly in components that override this value on containers
    text-transform: $single_category_title_text_transform;
}


@mixin cms-single-category-tile() {
	.single-category-tile {
		.info {
			h5 {
				@include single-category-title;
			}
		}
	}
}
