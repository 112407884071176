// ****
// * Product Teasers are product thumbanils displayed in various components
// * like grids, product merchandising, related products, etc.
// *
// * Some of the base styles are initiated in widgets/_article-teaser.scss
// ****

$product_teaser_padding: null !default;
$product_teaser_padding_medium: null !default;
$product_teaser_image_padding: null !default;
$product_teaser_image_padding_medium: null !default;
$product_teaser_image_margin: null !default;
$product_teaser_image_margin_medium: null !default;
$product_teaser_info_padding: null !default;
$product_teaser_info_margin: null !default;
$product_teaser_text_align: center !default;
$product_teaser_link_decoration: none !default;
$product_teaser_title_color: $header-color !default;
$product_teaser_title_bottom_margin: 0.2em !default;
$product_teaser_title_font_family: $header-font-family !default;
$product_teaser_title_font_weight: $header-font-weight !default;
$product_teaser_title_font_size: em-calc(header-font-size(h5, medium)) !default;
$product_teaser_title_font_size_medium: null !default;
$product_teaser_title_line_height: $header-lineheight !default;
$product_teaser_title_letter_spacing: inherit !default;
$product_teaser_title_min_height: null !default;
$product_teaser_title_min_height_medium: null !default;
$product_teaser_title_top_margin: null !default;
$product_teaser_title_text_transform: null !default;
$product_teaser_swatch_border: null !default;
$product_teaser_swatch_border_radius: null !default;
$product_teaser_swatch_count: 5 !default;
$product_teaser_swatch_gutter: 3px !default;
$product_teaser_swatch_height: 25px !default;
$product_teaser_swatch_width: null !default;
$product_teaser_swatch_more_font_family: $header-font-family !default;
$product_teaser_swatch_more_font_weight: $header-font-weight !default;
$product_teaser_swatch_more_color: $header-color !default;
$product_teaser_swatch_more_font_size: inherit !default;
$product_teaser_swatch_more_letter_spacing: inherit !default;
$product_teaser_swatch_more_border: 1px solid $body-font-color !default;
$product_teaser_swatch_more_background: $white !default;
$product_teaser_eyebrow_font_family: null !default;
$product_teaser_eyebrow_font_weight: null !default;
$product_teaser_eyebrow_color: null !default;
$product_teaser_eyebrow_font_size: null !default;
$product_teaser_eyebrow_line_height: null !default;
$product_teaser_eyebrow_letter_spacing: null !default;
$product_teaser_eyebrow_text_transform: null !default;
$product_teaser_promo_font_family: inherit !default;
$product_teaser_promo_color: inherit !default;
$product_teaser_promo_font_size: inherit !default;
$product_teaser_promo_line_height: inherit !default;
$product_teaser_promo_letter_spacing: inherit !default;
$product_teaser_price_font_family: inherit !default;
$product_teaser_price_color: inherit !default;
$product_teaser_price_font_size: inherit !default;
$product_teaser_price_font_size_medium: null !default;
$product_teaser_price_font_weight: null !default;
$product_teaser_price_line_height: inherit !default;
$product_teaser_price_letter_spacing: inherit !default;
$product_teaser_price_previous_font_family: null !default;
$product_teaser_price_previous_color: null !default;
$product_teaser_price_previous_font_size: null !default;
$product_teaser_price_previous_font_weight: null !default;
$product_teaser_price_previous_line_height: null !default;
$product_teaser_price_previous_letter_spacing: null !default;
$product_teaser_price_previous_text-decoration: line-through !default;
$product_teaser_price_current_font_family: null !default;
$product_teaser_price_current_color: null !default;
$product_teaser_price_current_font_size: null !default;
$product_teaser_price_current_font_weight: null !default;
$product_teaser_price_current_line_height: null !default;
$product_teaser_price_current_letter_spacing: null !default;
$product_teaser_price_compare_font_family: null !default;
$product_teaser_price_compare_color: null !default;
$product_teaser_price_compare_font_size: null !default;
$product_teaser_price_compare_font_weight: null !default;
$product_teaser_price_compare_line_height: null !default;
$product_teaser_price_compare_letter_spacing: null !default;
$product_teaser_qty_font_family: inherit !default;
$product_teaser_qty_color: inherit !default;
$product_teaser_qty_font_size: inherit !default;
$product_teaser_qty_line_height: inherit !default;
$product_teaser_qty_letter_spacing: inherit !default;
$product_teaser_mobile_quickshop-button-font-size: null !default;
$product_teaser_badge-font-family: null !default;
$product_teaser_badge-font-size: null !default;
$product_teaser_badge_horizontal_align: right !default;
$product_teaser_badge-horizontal-offset: 0 !default;
$product_teaser_badge_vertical_align: top !default;
$product_teaser_badge-vertical-offset: 0 !default;

$giftCardNameFontFamily: null !default;
$giftCardNameFontSize: rem-calc(25) !default;
$giftCardNameColor: null !default;
$giftCardNameBottomPadding: 10px !default;
$giftCardNameWeight: bold !default;

$giftCardPriceColor: null !default;
$giftCardPriceFontFamily: null !default;
$giftCardPriceSize: rem-calc(25) !default;
$giftCardPriceBottomPadding: 25px !default;
$giftCardPriceWeight: bold !default;

$giftCardLinkWordColor: null !default;
$giftCardLinkWordFamily: null !default;
$giftCardLinkBottomPadding: 25px !default;
$giftCardLinkWeight: bold !default;
$giftCardLinkHoverColor: null !default;
$giftCardLinkBackground: null !default;
$giftCardDecoration: none !default;
$giftCardLinkBorder: 2px solid $black !default;

$giftCardImageBottomPadding: 50px !default;


// We use this mixin to style the quickshop button
@mixin quickshop-button() {
	display: none;
    font-size: $product_teaser_mobile_quickshop-button-font-size;
    left: 50%;
    position: absolute;
	top: 50%;
	transform: translate(-50%, -125%);

    @include breakpoint(large) {
        font-size: map-get($button-sizes, default);
    }
}

// We use this mixin to style an individual color swatch
@mixin product-teaser-swatch() {
	border: $product_teaser_swatch_border;
	border-radius: $product_teaser_swatch_border_radius;
	display: inline-block;
	height: $product_teaser_swatch_height;
	margin-bottom: $product_teaser_swatch_gutter;
	overflow: hidden;

	// when border-radius is used, gutters should use margin to prevent
	// inner content from being cropped when a padding is applied.
	// otherwise, use padding to preserve existing styles
	@if $product_teaser_swatch_border_radius {
		margin-left: $product_teaser_swatch_gutter / 2;
		margin-right: $product_teaser_swatch_gutter / 2;
	} @else {
		padding-left: $product_teaser_swatch_gutter / 2;
		padding-right: $product_teaser_swatch_gutter / 2;
	}

	// use the specicied width if set, otherwise calculate 
	// the % width by number of swatches shown
	@if $product_teaser_swatch_width {
		width: $product_teaser_swatch_width;
	} @else {
		width: percentage(1 / $product_teaser_swatch_count);
	}
	vertical-align: top;

	.color {
		display: block;
		float: left;
		height: 100%;

		// Image based swatches have a background set
		&.image {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	// Multi-color swatches
	@for $i from 1 through 4 {
		&.colors-#{$i} {
			.color {
				width: percentage(1 / $i);
			}
		}
	}

	@if $product_teaser_text_align == "left" {
		&:first-child {
			margin-left: 0;
		}
	}
}


// We use this to style the more swatches button
@mixin product-teaser-swatch-more() {
	// Leverages .swatch

	.more-counter {
		background: $product_teaser_swatch_more_background;
		border: $product_teaser_swatch_more_border;
		color: $product_teaser_swatch_more_color;
		display: block;
		font-family: $product_teaser_swatch_more_font_family;
		font-size: $product_teaser_swatch_more_font_size;
		font-weight: $product_teaser_swatch_more_font_weight;
		height: $product_teaser_swatch_height;
		letter-spacing: $product_teaser_swatch_more_letter_spacing;
		line-height: $product_teaser_swatch_height;
		text-align: center;
	}
}

// We use this mixin to style a group of color swatches
@mixin product-teaser-swatches() {
	width: 90%;
	margin: 0 auto;
	word-spacing: -4px; // Solve for gap between inline-block elements

	.swatch {
		@include product-teaser-swatch;
	}

	.more {
		@include product-teaser-swatch-more;
	}
}

// We use this mixin to style the Product Title in product teasers
@mixin product-teaser-title() {
	color: $product_teaser_title_color;
	font-family: $product_teaser_title_font_family;
	font-size: $product_teaser_title_font_size;
	font-weight: $product_teaser_title_font_weight;
	letter-spacing: $product_teaser_title_letter_spacing;
	line-height: $product_teaser_title_line_height;
	margin-top: $product_teaser_title_top_margin;
	margin-bottom: $product_teaser_title_bottom_margin;
	min-height: $product_teaser_title_min_height;
	text-transform: $product_teaser_title_text_transform;
	white-space: normal; // Ensure word-wrapping works properly in components that override this value on containers

	@include breakpoint(medium) {
		font-size: $product_teaser_title_font_size_medium;
		min-height: $product_teaser_title_min_height_medium;
	}
}

// We use this mixin to style the Eyebrow text in product info
@mixin product-teaser-eyebrow() {
	font-family: $product_teaser_eyebrow_font_family;
    font-weight: $product_teaser_eyebrow_font_weight;
	color: $product_teaser_eyebrow_color;
	font-size: $product_teaser_eyebrow_font_size;
	line-height: $product_teaser_eyebrow_line_height;
	letter-spacing: $product_teaser_eyebrow_letter_spacing;
	text-transform: $product_teaser_eyebrow_text_transform;
}

// We use this mixin to style the product promo text block
@mixin product-teaser-promotion() {
	font-family: $product_teaser_promo_font_family;
	color: $product_teaser_promo_color;
	font-size: $product_teaser_promo_font_size;
	line-height: $product_teaser_promo_line_height;
	letter-spacing: $product_teaser_promo_letter_spacing;
}

// We use this mixin to style the previous price when original and discount prices are displayed
@mixin product-teaser-price-previous() {
	font-family: $product_teaser_price_previous_font_family;
	color: $product_teaser_price_previous_color;
	font-size: $product_teaser_price_previous_font_size;
	font-weight: $product_teaser_price_previous_font_weight;
	line-height: $product_teaser_price_previous_line_height;
	letter-spacing: $product_teaser_price_previous_letter_spacing;
	text-decoration: $product_teaser_price_previous_text-decoration;
	// Space between prices
	margin-right: 1em;
}

// We use this mixin to style the previous price when original and discount prices are displayed
@mixin product-teaser-price-current() {
	font-family: $product_teaser_price_current_font_family;
	color: $product_teaser_price_current_color;
	font-size: $product_teaser_price_current_font_size;
	font-weight: $product_teaser_price_current_font_weight;
	line-height: $product_teaser_price_current_line_height;
	letter-spacing: $product_teaser_price_current_letter_spacing;
}

@mixin product-teaser-price-compare() {
	font-family: $product_teaser_price_compare_font_family;
	color: $product_teaser_price_compare_color;
	font-size: $product_teaser_price_compare_font_size;
	font-weight: $product_teaser_price_compare_font_weight;
	line-height: $product_teaser_price_compare_line_height;
	letter-spacing: $product_teaser_price_compare_letter_spacing;
}

// We use this mixin to style the price in product teasers
@mixin product-teaser-price() {
	font-family: $product_teaser_price_font_family;
	color: $product_teaser_price_color;
	font-size: $product_teaser_price_font_size;
	font-weight: $product_teaser_price_font_weight;
	line-height: $product_teaser_price_line_height;
	letter-spacing: $product_teaser_price_letter_spacing;

	.previous {
		@include product-teaser-price-previous();
	}

	.current {
		@include product-teaser-price-current();
	}

	// Overrides Current Price styling when both Previous and Current are displayed
	.previous + .current {
		@include product-teaser-price-compare();
	}

	@include breakpoint(medium) {
		font-size: $product_teaser_price_font_size_medium;
	}
}

// We use this mixin to style the item quantinty in product teasers
@mixin product-teaser-qty() {
	font-family: $product_teaser_qty_font_family;
	color: $product_teaser_qty_color;
	font-size: $product_teaser_qty_font_size;
	line-height: $product_teaser_qty_line_height;
	letter-spacing: $product_teaser_qty_letter_spacing;
}

// We use this mixin to style the item badge in product teasers
@mixin product-teaser-badge() {
	position: absolute;
	#{$product_teaser_badge-vertical-align}: $product_teaser_badge-vertical-offset;
	#{$product_teaser_badge-horizontal-align}: $product_teaser_badge-horizontal-offset;

	&-label {
		font-family: $product_teaser_badge-font-family;
		font-size: $product_teaser_badge-font-size;
	}
}


// We use this mixin to apply the overall product teaser sytling
@mixin product-teaser() {
	padding: $product_teaser_padding;
	position: relative;
	text-align: $product_teaser_text_align;
	vertical-align: top;

	 // Entire thumbnail is wrapped in a clickable anchor
	.pdp-link {
		// Unset global link styling to body defaults so we can style the inner elements correctly
		color: $body-font-color;
		display: block;
		font-family: $body-font-family;
		font-style: normal;
		font-weight: normal;
		letter-spacing: inherit;
		size: $global-font-size;
		text-decoration: $product_teaser_link_decoration; // remove default link underline on text within thumbnail
		text-transform: none;
	}

	// Quick Shop buttons
	.quickview-button {
		@include quickshop-button;
	}

	// Image area
	.view {
		margin: $product_teaser_image_margin;
		padding: $product_teaser_image_padding;
		position: relative;

		picture {
			width: 100%; // required for fluid image sizes
		}

		@include breakpoint(medium) {
			margin: $product_teaser_image_margin_medium;
			padding: $product_teaser_image_padding_medium;
		}
	}

	// Badge
	.badge {
		@include product-teaser-badge;
	}

	// Product details under the image
	.info {
		margin: $product_teaser_info_margin;
		padding: $product_teaser_info_padding;
		text-align: $product_teaser_text_align;
	}

	// Eyebrow text label
	.eyebrow {
		@include product-teaser-eyebrow;
	}

	// Text promotion
	.promotion {
		@include product-teaser-promotion;
	}

	// Color Swatches
	.swatches {
		@include product-teaser-swatches;
	}

	// Product Title
	.title {
		@include product-teaser-title;
 	}

	// Product Price
	.price {
		@include product-teaser-price;
	}

	// Product Qauntity
	.qty {
		@include product-teaser-qty;
	}

	@include breakpoint(medium) {
		padding: $product_teaser_padding_medium;
	}
}

@mixin gift-card {

    .gift-card {

        &-image {
            padding-bottom: $giftCardImageBottomPadding;
        }

        &-name {
            color: $giftCardNameColor;
            font-family: $giftCardNameFontFamily;
            font-size: $giftCardNameFontSize;
            padding-bottom: $giftCardNameBottomPadding;
            font-weight: $giftCardNameWeight;
        }

        &-price {
            color: $giftCardPriceColor;
            font-family: $giftCardPriceFontFamily;
            font-size: $giftCardPriceSize;
            padding-bottom: $giftCardPriceBottomPadding;
            font-weight: $giftCardPriceWeight;
        }

        &-link {
            padding-bottom: $giftCardLinkBottomPadding;
        }

        &-link a {
           @include button($background: $giftCardLinkBackground, $background-hover: $giftCardLinkHoverColor, $color: $giftCardLinkWordColor);
            border: $giftCardLinkBorder;
            font-family: $giftCardLinkWordFamily;
            font-weight: $giftCardLinkWeight;
            text-decoration: $giftCardDecoration;
        }
    }
}

// Product Teaser styles applied to all article.product.teaser elements
@mixin cms-product-teaser {

    .product.teaser {
        @include product-teaser;
    }

    // Quickshop Button hover functionality
	@include breakpoint(medium) {
		.product.teaser {
			&:active,
			&:focus,
			&:focus-within,
			&:hover {
				.quickview-button {
					display: block; // Expose buttons on hover
				}
			}
		}
	}

    .gift-card-content {
        @include gift-card;
    }
}
