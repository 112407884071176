@import 'cms/components/carousel-container'; // Get shared component


@mixin napa-cms-carousel-container {
  section.carousel-container {
    &.carousel-mobile {
      margin: $carousel-mobile-margin;
      & > .row {
        margin: 0;
      }
      .carousel-wrapper {
        margin-right: $carousel-mobile-wrapper-margin-right;
        .carousel {
          width: $carousel-mobile-width;
          overflow-x: $carousel-mobile-vertical-overflow;
        }
        .cycle-next, .cycle-prev {
          border-radius: $carousel-mobile-nav-button-border-radius;
          margin-top: $carousel-mobile-nav-button-margin-top;
          top: $carousel-mobile-nav-button-top;
          height: $carousel-mobile-nav-button-height;
          background: $carousel-mobile-nav-button-background;
          &::before {
            content: $carousel-mobile-nav-button-icon;
          }
        }
        &:hover {
          .cycle-next, .cycle-prev {
            background: $carousel-mobile-nav-button-background-hover;
          }
        }
      }
      .cycle-carousel-wrap {
        left: $carousel-mobile-slide-wrapper-left;
        display: flex;
        white-space: unset !important;  // sass-lint:disable-line no-important
      }
      .slide {
        width: $carousel-mobile-slide-width;
        padding-top: $carousel-mobile-slide-padding-top;
        padding-bottom: $carousel-mobile-slide-padding-bottom;
        padding-left: $carousel-mobile-slide-padding-left;
        padding-right: $carousel-mobile-slide-padding-right;
        p {
          font-family: $carousel-mobile-slide-paragraph-font-family;
          font-size: $carousel-mobile-slide-paragraph-font-size;
          line-height: $carousel-mobile-slide-paragraph-line-height;
          font-weight: $carousel-mobile-slide-paragraph-font-weight;
          margin-bottom: $carousel-mobile-slide-paragraph-margin-bottom;
          text-align: $carousel-mobile-slide-paragraph-text-align;
          text-transform: $carousel-mobile-slide-paragraph-text-transform;
        }
        p + p {
          margin-top: $carousel-mobile-slide-inter-paragraph-vertical-spacing;
          height: rem-calc(45);
        }
      }
      .carouselimg {
        width: $carousel-mobile-carouselimg-width;
        max-width: $carousel-mobile-carouselimg-max-width;
        height: $carousel-mobile-carouselimg-height;
        object-fit: $carousel-mobile-carouselimg-object-fit;
        transform: $carousel-mobile-carouselimg-transform;
      }
    }
    &.show-controls-on-focus {
      .carousel-wrapper {
        @include carousel-controls-on-hover(
                $color-hover: $carousel-container-overlay-arrow-color-hover,
                $background-hover: $carousel-container-overlay-arrow-background-hover,
                $opacity-hover: $carousel-container-overlay-arrow-opacity-hover
        );
      }
    }
  }
}
