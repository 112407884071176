@import "garnish";

// ********************************
// *
// * CMS Text Component
// *
// ********************************

$text-garnish-full-width-top-padding: 0 !default;
$text-garnish-full-width-bottom-padding: 0 !default;
$text-garnish-full-width-top-margin: 0 !default;
$text-garnish-full-width-bottom-margin: 0 !default;
$text-garnish-full-width-top-padding-medium-and-up: 0 !default;
$text-garnish-full-width-bottom-padding-medium-and-up: 0 !default;
$text-garnish-full-width-top-margin-medium-and-up: 0 !default;
$text-garnish-full-width-bottom-margin-medium-and-up: 0 !default;
$text-garnish-full-width-top-padding-large-and-up: 0 !default;
$text-garnish-full-width-bottom-padding-large-and-up: 0 !default;
$text-garnish-full-width-top-margin-large-and-up: 0 !default;
$text-garnish-full-width-bottom-margin-large-and-up: 0 !default;

@mixin cms-text {
  .text {
    .garnish-full-width {
      @include garnish-defaults(
        (
          $text-garnish-full-width-top-padding,
          $text-garnish-full-width-bottom-padding,
          $text-garnish-full-width-top-margin
        ),
        (
          $text-garnish-full-width-bottom-margin,
          $text-garnish-full-width-top-padding-medium-and-up,
          $text-garnish-full-width-bottom-padding-medium-and-up
        ),
        (
          $text-garnish-full-width-top-margin-medium-and-up,
          $text-garnish-full-width-bottom-margin-medium-and-up,
          $text-garnish-full-width-top-padding-large-and-up
        ),
        (
          $text-garnish-full-width-bottom-padding-large-and-up,
          $text-garnish-full-width-top-margin-large-and-up,
          $text-garnish-full-width-bottom-margin-large-and-up
        )
      );
    }
  }
}
