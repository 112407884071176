// ********************************
// *
// * Carousel Controls
// *
// * Displays consistent controls for various carousel components
// *
// ********************************
@import 'global/z-indexes';

// Overlay Arrows
$carousel-overlay-arrow-background: null !default;
$carousel-overlay-arrow-background-hover: null !default;
$carousel-overlay-arrow-border-radius: null !default;
$carousel-overlay-arrow-color: $header-color !default;
$carousel-overlay-arrow-color-hover: null !default;
$carousel-overlay-arrow-width: 2 * $column-gutter !default;
$carousel-overlay-arrow-height: $carousel-overlay-arrow-width !default;
$carousel-overlay-arrow-opacity: 1 !default;
$carousel-overlay-arrow-opacity-hover: 1 !default;
$carousel-overlay-arrow-size: em-calc(16) !default;
$carousel-overlay-arrow-top: 50% !default;
$carousel-overlay-arrow-transition-delay: null !default;

// Arrows that display below the carousel
$carousel-lower-arrow-size: em-calc(16) !default;
$carousel-lower-arrow-color: $header-color !default;
$carousel-lower-arrow-color-hover: null !default;
$carousel-lower-arrow-padding: 0 !default;
$carousel-lower-arrow-background: null !default;
$carousel-lower-arrow-background-hover: null !default;
$carousel-lower-container-margin: $column-gutter 0 !default;

// Pager that displays below the carousel
$carousel-pager-indicator-size: em-calc(12) !default;
$carousel-pager-indicator-margin: 0 em-calc(3) !default;
$carousel-pager-indicator-border-radius: 50% !default;
$carousel-pager-indicator-background: null !default;
$carousel-pager-indicator-border: null !default;
$carousel-pager-indicator-active-background: null !default;
$carousel-pager-indicator-active-border: null !default;
$carousel-pager-indicator-hover-background: null !default;
$carousel-pager-indicator-hover-border: null !default;

// This mixin is used to syle arrows that are to the left and right of the carousel
// and overlay the carousel when it fills the full width of the window
@mixin carousel-overlay-arrows(
	$width:			$carousel-overlay-arrow-width,
	$height:		$carousel-overlay-arrow-height,
	$size:			$carousel-overlay-arrow-size,
	$color:			$carousel-overlay-arrow-color,
	$background:	$carousel-overlay-arrow-background,
	$color-hover:	$carousel-overlay-arrow-color-hover,
	$background-hover: $carousel-overlay-arrow-background-hover,
    $opacity:       $carousel-overlay-arrow-opacity,
	$opacity-hover:	$carousel-overlay-arrow-opacity-hover,
	$radius:		$carousel-overlay-arrow-border-radius,
	$top:			$carousel-overlay-arrow-top,
	$transition-delay: $carousel-overlay-arrow-transition-delay
	) {

	.cycle-prev,
	.cycle-next {
		background: $background;
		border-radius: $radius;
		color: $color;
		cursor: pointer;
		height: $height;
		line-height: $height;
		margin: 0;
		margin-top: $height / -2;
		opacity: $opacity;
		overflow: hidden; // prevent irregular shapped focus outline
		padding: 0;
		position: absolute;
		text-align: center;
		top: $top;
		transition-property: all;
		transition-delay: $transition-delay;
		transition-duration: 0.2s;
		width: $width;
		z-index: z-index(carousels) + 1;

		&:hover {
			opacity: $opacity-hover;
			background: $background-hover;
			color: $color-hover;
		}

		span {
			display: none; // Hide text labels
		}

		&::before {
			font-size: $size;
		}
	}

	.cycle-prev {
		left: 0;
	}

	.cycle-next {
		right: 0;
	}

	// Move the arrows outside the carousel if the screen is large enough
    // Because this media query uses ems, the base needs to be converted to 16
    $row-width-rems-base-16: (strip-unit($global-font-size) / 16) * $global-width;
    $button-width-rems-base-16: if(unit($width) == 'em', rem-calc((strip-unit($global-font-size) / 16) * $width), rem-calc($width));

	@media only screen and (min-width: #{$row-width-rems-base-16 + ($button-width-rems-base-16 * 2)}) {
        &:not(.fullbleed) {
            .cycle-prev {
                margin-left: -#{$width};
            }

            .cycle-next {
                margin-right: -#{$width};
            }
        }
	}
}

// This mixin is used to style the lower arrows that display under the carousel
@mixin carousel-lower-arrows(
	$size:			$carousel-lower-arrow-size,
	$color:			$carousel-lower-arrow-color,
	$color-hover:	$carousel-lower-arrow-color-hover,
    $padding:       $carousel-lower-arrow-padding,
    $background:    $carousel-lower-arrow-background,
    $background-hover: $carousel-lower-arrow-background-hover
	) {

	display: inline-block;
    vertical-align: middle;

	.cycle-prev,
	.cycle-next {
		transition-property: all;
		transition-duration: 0.2s;
		background: $background;
		color: $color;
		cursor: pointer;
        font-size: $size;
        padding: $padding;

		&:hover {
			background: $background-hover;
			color: $color-hover;
		}
	}
}


// This mixin is used to style carousel pager controls below the carousel
@mixin carousel-pager(
	$size:				$carousel-pager-indicator-size,
	$margin: 			$carousel-pager-indicator-margin,
	$border-radius:		$carousel-pager-indicator-border-radius,
	$background:		$carousel-pager-indicator-background,
	$border:			$carousel-pager-indicator-border,
	$active-background: $carousel-pager-indicator-active-background,
	$active-border: 	$carousel-pager-indicator-active-border,
	$hover-background:	$carousel-pager-indicator-hover-background,
	$hover-border:		$carousel-pager-indicator-hover-border
	) {

    display: inline-block;
    vertical-align: middle;

    span {
    	// base dot
        transition-property: all;
        transition-duration: 0.2s;
        background: $background;
        border: $border;
        border-radius: $border-radius;
        cursor: pointer;
        display: inline-block;
        height: $size;
        margin: $margin;
        overflow: hidden;
        vertical-align: middle;
        width: $size;

        // current dot
        &.cycle-pager-active {
            background: $active-background;
            border: $active-border;
        }

		// hover dot
        &:hover {
            background: $hover-background;
            border: $hover-border;
        }
	}

	&.hide {
		display: none;
	}
}

// This mixin is used to style the full set of lower carousel controls
@mixin carousel-lower-controls(
	$arrow-size:		$carousel-lower-arrow-size,
	$arrow-color:		$carousel-lower-arrow-color,
	$arrow-color-hover:	$carousel-lower-arrow-color-hover,
    $arrow-padding:     $carousel-lower-arrow-padding,
    $arrow-background:  $carousel-lower-arrow-background,
    $arrow-background-hover: $carousel-lower-arrow-background-hover,
	$size:				$carousel-pager-indicator-size,
	$margin: 			$carousel-pager-indicator-margin,
	$border-radius:		$carousel-pager-indicator-border-radius,
	$background:		$carousel-pager-indicator-background,
	$border:			$carousel-pager-indicator-border,
	$active-background: $carousel-pager-indicator-active-background,
	$active-border: 	$carousel-pager-indicator-active-border,
	$hover-background:	$carousel-pager-indicator-hover-background,
	$hover-border:		$carousel-pager-indicator-hover-border,
    $inner-margin:      $carousel-lower-container-margin
	) {

    .controls {

        > div {
            @include clearfix();
        }

        .inner {
            margin: $inner-margin; //$column-gutter 0;
        }

        .cycle-pager {
            @include carousel-pager(
                $size,
                $margin,
                $border-radius,
                $background,
                $border,
                $active-background,
                $active-border,
                $hover-background,
                $hover-border
            )
        }

        .cycle-pager-wrapper {
        	display: inline-block;
    	}

        .arrow-wrapper {

            @include carousel-lower-arrows(
                $arrow-size,
                $arrow-color,
                $arrow-color-hover,
                $arrow-padding,
                $arrow-background,
                $arrow-background-hover
            );
        }
    }
}

// apply hover states to controls when hovering or focusing on carousel container
@mixin carousel-controls-on-hover(
	$background: $carousel-overlay-arrow-background,
	$background-hover: $carousel-overlay-arrow-background-hover,
	$color:	$carousel-overlay-arrow-color,
	$color-hover: $carousel-overlay-arrow-color-hover,
	$opacity:	0,
	$opacity-hover:	1,
	$override-visibility-class: true
	) {

	.cycle-prev,
	.cycle-next {
		@if($override-visibility-class) {
			@include visibility-classes-opacity(
				$opacity-hidden: $opacity,
				$opacity-visible: $opacity-hover
			);
		} @else {
			opacity: $opacity;
		}
		background: $background;
		color: $color;
	}

	&:focus-within,
	&:hover {
		.cycle-prev,
		.cycle-next {
			background: $background-hover;
			color: $color-hover;
			opacity: $opacity-hover;
		}
	}
}
