.hidden {
	display: none !important; // sass-lint:disable-line no-important
}

.clearfix {
    @include clearfix;
}

.notransition {
  transition: none !important; // sass-lint:disable-line no-important
}
