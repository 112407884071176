// ********************************************************
// * CMS Region Selector Component
//
// This component utilizes the Foundation.ResponsiveAccordionTab
// component which means it displays as an accordion on small
// screens and tabs on medium+ screens. Within the main
// accordion/tab content panels there are also nested accordions
// that are persistent on medium+ screens.
// ********************************************************

$regionHeadlineFontSize: rem-calc(22) !default;
$regionHeadlineFontWeight: null !default;
$regionNoticeFontSize: null !default;
$regionNoticeFontWeight: null !default;
$regionInstructionsFontSize: null !default;
$regionInstructionsFontWeight: null !default;

$regionAccordionBorder: $accordion-content-border !default;
$regionAccordionPaddingV: 10px !default;
$regionAccordionPaddingH: 15px !default;
$regionAccordionIndicatorColor: $black !default;
$regionAccordionIndicatorSize: 16px !default;
$regionAccordionIndicatorWeight: null !default;
$regionImageBackground: $light-gray  !default;
$regionImageColor: $dark-gray !default;
$regionImageHoverBackground: null !default;
$regionImageHoverColor: $black !default;
$regionImageSize: 60px !default;
$regionImageSizeMedium: 100px !default;
$regionImageSizeLarge: 128px !default;
$regionNameColor: $black !default;
$regionNameFontFamily: null !default;
$regionNameFontSize: rem-calc(14) !default;
$regionNameFontWeight: bold !default;
$regionNameMargin: 20px !default;
$regionTextTransform: uppercase !default;

$countryNameColor: $regionNameColor !default;
$countryNameFontFamily: null !default;
$countryNameFontSize: $regionNameFontSize !default;
$countryNameFontWeight: null !default;
$countryNameMargin: 8px !default;
$countryNameTextTransform: uppercase !default;
$countryFlagSize: 26px !default;
$countryItemFlexBasis: 200px !default;

$languageBackgroundColor: null !default;
$languageNameColor: $countryNameColor !default;
$languageNameHoverColor: get-color(primary) !default;
$languageNameFontFamily: null !default;
$languageNameFontSize: $countryNameFontSize !default;
$languageNameFontWeight: $countryNameFontWeight !default;
$languageSelectFontSize: rem-calc(12) !default;
$languageSelectFontWeight: bold !default;


@mixin title-styles {
  .region-selector {
    .headline {
      font-size: $regionHeadlineFontSize;
      font-weight: $regionHeadlineFontWeight;
    }

    .notice {
      font-size: $regionNoticeFontSize;
      font-weight: $regionNoticeFontWeight;
    }

    .instructions {
      font-size: $regionInstructionsFontSize;
      font-weight: $regionInstructionsFontWeight;
    }

    @include breakpoint(medium) {
      .instructions {
        margin-bottom: 10px;
      }
    }
  }
}

// Override Foundation.Accordion styles
// - remove unwanted padding and borders for full-bleed display
// - adjust styling for consistency when converted to tabs display
@mixin accordion-styles {
  &-item {
    padding: 0;

    &:last-child:not(.is-active) {
      .accordion-title {
        border-bottom: 0; //remove default foundation border
      }
    }
  }

  &-content {
    border: 0; //remove default foundation border
    padding: 0;

    :last-child > &:last-child {
      border-bottom: 0; //remove default foundation border
    }
  }

  &-title {
    border: 0; //remove side borders
    border-top: $regionAccordionBorder;
    height: $countryFlagSize + ($regionAccordionPaddingV * 2);
    padding: $regionAccordionPaddingV $regionAccordionPaddingH;

    &:hover,
    &:focus {
      background: none; //remove default background from foundation-accordion
    }

    &::before {
      color: $regionAccordionIndicatorColor;
      font-size: $regionAccordionIndicatorSize;
      font-weight: $regionAccordionIndicatorWeight;
    }
  }

  // prevent accordion styles from displaying on medium/large
  // screens before it gets converted to the tabs layout
  @include breakpoint(medium) {
    &-title {
      border: 0;

      &::before {
        display: none;
      }

      :last-child:not(.is-active) > & {
        border: 0;
      }
    }
  }
}

// Override Foundation.Tab styles
// - remove unwanted border and background color
@mixin tab-styles {
  &-content {
    border: 0;
  }

  &-title {
    > a {
      &:focus,
      &[aria-selected='true'] {
        background: none;
      }
    }
  }
}


// region item styles
@mixin region-styles {

  &-list {
    border-bottom: $regionAccordionBorder;
  }

  &-item {
    &:hover,
    &:focus,
    &.is-active {

      .region-map-svg {
        background-color: $regionImageHoverBackground;
        fill: $regionImageHoverColor;
      }
    }
  }

  &-link {
    height: $regionImageSize + ($regionAccordionPaddingV * 2);
  }

  &-image {
    display: inline-block;
    float: left;
    height: $regionImageSize;
    overflow: hidden;
    width: $regionImageSize;
  }

  &-map-svg {
    background-color: $regionImageBackground;
    fill: $regionImageColor;
    height: auto;
    width: 100%;
  }

  &-name {
    @include vertical-align;
    color: $regionNameColor;
    display: inline-block;
    font-family: $regionNameFontFamily;
    font-size: $regionNameFontSize;
    font-weight: $regionNameFontWeight;
    margin-left: $regionNameMargin;
    text-transform: $regionTextTransform;
  }

  @include breakpoint(medium) {
    &-list {
      border: 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }

    &-link {
      height: auto;
      padding-top: 0;
    }

    &-image {
      display: block;
      float: none;
      height: $regionImageSizeMedium;
      margin: 0 auto 20px;
      width: $regionImageSizeMedium;
    }

    &-name {
      display: block;
      margin: 8px 0 0;
      text-align: center;
    }
  }

  @include breakpoint(large) {
    &-image {
      height: $regionImageSizeLarge;
      width: $regionImageSizeLarge;
    }
  }
}


// country item styles
@mixin country-styles {
  &-name {
    @include vertical-align;
    color: $countryNameColor;
    display: inline-block;
    float: left;
    font-family: $countryNameFontFamily;
    font-size: $countryNameFontSize;
    font-weight: $countryNameFontWeight;
    margin-left: $countryNameMargin;
    text-transform: $countryNameTextTransform;
  }

  &-image {
    @include vertical-align;
    display: inline-block;
    float: left;
    height: $countryFlagSize;
    width: $countryFlagSize;
  }

  &-link {
    &.language-link {
      background-color: transparent;
      padding-left: $regionAccordionPaddingH;
    }
  }


  @include breakpoint(medium) {
    &-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }

    &-item {
      flex-basis: $countryItemFlexBasis;
      padding: 0 10px 30px;
    }

    &-link {
      border: 0;
      padding-left: 0;
      padding-right: 0;

      &.language-link {
        padding-left: 0;
        padding-right: 0;
      }

      :last-child:not(.is-active) > & {
        border: 0;
      }

      &::before {
        @include vertical-align;
        display: inline-block;
        margin: 0;
        right: -$countryNameFontSize/2;
      }
    }
  }
}


// language item styles
@mixin language-styles {
  &-list {
    @include accordion-container;
  }

  &-select {
    font-size: $languageSelectFontSize;
    font-weight: $languageSelectFontWeight;
    margin-bottom: 5px;
  }

  &-link {
    @include accordion-title (
      $padding: $regionAccordionPaddingV $regionAccordionPaddingH,
      $font-size: $languageNameFontSize,
      $color: $languageNameColor,
      $border: null,
      $background-hover: null
    );

    background-color: $languageBackgroundColor;
    border-top: $regionAccordionBorder;
    font-weight: $languageNameFontWeight;
    padding-left: $regionAccordionPaddingH + $countryFlagSize + $countryNameMargin;

    &:before {
      display: none; // hide accordion indicator added by mixin above
    }

    &:hover {
      color: $languageNameHoverColor;
    }
  }

  @include breakpoint(medium) {
    &-link {
      background-color: transparent;
      border: 0;
      padding: 5px 0;

      :last-child:not(.is-active) > & {
        border: 0;
      }
    }
  }
}


@mixin cms-region-selector {
  @include title-styles;

  .cms-region-selector {

    a {
      text-decoration: none;
    }

    // foundation.accordion styles
    .accordion {
      @include accordion-styles;
    }

    // foundation.tab styles
    .tabs {
      @include tab-styles;
    }

    // region styles
    .region {
      @include region-styles;
    }

    // country styles
    .country {
      @include country-styles;
    }

    // language styles
    .language {
      @include language-styles;
    }
  }
}
