// ********************************
// *
// * Product Carousel Component
// *
// * Displays CMS products in a carousel
// *
// * Requires the global sass/cms/widgets/_carousel-controls.scss
// * Requires the brand-specific sass/cms/_mixins.scss
// *
// ********************************

$product-carousel-overlay-arrow-size: 				$carousel-overlay-arrow-size !default;
$product-carousel-overlay-arrow-color: 				$carousel-overlay-arrow-color !default;
$product-carousel-overlay-arrow-background: 		$carousel-overlay-arrow-background !default;
$product-carousel-overlay-arrow-color-hover: 		$carousel-overlay-arrow-color-hover !default;
$product-carousel-overlay-arrow-background-hover: 	$carousel-overlay-arrow-background-hover !default;
$product-carousel-overlay-arrow-width: 				$carousel-overlay-arrow-width !default;
$product-carousel-overlay-arrow-height: 			$carousel-overlay-arrow-height !default;
$product-carousel-overlay-arrow-opacity:            $carousel-overlay-arrow-opacity !default;
$product-carousel-overlay-arrow-opacity-hover:      $carousel-overlay-arrow-opacity-hover !default;
$product-carousel-product-wrapper-padding:          0 $column-gutter / 2 !default;

// We use this mixin to style the product carousel component
@mixin cms-product-carousel() {

	section.product-carousel {

		@include component-headline();

		.carousel-wrapper {
			position: relative;

			@include carousel-overlay-arrows(
				$product-carousel-overlay-arrow-width,
				$product-carousel-overlay-arrow-height,
				$product-carousel-overlay-arrow-size,
				$product-carousel-overlay-arrow-color,
				$product-carousel-overlay-arrow-background,
				$product-carousel-overlay-arrow-color-hover,
				$product-carousel-overlay-arrow-background-hover,
				$product-carousel-overlay-arrow-opacity,
				$product-carousel-overlay-arrow-opacity-hover
			);
		}

		.slide {
			// Hide slides until component intializes so we don't have wierd sizes
			// before init. Then slides sizes are determined by Cycle 2 and displayed.
			display: none;
			vertical-align: top;

			.product-wrapper {
				// The products need padding to match the grid system
				@include clearfix();
				padding: $product-carousel-product-wrapper-padding;
			}


            // prevent inactive slides from receiving focus
            &.inactive {
                & > div {
                    visibility: hidden;
                }
            }
		}
	}
}
