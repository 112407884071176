// ********************************************************
// * Extend Foundation Sections with brand-specific needs
// *
// * Start by setting variables in foundation-settings before using this file
// *
// ********************************************************

.section-container.accordion {
	border-top: 2px solid get-color(burnt-orange);

	section {
		background-color: get-color(white-smoke);

		&.active {
			border-bottom: 1px solid $light-gray;
			border-left: 0;
			border-right: 0;

			.title {
				background: transparent;
				border: 0;

				a {
					&::before {
						@include icomoonFont($icon-minus);
					}
				}
			}
		}

		.content {
			@include grid-column-f4($columns: 10, $collapse: true, $float: false);

			background-color: get-color(white-smoke);
			border: 0;
			font-size: 12px;
			margin: 0 0 0 10px;
			padding: 0;

			p {
				line-height: 16px;
			}
		}

		.title {
			background: transparent;
			border-left: 0;
			border-right: 0;

			a {
				line-height: 18px;
				padding-left: 10px;
				padding-right: 10px;
				text-decoration: none;
				white-space: normal;

				&::before {
					@include icomoonFont($icon-plus);
					float: right;
					font-size: 11px;
					margin: 4px 6px 0 0;

					@include breakpoint(medium) {
          				margin: 5px 0;
        			}
				}
			}

			&:hover {
				background: transparent;
			}
		}
	}
}
