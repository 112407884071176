$searchResultKeywordLink: inline-block !default;
///////////////////////////////////////////////////
// Auto suggest
///////////////////////////////////////////////////

$include-html-search-autosuggest-classes: $include-html-classes !default;
$search-autosuggest-container-background: $white !default;
$search-autosuggest-container-shadow: null !default;
$search-autosuggest-text-color: $body-font-color !default;
$search-autosuggest-text-color-highlight: $search-autosuggest-text-color !default;
$search-autosuggest-item-anchor-color: null !default;
$search-autosuggest-item-anchor-color-hover: null !default;
$search-autosuggest-border-bottom: $topbar-border-bottom !default;
$search-autosuggest-suggestions-title-color: null !default;
$search-autosuggest-suggestions-list-item-padding-top: 3px !default;
$search-autosuggest-suggestions-list-item-padding-bottom: 3px !default;
$search-autosuggest-suggestions-list-item-color: null !default;
$search-autosuggest-featured-results-item-font-family: null !default;
$search-autosuggest-featured-results-item-font-size: null !default;
$search-autosuggest-featured-results-item-title-line-height: 1.2 !default;
$search-autosuggest-featured-results-item-title-margin-right: null !default;
$search-autosuggest-featured-results-item-title-font-size: em-calc(13px) !default;
$search-autosuggest-featured-results-title: null !default;
$search-autosuggest-featured-results-item-padding-bottom: 8px !default;
$search-autosuggest-featured-results-item-padding-top: 8px !default;
$search-autosuggest-featured-results-item-img-width: 74px !default;
$search-autosuggest-featured-results-item-img-height: $search-autosuggest-featured-results-item-img-width !default;
$search-autosuggest-featured-results-item-info-container-padding-left: 1.3em !default;

// close button
$search-autosuggest-background: $search-autosuggest-container-background !default;
$search-autosuggest-close-background: transparent !default;
$search-autosuggest-close-background-hover: $search-autosuggest-close-background !default;
$search-autosuggest-close-color: $input-color !default;
$search-autosuggest-close-color-hover: $search-autosuggest-close-color !default;
$search-autosuggest-close-padding: 10px !default;
$search-autosuggest-close-right: 0 !default;
$search-autosuggest-close-top: 0 !default;
$search-autosuggest-close-top-medium: null !default;
$search-autosuggest-close-top-large: null !default;

// without a -for-size indicates
// "small" screens
$search-autosuggest-container-position-left: 0 !default;
$search-autosuggest-container-width: 100% !default;
// -for-small here means small-to-medium breakpoint or
// "medium" sized screens
$search-autosuggest-container-position-left-medium: -49px !default;
$search-autosuggest-container-position-right-medium: null !default;
$search-autosuggest-container-zindex: 202  !default;
$search-autosuggest-container-width-medium: 351px !default;

//search-autosuggest-expanded
$search-autosuggest-expanded-border: 2px solid get-border-value($input-border, 'color') !default;
$search-autosuggest-expanded-img-width: 150px !default;
$search-autosuggest-expanded-img-width-medium: $search-autosuggest-expanded-img-width !default;
$search-autosuggest-expanded-img-width-large: 110px !default;
$search-autosuggest-expanded-img-width-xlarge: 120px !default;
$search-autosuggest-expanded-padding-top-medium: null !default;
$search-autosuggest-expanded-padding-top-large: null !default;
$search-autosuggest-expanded-price-current-color: null !default;
$search-autosuggest-expanded-price-previous-color: null !default;
$search-autosuggest-expanded-price-previous-text-decoration: line-through !default;
$search-autosuggest-expanded-products-border-large: null !default;
$search-autosuggest-expanded-products-item-align-medium: center !default;
$search-autosuggest-expanded-products-item-margin-medium: null !default;
$search-autosuggest-expanded-products-item-size: 155px !default;
$search-autosuggest-expanded-products-item-size-medium: 130px !default;
$search-autosuggest-expanded-products-item-width-medium: 250px !default;
$search-autosuggest-expanded-products-item-width-large: 300px !default;
$search-autosuggest-expanded-products-item-width-xlarge: 400px !default;
$search-autosuggest-expanded-products-margin-medium: 20px auto 0 20px !default;
$search-autosuggest-expanded-products-title-size: null !default;
$search-autosuggest-expanded-products-title-size-large: null !default;
$search-autosuggest-expanded-products-title-size-xlarge: 1.75rem !default;
$search-autosuggest-expanded-product-name-padding: 0.5em 0 !default;
$search-autosuggest-expanded-product-name-transform: null !default;
$search-autosuggest-expanded-suggestion-color: null !default;
$search-autosuggest-expanded-suggestion-color-hover: null !default;
$search-autosuggest-expanded-suggestion-decoration: null !default;
$search-autosuggest-expanded-suggestion-decoration-hover: null !default;
$search-autosuggest-expanded-suggestion-weight: null !default;
$search-autosuggest-expanded-suggestions-title-size: null !default;
$search-autosuggest-expanded-suggestions-title-size-medium: null !default;
$search-autosuggest-expanded-suggestions-title-margin-medium: 10px 0 27px !default;
$search-autosuggest-expanded-title-margin-bottom-medium: null !default;
$search-autosuggest-expanded-query-display: null !default;


// search results overlay (smallscreen only)
$search-autosuggest-overlay-background: $search-autosuggest-container-background !default;
$search-autosuggest-overlay-close-background: transparent !default;
$search-autosuggest-overlay-close-color: $input-color !default;
$search-autosuggest-overlay-close-padding: 10px !default;
$search-autosuggest-overlay-close-right: 20px !default;
$search-autosuggest-overlay-close-top: -48px !default;
$search-autosuggest-overlay-top: 60px !default;

$price-container-p-display: null !default;
$price-container-p-width: null !default;
$price-container-list-price-text-decoration: null !default;
$price-container-sale-price-color: null !default;

// apply CSS styles for autocomplete overlay experience
@mixin autosuggest-overlay-results {
	&.autosuggest-active {
		// Prevent the body from scrolling since we
		// have an overlay for the results
		overflow: hidden;
		position: fixed;

		// display search above topnav
		.topnav-search {
			&-container {
				background: $search-autosuggest-overlay-background;
				position: fixed;
				top: 0;
				width: 100%;

				.topnav-search {
					&-button {
						display: none; // search button is replaced by close button within the overlay
					}

					&-input {
						border: $search-autosuggest-expanded-border;
					}
				}
			}
		}

		// display results below search
		.autosuggest-results-container {
			height: calc(100vh - #{$search-autosuggest-overlay-top});
			top: $search-autosuggest-overlay-top;

			.suggestions {
				margin-left: 1.25rem;

				h6 {
					font-size: 1.25rem;
					margin: 1em 0 0.5em 0;
				}
			}

			.searches-area {
				margin: 0 0 2em 0.5em;
			}

			.products-area-title-mobile {
				font-size: 1.25rem;
				margin-bottom: 0.5em;
			}

			.products-area {
				margin-left: 2rem;
			}

		}

		.autosuggest-overlay-close {
			background-color: $search-autosuggest-overlay-close-background;
			border-color: transparent;
			color: $search-autosuggest-overlay-close-color;
			margin: 0;
			padding: $search-autosuggest-overlay-close-padding;
			position: absolute;
			right: $search-autosuggest-overlay-close-right;
			top: $search-autosuggest-overlay-close-top;

		}
	}
}


@mixin search-autosuggest {
	.autosuggest-overlay-close {
		background-color: $search-autosuggest-close-background;
		border-color: transparent;
		color: $search-autosuggest-close-color;
		margin: 0;
		padding: $search-autosuggest-close-padding;
		position: absolute;
		right: $search-autosuggest-close-right;
		top: $search-autosuggest-close-top;

		&:hover,
		&:focus {
			background-color: $search-autosuggest-close-background-hover;
			color: $search-autosuggest-close-color-hover;
		}

		@include breakpoint(medium) {
			top: $search-autosuggest-close-top-medium;
		}

		@include breakpoint(large) {
			top: $search-autosuggest-close-top-large;
		}
	}

	.autosuggest-results {
		&-container {
			animation-duration: 0.25s;
			animation-name: slideDown;
			animation-timing-function: cubic-bezier(0, 0, 0.16, 0.99);
			background: $search-autosuggest-container-background;
			box-shadow: $search-autosuggest-container-shadow;
			color: $search-autosuggest-text-color;
			display: block;
			left: $search-autosuggest-container-position-left;
			max-width: 100%;
			min-width: 100%;
			padding-bottom: 20px;
			position: absolute;
			width: $search-autosuggest-container-width;
			z-index: 202;

			// medium screens
			@include breakpoint(medium) {
				left: $search-autosuggest-container-position-left-medium;
				right: $search-autosuggest-container-position-right-medium;
				max-width: none;
				min-width: auto;
				position: absolute;
				width: $search-autosuggest-container-width-medium;
			}

			.search-term {
				color: $search-autosuggest-text-color-highlight;
			}

		}

		&__suggestions {

			&-list {
				@include grid-column-f4($columns: 12, $float: false);
				border-bottom: $search-autosuggest-border-bottom;
				margin: 0;
				padding-bottom: $column-gutter/2;
				padding-top: $column-gutter/2;

				&-header {

					&-item {
						@include grid-column-f4($columns: 12, $float: false);
						border-bottom: $search-autosuggest-border-bottom;
						color: $search-autosuggest-suggestions-title-color;
						margin: 0;
						padding-bottom: $column-gutter;
						padding-top: $column-gutter;
					}
				}

				&-item {
					@include grid-column-f4($columns: 12, $float: false, $collapse: true);
					list-style: none;
					padding-bottom: $search-autosuggest-suggestions-list-item-padding-bottom;
					padding-top: $search-autosuggest-suggestions-list-item-padding-top;

					.search-term-link {
						color: $search-autosuggest-suggestions-list-item-color;
					}
				}

			}
		}

		&__featured-results {
			&-container {

				.autosuggest-results__featured-results-title {
					color: $search-autosuggest-featured-results-title;
	    			padding-bottom: $column-gutter/2;
					padding-left: $column-gutter/2;
	    			padding-right: $column-gutter/2;
	    			padding-top: $column-gutter/2;
				}
			}

			&-item {
				@include grid-row;
				border-bottom: $search-autosuggest-border-bottom;
				font-size: $search-autosuggest-featured-results-item-title-font-size;
				padding-bottom: $search-autosuggest-featured-results-item-padding-bottom;
				padding-left: $column-gutter/2;
				padding-right: $column-gutter/2;
				padding-top: $search-autosuggest-featured-results-item-padding-top;

				&__img-container {
						display: table-cell;
						height: $search-autosuggest-featured-results-item-img-height;
						position: relative;
						vertical-align: top;
						width: $search-autosuggest-featured-results-item-img-width;

						img {
							height: auto;
							width: 100%;
						}

				}

				&__info-container {
					display: table-cell;
					padding-left: $search-autosuggest-featured-results-item-info-container-padding-left;
					position: relative;
					vertical-align: top;

					p {
						font-family: $search-autosuggest-featured-results-item-font-family;
						font-size: $search-autosuggest-featured-results-item-font-size;
						line-height: $search-autosuggest-featured-results-item-title-line-height;
						margin-bottom: 5px;
						margin-right: $search-autosuggest-featured-results-item-title-margin-right;
					}

					.price-container {
						p {
							display: $price-container-p-display;
							width: $price-container-p-width;
							&.list-price {
								text-decoration: $price-container-list-price-text-decoration;
							}
							&.sale-price {
								color: $price-container-sale-price-color;
							}
						}
					}
				}

				a {
					color: $search-autosuggest-item-anchor-color;
					display: inline-block;
					width: 100%;

					&:hover {
						color: $search-autosuggest-item-anchor-color-hover;
					}
				}

				&:last-child {
					border: 0;
					padding-bottom: 0;
				}
			}
		}
	}

	.autosuggest-overlay {
		@include autosuggest-overlay-results;
	}
}

@mixin search-autosuggest-expanded {
	.autosuggest-results-container {
		position: fixed;

		.suggestions h6 {
			font-weight: 500;
			letter-spacing: inherit;
		}

		.products-area-title h6 {
			display: none;
			font-size: $search-autosuggest-expanded-products-title-size;
			font-weight: 500;
			letter-spacing: inherit;
		}

		.sug-product-info-link {
			color: $search-autosuggest-expanded-suggestion-color;
			display: inline-block;
			font-weight: $search-autosuggest-expanded-suggestion-weight;
			text-decoration: none;
			width: $search-autosuggest-expanded-img-width;
		}

		.sug-nav-link {
			text-decoration: none;
			text-transform: lowercase;
		}

		.products li {
			display: block;
			margin-bottom: 1em;
			min-height: $search-autosuggest-expanded-products-item-size;
			padding-right: 20px;
		}

		.sug-product-name,
		.sug-product-price {
			font-size: 1rem;
			line-height: 1.2;
			text-align: left;
		}

		.sug-product-price {
			font-weight: 600;
		}

		.previous {
			color: $search-autosuggest-expanded-price-previous-color;
			text-decoration: $search-autosuggest-expanded-price-previous-text-decoration;
		}

		.current {
			color: $search-autosuggest-expanded-price-current-color;
		}

		h6 {
			font-style: normal;
		}

		.suggestions {
			h6 {
				margin-top: 1em;
			}

			.query {
				display: $search-autosuggest-expanded-query-display;
			}
		}

		.products-area-title-mobile {
			display: block;
			margin-left: 1.25rem;
			padding: 0.5em 1em 0 0.5em;
		}

		.search-autosuggest-expanded-wrapper {
			flex-direction: column;
			margin-top: 2em;
			padding: 0;
		}

		.sug-product-picture img {
			width: 100%;
		}

		.products-area {
			height: 275px;
			overflow-x: scroll;
			overflow-y: hidden;

			ul {
				display: inline-flex;
				margin-left: 0;
				width: 100%;
			}
		}

		.sug-product-name {
			padding: $search-autosuggest-expanded-product-name-padding;
			text-transform: $search-autosuggest-expanded-product-name-transform;
		}

		.searches-area {
			margin-left: 0.5em;
		}

		.search-query,
		.highlight,
		.search-term {
			font-weight: 600;
		}

		.cat-sug li {
			list-style: none;
		}

		.cat-sug {
			margin-left: 0;
		}

		.suggestions li {
			list-style: none;
		}

		.search-term-link {
			color: $search-autosuggest-expanded-suggestion-color;
			font-weight: $search-autosuggest-expanded-suggestion-weight;
			text-decoration: $search-autosuggest-expanded-suggestion-decoration;

			&:active,
			&:hover {
				color: $search-autosuggest-expanded-suggestion-color-hover;
				text-decoration: $search-autosuggest-expanded-suggestion-decoration-hover;
			}
		}

		@include breakpoint(medium) {
			background: rgba($black, 0.7);
			height: 100%;
			left: 0;
			overflow: hidden;
			width: 100%;
			z-index: z-index(topnav) - 1;

			.topnav-header.sticky-minimized + & {
				top: $topnav-sticky-minimized--height__medium;

				.search-autosuggest-expanded {
					padding-top: 0;
				}
			}

			.search-autosuggest-expanded {
				border-radius: 0 0 0 0.5em;
				height: auto;
				padding-top: $search-autosuggest-expanded-padding-top-medium;
				position: absolute;
				right: 0;
				width: 640px;
			}

			.search-autosuggest-expanded-wrapper {
				background: $white;
				border-radius: 0 0 0 0.5em;
				display: flex;
				flex-direction: row-reverse;
				margin-top: 0;
				padding: 1em;
				text-align: left;
			}

			.suggestions h6 {
				color: $black;
				font-size: $search-autosuggest-expanded-suggestions-title-size-medium;
				margin: $search-autosuggest-expanded-suggestions-title-margin-medium;
			}

			.cat-sug li:hover {
				color: get-color(primary);
			}

			.search-suggestion:hover {
				cursor: pointer;
			}

			.search-query {
				font-weight: 700;
			}

			.suggestions p {
				font-size: 13px;
				padding-bottom: 13px;
			}

			.search-autosuggest-expanded-wrapper {
				.highlight,
				.search-term {
					font-weight: bold;
				}

				ul {
					display: block;
					list-style: none;
					margin-bottom: 1rem;
					margin-left: 1.25rem;
				}
			}

			.searches-area {
				min-width: 25%;
			}

			.search-suggestion,
			.sug-nav-link {
				font-size: 1rem;
			}

			.searches-area {
				min-width: 30%;
			}

			.products-area {
				min-width: 25%;
			}

			.searches-area,
			.products-area {
				background: $white;
				height: auto;
			}

			.first {
				display: inline-block;
			}

			.search-suggestion:nth-child(1) {
				margin-bottom: 1em;
			}

			.products-area-title h6 {
				display: block;
				margin-bottom: $search-autosuggest-expanded-title-margin-bottom-medium;
			}

			.products-area {
				height: 100%;
				margin: $search-autosuggest-expanded-products-margin-medium;
				overflow: visible;

				ul {
					margin-left: 0;
				}

				li {
					display: inline-block;
					margin: $search-autosuggest-expanded-products-item-margin-medium;
					min-height: $search-autosuggest-expanded-products-item-size-medium;
					padding-right: 15px;
				}
			}

			.sug-product-info {
				width: 100%;

				&-link {
					align-items: $search-autosuggest-expanded-products-item-align-medium;
					display: inline-flex;
					width: $search-autosuggest-expanded-products-item-width-medium;
				}
			}

			.products-area-title-mobile {
				display: none;
			}

			.sug-product-picture {
				text-align: center;
				margin-right: $column-gutter;
				min-width: $search-autosuggest-expanded-img-width-medium;
				width: $search-autosuggest-expanded-img-width-medium;

				a {
					width: 100%;
				}

				img {
					backface-visibility: hidden;
					mix-blend-mode: multiply;
					transition: all 250ms cubic-bezier(0, 0, 0.32, 0.95);
				}
			}

			.sug-product-info-link {
				&:hover {
					color: get-color(primary);
				}
			}
		}

		@include breakpoint(large) {

			.search-autosuggest-expanded {
				max-width: 1920px;
				padding-top: $search-autosuggest-expanded-padding-top-large;
				width: 100%;
			}

			&.history {
				padding-right: 58px;
				width: 35%;
			}

			.products-area {
				border-right: $search-autosuggest-expanded-products-border-large;

				li {
					min-height: $search-autosuggest-expanded-products-item-size;
				}
			}

			.sug-product-info {

				&-link {
					width: $search-autosuggest-expanded-products-item-width-large;
				}
			}

			.searches-area {
				min-width: 30%;
			}

			.products-area-title h6 {
				font-size: $search-autosuggest-expanded-products-title-size-large;
			}

			.sug-product-picture {
				min-width: $search-autosuggest-expanded-img-width-large;
				width: $search-autosuggest-expanded-img-width-large;
			}

			.search-suggestion,
			.sug-nav-link {
				font-size: 1.1rem;
			}

			.sug-product:nth-child(even) {
				margin-left: 40px;
			}
		}

		@include breakpoint(xlarge) {
			.search-autosuggest-expanded {
				max-width: 1308px;
				width: 100%;
			}

			.history {
				padding-right: 58px;
				width: 35%;
			}

			.products-area {
				li {
					width: $search-autosuggest-expanded-products-item-width-xlarge;
				}
			}

			.sug-product-picture {
				min-width: $search-autosuggest-expanded-img-width-xlarge;
				width: $search-autosuggest-expanded-img-width-xlarge;
			}

			.products-area-title h6 {
				font-size: $search-autosuggest-expanded-products-title-size-xlarge;
			}

			.search-suggestion,
			.sug-nav-link {
				font-size: 1.2rem;
			}
		}

		@keyframes slideDown {
			from {
				top: -1000px;
			}

			to {
				top: 120px;
			}
		}

		@keyframes slideUp {
			from {
				top: 120px;
			}

			to {
				top: -1000px;
			}
		}
	}
}

@if($include-html-search-autosuggest-classes != false) {
		@include search-autosuggest;
}
